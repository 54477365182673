export const keyActions = (keyData) => {
 
    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/users/adminKey", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
         body: JSON.stringify({
         keyData
        
         })
    }).then((response) => response.json())
    .then((json) => {
       
      
      return json;
    }).catch((err) => {
        console.log("keyActions.js, key Request Error: ", err.message);
        keyFailure("Fail to key Acess");
    });
  

}

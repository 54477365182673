import { getData } from "../storage/manageStorage";

export const addPoleImageGallery = async  (formData) => {

    const token = await getData("authToken")

   return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/poles/addPoleImageGallery", {
        method: "POST",
        headers: {
            'Content-Type': 'multipart/form-data',// this is a imp line
            'Accept': 'application/json',
            'Authorization':'Bearer'+token,
        },
         body:  formData    
         
    }).then((response) => response.json())  
    .then((json) => {
     
       
      return json;
    }).catch((err) => {
        console.log("AddPoleImageGallery.js, addPole Request Error: ", err.message);
        
    });
  
  
}






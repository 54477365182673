
export const  removeParticipation = (formData) => {


  fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/participants/removeParticipant", {
      method: "POST",
      headers: {
          "Content-Type": "application/json"
      },
       body: JSON.stringify({
        formData
      
       })
  }).then((response) => response.json())
  .then((json) => {

    
    
    return json;
  }).catch((err) => {
    console.log("removeParticipantsSuccess.js, removeParticipants Request Error: ", err.message);
    
});



}









import { getData } from "../storage/manageStorage";



export const EditEventImageGallery = async (formData,concept) => {

const token = await getData("authToken")



if(concept == "activity"){


    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/upload", {
        method: "POST",
        'Content-Type': 'multipart/form-data',
         body:formData    
         
    }).then((response) => response.json())
    .then((json) => {
        
  
        
      return json;
    }).catch((err) => {
        console.log("EditEventImageGallery.js, EditActivity Request Error: ", err.message);
        
    });
  
}

if(concept == "event"){
    
    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/events/editConceptImageGallery", {
        method: "POST",
        headers: {
            'Content-Type': 'multipart/form-data',// this is a imp line
            Accept: 'application/json',
            'Authorization':'Bearer'+token,
        },
         body:formData    
         
    }).then((response) => response.json())
    .then((json) => {
       
     
      return json;
    }).catch((err) => {
        console.log("EditEventImageGallery.js, EditActivity Request Error: ", err.message);
        
    });
}

if(concept == "activityHoliday"){

  
      

  return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/poles/editConceptHolidayActivityGallery", {
    method: "POST",
    headers: {
        'Content-Type': 'multipart/form-data',// this is a imp line
        Accept: 'application/json',
        'Authorization':'Bearer'+token,
    },
     body:formData    
     
}).then((response) => response.json())
.then((json) => {
   
 
  return json;
}).catch((err) => {
    console.log("EditEventImageGallery.js, EditActivity Request Error: ", err.message);
    
});
}


  
  



}









import { getData } from "../storage/manageStorage";



export const editConceptImagePredefined = async (formData,concept) => {

const token = await getData("authToken")

if(concept == "activity"){


  fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/poles/editConceptPredefined", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          'Authorization':'Bearer'+token,
      },
       body: JSON.stringify({
        formData
      
       })
  }).then((response) => response.json())
  .then((json) => {
 
    return json;
  }).catch((err) => {
    console.log("addActivitySuccess.js, addActivity Request Error: ", err.message);
    
});

}

if(concept == "event"){

    fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/events/editConceptPredefined", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization':'Bearer'+token,
        },
         body: JSON.stringify({
          formData
        
         })
    }).then((response) => response.json())
    .then((json) => {
  
    
      
      return json;
    }).catch((err) => {
      console.log("addEventSuccess.js, addEvent Request Error: ", err.message);
      
  })

}


if(concept == "activityHoliday"){

  fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/poles/editConceptHolidayActivityPredefined", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          'Authorization':'Bearer'+token,
      },
       body: JSON.stringify({
        formData
      
       })
  }).then((response) => response.json())
  .then((json) => {

  
    
    return json;
  }).catch((err) => {
    console.log("addHolidaySuccess.js, addHolidayActivity Request Error: ", err.message);
    
})

}

}









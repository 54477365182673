import AsyncStorage from '@react-native-async-storage/async-storage';
import React, {useContext} from "react";
import {storeData} from "../../storage/manageStorage"
import { ManageContext } from '../../provider/manageStateProvider';

// action creators

export const signupSuccess = async (SignUpData) => {


/* const message = SignUpData.message
const authToken = SignUpData.authToken
const firstname = SignUpData.firstname
const lastname = SignUpData.lastname
const idUser = SignUpData.idUser

 

 if(message=="Signup Success")
 {

    storeData("isManager","false")
    storeData("authToken",authToken)
    storeData("lastname",lastname)
    storeData("firstname",firstname) 
    storeData("idUser",idUser)
    storeData("photoUser","")


 } */
 
    
}


export const signupActions = signupData => {


     return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/users/signup", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
         body: JSON.stringify({
         signupData
        
         })
    }).then((response) => response.json())
    .then((json) => {
    
    signupSuccess(json);
    
      return json;
    }).catch((err) => {
        console.log("signupActions.js, Signup Request Error: ", err.message);
        signupFailure("Fail to Sign Up");
    });
  


}

import { getData } from "../storage/manageStorage";






export const deleteConceptActions = async (deleteConceptData,concept) => {
  
  const token = await getData("authToken")

 if(concept == "activity"){

    fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/poles/deleteConcept", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization':'Bearer'+token,
        },
         body: JSON.stringify(deleteConceptData)
    }).then((response) => response.json())
    .then((json) => {
       
  
      
      return json;
    }).catch((err) => {
        console.log("deleteConceptActions.js, deleteConcept Request Error: ", err.message);
      
    });
  

  }
  if(concept == "event"){

    
    
    fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/events/deleteConcept", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization':'Bearer'+token,
        },
         body: JSON.stringify(deleteConceptData)
    }).then((response) => response.json())
    .then((json) => {
       
   
      
      return json;
    }).catch((err) => {
        console.log("deleteConceptActions.js, deleteConcept Request Error:", err.message);
        
    });

 }


 if(concept == "activityHoliday"){

    
    
  fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/poles/deleteConceptActivityHoliday", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          'Authorization':'Bearer'+token,
      },
       body: JSON.stringify(deleteConceptData)
  }).then((response) => response.json())
  .then((json) => {
     
 
    
    return json;
  }).catch((err) => {
      console.log("deleteConceptActions.js, deleteConcept Request Error:", err.message);
      
  });

}
}

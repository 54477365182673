import React, { useEffect, useState, useRef, useContext } from "react";
import moment from "moment";

import localization from "moment/locale/fr";

import { SOCKET } from "../config/socket";

import {
  Text,
  View,
  StyleSheet,
  ImageBackground,
  Platform,
  Image,
  StatusBar,
  FlatList,
  Modal,
  Share,
  TouchableOpacity,
  Linking,
  Button,
  TextInput
} from "react-native";

import { LinearGradient } from "expo-linear-gradient";

import { ScrollView } from "react-native-gesture-handler";

import { Feather } from "@expo/vector-icons";
import { theme } from "../constants";

import { getData, storeData } from "../storage/manageStorage";

import { loginActions } from "../actions/authentification/LoginAction";
import { removeParticipation } from "../actions/removeParticipation";
import { listParticipant } from "../actions/FindUsersParticipate";
import { getCreatorData } from "../actions/GetCreatorData";
import { ManageContext } from "../provider/manageStateProvider";

import { fetchDataPermanence } from "../actions/permanence/DataPermanenceAction";
import { getOldAppointement } from "../actions/permanence/DataListOldAppointmentAction";

import { reservePermanence } from "../actions/permanence/ReservePermanence";
import { cancelAppointment } from "../actions/permanence/CancelAppointmentAction";
import AddAppointmentAnimation from "../components/animation/addAppoitmentPermanence";



import { Ionicons } from "@expo/vector-icons";


const PermanenceDetail = ({ navigation, route }) => {



  const handlePermanenceDetail = useContext(ManageContext);
  const [password, setPassword] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [messageError, setMessageError] = useState("");
  const [updateParticipation, setUpdateParticipation] = useState(false);
  const [expand, setSetExpand] = useState(0);
  const [manage, setManage] = useState(false);
  const [participationModal, setParticipationModal] = useState(false);
  const [calendarModal, setCalendarModal] = useState(false);
  const [confirmAppointmentModal, setConfirmAppointmentModal] = useState(false);
  const [idUser, setIdUser] = useState("");
  const [firstname, setFirstname] = useState("");
  const [appointmentDelete, setAppointmentDelete] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstnameCustomer, setFirstnameCustomer] = useState("");
  const [cancelMessageError, setCancelMessageError] = useState("");
  const [lastnameCustomer, setLastnameCustomer] = useState("");
  const [photoUser, setPhotoUser] = useState("");
  const [userParticipation, setUserParticipation] = useState([]);
  const [allParticipation, setAllParticipation] = useState([]);
  const [creatorDetail, setCreatorDetail] = useState([]);
  const [isBanned, setIsBanned] = useState();
  const [idStructure, setIdStructure] = useState("");
  const [dateAnnonce, setDateAnnonce] = useState("");
  const [visibleMaxUser, setVisibleMaxUser] = useState(false);
  const [validAppointmentModal, setValidAppointmentModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [purpose, setPurpose] = useState("");
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [phoneNumberStructure, setPhoneNumberStructure] = useState();
  const [incomingAppointment, setIncomingAppointment] = useState([]);
  const [hourSelected, setHourSelected] = useState([]);
  const [hourAllReadyReserved, setHourAllReadyReserved] = useState([]);
  const [initialHour, setInitialHour] = useState([]);
  const [oldAppointment, setOldAppointment] = useState([]);
  const [days, setDays] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cancelAppointmentModal, setCancelAppointmentModal] = useState(false);
  const [buttonLoadingCancelAppointment, setButtonLoadingCancelAppointment] =
    useState(false);

  const allHours = [
    "9h00",
    "9h30",
    "10h00",
    "10h30",
    "11h00",
    "11h30",
    "13h00",
    "13h30",
    "14h00",
    "14h30",
    "15h00",
    "15h30",
    "16h00",
    "16h30",
    "17h00",
    "17h30",
    "18h00",
    "18h30",
    "19h00",
  ];

  moment().locale("fr", localization).format("LLL");

  const permanence = route.params.permanence;

  const bottomSheetHandle = useRef(null);

  useEffect(() => {
    //Put your Data loading function here instead of my loadData()

    const unsubscribe = navigation.addListener("focus", async () => {
      await getData("isManager")
        .then((data) => data)
        .then((value) => {
          value == "true" ? setManage(true) : setManage(false);
        });

      await getData("idUser")
        .then((data) => data)
        .then((value) => {
          setIdUser(value);
        });

      await getData("idC")
        .then((data) => data)
        .then((value) => {
          setIdStructure(value);
        });

      await getData("firstname")
        .then((data) => data)
        .then((value) => {
          setFirstname(value);
        });

      await getData("phoneNumber")
        .then((data) => data)
        .then((value) => {
          setPhoneNumber(value);
        });
      await getData("photoUser")
        .then((data) => data)
        .then((value) => {
          setPhotoUser(value);
        });

      await getData("lastname")
        .then((data) => data)
        .then((value) => {
          setLastname(value);
        });

      return unsubscribe;
    });
  }, [navigation]);

  const idAllowParticipate =
    route.params.concept == "permanence"
      ? idStructure
      : route.params.permanence.idStructure == ""
      ? route.params.permanence.idCentre
      : route.params.permanence.idStructure;

  useEffect(() => {
    const fetchParticipate = async () => {
      
      if (idUser !== "") {
        const formData = {
          idUser: idUser,
          idConcept: route.params.permanence.id,
        };

        const participe = await listParticipant(formData);

        setUserParticipation(participe.isParticipating);

        if (participe.allParticipant[0] == undefined) {
          setAllParticipation([]);
        } else {
          setAllParticipation(participe.allParticipant);

          setIsBanned(participe.allParticipant[0].isBanned);
        }
      }
    };
    fetchParticipate();
  }, [idUser, userParticipation, updateParticipation]);

  useEffect(() => {
    const fetchCreator = async () => {

      const formData = {
        idUser: route.params.permanence.idUser,
        
      };


      const creator = await getCreatorData(formData);

     if(creator !== undefined){
    

        setCreatorDetail(creator.response);
   

     

     }

  
    };
    fetchCreator();

  }, []);

  useEffect(() => {
    //Put your Data loading function here instead of my loadData()
    const fetchData = async () => {
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = new Date(Date.now() - tzoffset)
        .toISOString()
        .slice(0, -1);

      const ListPermanence = await fetchDataPermanence(
        route.params.idCentre,
        route.params.permanence.id,
        handlePermanenceDetail.updateManager,
        handlePermanenceDetail.idUser,
        localISOTime
      );

      const ListOldPermanence = await getOldAppointement(
        route.params.idCentre,
        route.params.permanence.id,
        handlePermanenceDetail.updateManager,
        handlePermanenceDetail.idUser,
        localISOTime
      );

      setDays(ListPermanence.days);
      setIncomingAppointment(ListPermanence.allAppointment);

 
      setPhoneNumberStructure(ListPermanence.phoneNumber)
      
      setInitialHour(ListPermanence.docs[0].initialHour);
      setHourAllReadyReserved(
        ListPermanence.allReadyReserved + ListPermanence.arrCloseDate
      );

      if (ListOldPermanence.oldAppointment !== undefined) {
        setOldAppointment(ListOldPermanence.oldAppointment);
      }
    };

    fetchData();
  }, [handlePermanenceDetail.updatePermanence]);

  const goToEditConceptPermanence = () => {
    navigation.navigate("EditPermanence", {
      permanence: route.params.permanence,
      idCentre: route.params.idCentre,
      initialHour: initialHour,
    });
  };

  const cancelCalendar = () => {
    setCalendarModal(false);
  };

  const hideCancelAppointmentModal = () => {
    setCancelAppointmentModal(false);
  };


  const userCallHandler =() => {

    if(phoneNumberStructure !== undefined && phoneNumberStructure !== null )  {

  

      const callUri = 'tel://' + 0 + phoneNumberStructure
     
        Linking.openURL(callUri);
  
    }


  }


  const openCancelAppointmentModal = (id) => {
    setAppointmentDelete(id);
    setCancelAppointmentModal(true);
  };

  const cancelAppointmentHandler = async () => {
    setCancelMessageError("");

    setButtonLoadingCancelAppointment(true);

    const formData = {
      idPermanence: appointmentDelete,
    };

    const response = await cancelAppointment(formData);

    if (response.message == "Good") {
      handlePermanenceDetail.updatePermanence == "false"
        ? handlePermanenceDetail.setUpdatePermanence("true")
        : handlePermanenceDetail.setUpdatePermanence("false");

      setCancelAppointmentModal(false);

      setButtonLoadingCancelAppointment(false);
    } else {
      setCancelMessageError("Une erreur s'est produite");
    }
  };

  const sendSmspermanence = () => {
    if (route.params.concept == "permanence") {
      const timepermanence =
        route.params.permanence.timepermanence == undefined
          ? ""
          : route.params.permanence.timepermanence;

      const shareOptions = {
        title: "Mon Centre Social",
        message: `Bonjour, je trouve très intéréssant cette permanence de ${route.params.permanence.title} et je souhaite t'en informer elle aura lieu au ${route.params.permanence.location}, pour plus d'information tu peux télécharger l'application Mon Centre Social : 
      
Play Store: 
https://play.google.com/store/apps/details?id=com.mcsexpo.MCS&gl=FR

Apple Store: 
https://apps.apple.com/fr/app/mon-centre-social/id1610144427?platform=iphone`,
        subject: "Participation",
      };

      Share.share(shareOptions);
    } else {
      const shareOptions = {
        title: "Mon Centre Social",
        message: `Bonjour, je trouve très intéréssant cet évènement de ${
          route.params.permanence.title
        } et je souhaite t'en informer il aura lieu aux ${
          route.params.permanence.location
        } le ${moment(route.params.permanence.date)
          .format("DD")
          .toLocaleUpperCase()} ${moment(route.params.permanence.date)
          .format("MMM")
          .toLocaleUpperCase()} a ${moment(route.params.permanence.date)
          .format("HH")
          .toLocaleUpperCase()}H${moment(route.params.permanence.date)
          .format("MM")
          .toLocaleUpperCase()}, pour plus d'information tu peux télécharger l'application Mon Centre Social : 
      
Play Store: 
https://play.google.com/store/apps/details?id=com.mcsexpo.MCS&gl=FR

Apple Store: 
https://apps.apple.com/fr/app/mon-centre-social/id1610144427?platform=iphone`,
        subject: "Participation",
      };

      Share.share(shareOptions);
    }
  };

  const confirmAppointment = async () => {
    if (dateAnnonce !== "") {
      setConfirmLoading(true);
      setMessageError("");

      //determine si le rdv est prit par un admin ou non
      const AppointmentFromManager =
        handlePermanenceDetail.updateManager == true ? true : false;

      if (handlePermanenceDetail.updateManager == false) {
        storeData("phoneNumber", phoneNumber);
      }

      const formData = {
        idConcept: route.params.permanence.id,
        idUser: handlePermanenceDetail.idUser,
        purpose: purpose,
        phoneNumber: phoneNumber,
        hourReserved: dateAnnonce,
        dateCreated: Date.now(),
        appointmentFromManager: AppointmentFromManager,
        lastnameCustomer: lastnameCustomer,
        firstnameCustomer: firstnameCustomer,
      };

      const response = await reservePermanence(formData);

      if (response.message == "Bad") {
        handlePermanenceDetail.updatePermanence == "false"
          ? handlePermanenceDetail.setUpdatePermanence("true")
          : handlePermanenceDetail.setUpdatePermanence("false");

        setMessageError("une erreur s'est produite veuillez réessayer");

        setTimeout(() => {
          setConfirmAppointmentModal(false);
          setCalendarModal(false);
          setValidAppointmentModal(false);
        }, 3000);

        setConfirmLoading(false);
      }

      if (response.message == "Good") {
        setLastnameCustomer("");
        setFirstnameCustomer("");

        setPhoneNumber("");
        setPurpose("");

        handlePermanenceDetail.updatePermanence == "false"
          ? handlePermanenceDetail.setUpdatePermanence("true")
          : handlePermanenceDetail.setUpdatePermanence("false");

        setConfirmAppointmentModal(true);
        setCalendarModal(false);
        setValidAppointmentModal(false);
        setTimeout(() => {
          setConfirmAppointmentModal(false);
        }, 4000);

        setConfirmLoading(false);
      }
    }
  };

  const validAppointment = () => {
    if (dateAnnonce !== "") {
      setValidAppointmentModal(true);
      setCalendarModal(false);
    }
  };

  const closeBottomSheet = () => {
    bottomSheetHandle.current.close();
    setSetExpand(0);
  };

  const hideMaxUserModal = () => {
    setVisibleMaxUser(false);
  };

  const showListParticipation = () => {
    if (route.params.concept == "permanence") {
      setParticipationModal(true);
    } else {
      if (idStructure == route.params.permanence.idStructure) {
        setParticipationModal(true);
      }
    }
  };

  const handleDateSelected = (day, hour, date) => {
    const oldData = [];

    let Data = [];
    const selected = day + hour + date;

    Data.includes(selected);

    if (oldData.includes(selected) == false) {
      Data.push(selected);

      setHourSelected(Data.concat(oldData));

      setDateAnnonce(date + " " + hour);
    } else {
      var filteredArray = oldData.filter(function (e) {
        return e !== selected;
      });

      setHourSelected(filteredArray);
    }
  };

  const renderIncomingAppointment = ({ item, index }) => {
    return (
      <View style={styles.cardAppointment}>
        <View style={styles.incomingAppointmentDate}>
          <Ionicons
            style={{ marginVertical: "4%", marginLeft: "4%" }}
            name="calendar-outline"
            color={"white"}
            size={20}
          />
          <Text style={{ color: "white", margin: "4%" }} h5>
            {moment(item.datesAppointment).format("dddd DD MMMM")}
          </Text>

          <Ionicons
            style={{ marginVertical: "4%", marginLeft: "4%" }}
            name="stopwatch-outline"
            color={"white"}
            size={20}
          />

          <Text style={{ color: "white", marginVertical: "4%" }} h5>
            {moment.utc(item.datesAppointment).format("HH:mm")}
          </Text>
        </View>

        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <Ionicons
            style={{ marginVertical: "5%", marginHorizontal: "1%" }}
            name="ios-person"
            color={theme.colors.lightBlue}
            size={15}
          />
          <Text style={{ color: "#1b3a59", margin: "4%" }} h5>
            {item.lastnameCustomer} {item.firstnameCustomer}
          </Text>
        </View>
        <View>
          <View style={styles.divider} />
        </View>

        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            maxHeight: 60,
          }}
        >
          <Text style={{ color: "#1b3a59", marginHorizontal: "4%" }} h5>
            {item.purpose}
          </Text>
        </View>
        <View>
          <View style={styles.divider} />
        </View>

        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <Ionicons
            style={{ marginVertical: "5%", marginHorizontal: "1%" }}
            name="md-call"
            color={theme.colors.lightBlue}
            size={15}
          />
          <Text
            style={{
              color: "#1b3a59",
              marginVertical: "4%",
              marginHorizontal: "1%",
            }}
            h5
          >
            {item.phoneNumber}
          </Text>
        </View>

        <View>
          <View style={styles.divider} />
        </View>

        <TouchableOpacity
          onPress={() => {
            openCancelAppointmentModal(item._id);
          }}
        >
          <Text
            style={{
              color: theme.colors.WARNING,
              marginVertical: "4%",
              alignSelf: "center",
            }}
            h5
          >
            {"Annuler le rendez-vous"}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const renderOldAppointment = ({ item, index }) => {
    return (
      <View style={styles.cardOldAppointment}>
        <View style={styles.oldAppointmentDate}>
          <Ionicons
            style={{ marginVertical: "4%", marginLeft: "4%" }}
            name="calendar-outline"
            color={"white"}
            size={20}
          />
          <Text style={{ color: "white", margin: "4%" }} h5>
            {moment(item.datesAppointment).format("dddd DD MMMM ")}
          </Text>

          <Ionicons
            style={{ marginVertical: "4%", marginLeft: "4%" }}
            name="stopwatch-outline"
            color={"white"}
            size={20}
          />

          <Text style={{ color: "white", marginVertical: "4%" }} h5>
            {moment.utc(item.datesAppointment).format("HH:mm")}
          </Text>
        </View>

        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <Ionicons
            style={{ marginVertical: "5%", marginHorizontal: "1%" }}
            name="ios-person"
            color={theme.colors.lightBlue}
            size={15}
          />
          <Text style={{ color: "#1b3a59", margin: "4%" }} h5>
            {item.lastnameCustomer} {item.firstnameCustomer}
          </Text>
        </View>
        <View>
          <View style={styles.divider} />
        </View>

        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            maxHeight: 60,
          }}
        >
          <Text style={{ color: "#1b3a59", marginHorizontal: "4%" }} h5>
            {item.purpose}
          </Text>
        </View>
        <View>
          <View style={styles.divider} />
        </View>

        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <Ionicons
            style={{ marginVertical: "5%", marginHorizontal: "1%" }}
            name="md-call"
            color={theme.colors.lightBlue}
            size={15}
          />
          <Text
            style={{
              color: "#1b3a59",
              marginVertical: "4%",
              marginHorizontal: "1%",
            }}
            h5
          >
            {item.phoneNumber}
          </Text>
        </View>
      </View>
    );
  };

  const renderItemDay = ({ item, index }) => {
    
    return (
      <View key={index} style={styles.card}>
        <Text style={{ color: "#1b3a59", margin: "2%" }} h5>
          {item.date}
        </Text>
        <View style={styles.divider} />;
        <View style={{ flex: 1 }}>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {allHours.map((itemHour, i) => (
              <View key={i}>
                {hourAllReadyReserved.includes(
                  item.date + " " + itemHour
                ) ? null : (
                  <TouchableOpacity
                    onPress={() =>
                      handleDateSelected(item.day, itemHour, item.date)
                    }
                  >
                    {initialHour.includes(item.day + itemHour) ? (
                      <View
                        style={
                          hourSelected.includes(
                            item.day + itemHour + item.date
                          ) == true
                            ? styles.hourSelected
                            : styles.hourUnselected
                        }
                      >
                        <Text
                          style={[
                            styles.text,
                            hourSelected.includes(
                              item.day + itemHour + item.date
                            ) == true
                              ? styles.unselected
                              : styles.selected,
                          ]}
                          h6
                        >
                          {itemHour}
                        </Text>
                      </View>
                    ) : null}
                  </TouchableOpacity>
                )}
              </View>
            ))}
          </View>
        </View>
      </View>
    );
  };

  const hideListParticipation = () => {
    setParticipationModal(false);
  };

  function jsx() {
    let canHeEdit = "";

    if (
      idStructure == route.params.permanence.idStructure &&
      handlePermanenceDetail.updateManager == true
    ) {
      canHeEdit = true;
    } else {
      canHeEdit = false;
    }

    if (route.params.concept == "permanence") {
      canHeEdit = true;
    }

    return (
      <View style={styles.container}>
        <StatusBar style="auto" />

        <ScrollView
          contentContainerStyle={{
            backgroundColor: "#FFF",
          }}
          style={{
            backgroundColor: "#FFF",
          }}
        >
          <ImageBackground
            resizeMode="cover"
            source={{ uri: permanence.preview }}
            style={{
              width: "100%",
              
            }}
          >
            <View style={{ flex: 1 }}>
              <View style={styles.sectionImageHeader}>
                <TouchableOpacity
                  onPress={goBack}
                  style={{
                    width: 56,
                    height: 40,
                    backgroundColor: "rgba(0,0,0,0.2)",
                    justifyContent: "center",
                    borderRadius: 10,
                    alignItems: "center",
                  }}
                >
                <Feather
                name="arrow-left"
                size={theme.sizesPhotos.font * 1.2}
                color={"#e0e0e0"}
              />
                  </TouchableOpacity>

                <View
                  style={{
                    width:
                      handlePermanenceDetail.updateManager !== false &&
                      canHeEdit == true
                        ? 90
                        : 50,
                    height: 40,
                    backgroundColor: "rgba(0,0,0,0.2)",
                    justifyContent: "space-between",
                    borderRadius: 10,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                


                  <TouchableOpacity onPress={sendSmspermanence}>
                  <View
                    style={{
                      margin: 10,
                    }}
                  >
                    <Feather
                      name="share-2"
                      size={theme.sizesPhotos.font * 1.2}
                      color={"#e0e0e0"}
                    />
                  </View>
                </TouchableOpacity>

                  {handlePermanenceDetail.updateManager !== false &&
                  canHeEdit == true ? (
                    <TouchableOpacity onPress={goToEditConceptPermanence}>
                      <View
                        style={{
                          margin: 10,
                        }}
                      >
                        <Feather
                          name="edit-2"
                          size={theme.sizesPhotos.font * 1.2}
                          color={"#e0e0e0"}
                        />
                      </View>
                    </TouchableOpacity>
                  ) : null}
                </View>
              </View>

              <View style={styles.sectionImageFooter}>
                <LinearGradient
                  colors={["transparent", "transparent"]}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 0, y: 1 }}
                  style={{
                    width: "100%",
                    height: 200,
                    justifyContent: "flex-end",
                  }}
                >
                  <View style={styles.footerContentView}>
                    <View>
                      <Text
                       
                        style={styles.titlePermanence}
                      >
                        {permanence.title}
                      </Text>

                      <View
                        body4
                        style={{
                          opacity: 0.8,
                          color: "#0f0c29",
                          letterSpacing: 1.5,
                        }}
                      ></View>
                    </View>
                  </View>
                </LinearGradient>

                <Modal animationType="slide" visible={confirmAppointmentModal}>
                  <View
                    style={{
                      width: "90%",
                      height: "55%",
                      backgroundColor: "white",
                      marginVertical: "20%",
                      marginLeft: "5%",
                      justifyContent: "center",
                    }}
                  >
                    <View style={{ alignItems: "center" }}>
                      <Text h3 color="black">
                        Votre rendez-vous a été pris en compte :
                      </Text>

                      <Text
                        h2
                        color="#00BCFF"
                        style={{ marginVertical: "5%" }}
                      >
                        {dateAnnonce}
                      </Text>
                    </View>
                    <AddAppointmentAnimation />
                  </View>
                </Modal>

                <View
                  style={{
                 
                    borderTopRightRadius: 15,
                    borderTopLeftRadius: 15,
                    paddingTop: 15,
                    backgroundColor: "white",
                  }}
                >
                  <LinearGradient
                    colors={["white", "white"]}
                    start={{ x: 0, y: 1 }}
                    end={{ x: 1, y: 1 }}
                  >
                    <View style={{ flexDirection: "row",}}>
                      <View style={{ width: "50%",
                        marginHorizontal:"10%"}}>

                      <TouchableOpacity onPress={showListParticipation}>
                <View
                  style={{
                    width: 180,
                    height: 45,
                    borderRadius: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#146cd9"
                      
                  }}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View>
                      <Text style={{color:"white"}}>Rdv passés </Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
                        
                      </View>

                      <View style={{ width: "50%",
                        marginHorizontal:"10%"}}>
                        {(route.params.permanence.activeChat == true &&
                          handlePermanenceDetail.userStructure ==
                            handlePermanenceDetail.idStructure) ||
                        handlePermanenceDetail.updateManager == true ? (
                          <TouchableOpacity onPress={chatHandler}>
                            <Image
                              style={{ width: 50, height: 50 }}
                              source={require("../assets/images/chatButton.png")}
                            />
                          </TouchableOpacity>
                        ) : (
                          <LinearGradient
                            colors={theme.colors.linearDislabled}
                            start={{ x: 0, y: 1 }}
                            end={{ x: 1, y: 0 }}
                            style={{
                              width: 45,
                              height: 45,
                              borderRadius: 30,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Ionicons
                                name="chatbubble-ellipses"
                                color={
                                  route.params.permanence.activeChat == true
                                    ? "#fff"
                                    : "#c4c0c0"
                                }
                                size={16}
                              />
                            </View>
                          </LinearGradient>
                        )}
                      </View>
                    </View>
                  </LinearGradient>
                </View>
              </View>
            </View>
          </ImageBackground>

          <Modal
            animationType="fade"
            transparent={true}
            visible={cancelAppointmentModal}
          >
            <View
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.8)",

                borderRadius: 10,
                flexDirection: "row",
                flex: 1,
              }}
            >
              <View
                style={{
                  width: "80%",
                  height: "50%",
                  marginHorizontal: "10%",
                  marginVertical: "45%",
                  backgroundColor: "white",

                  borderRadius: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text h3 color="black">
                    Annuler ce rendez-vous
                    <Text h3 color={theme.colors.lightBlue}>
                      {"  "}
                      {"?"}
                    </Text>
                  </Text>

                  <Text
                    style={{ padding: "5%" }}
                    bold
                    size={14}
                    color={theme.colors.WARNING}
                  >
                    {cancelMessageError}
                  </Text>

                  <Button
                    loading={buttonLoadingCancelAppointment}
                    onPress={cancelAppointmentHandler}
                    style={{
                      backgroundColor: theme.colors.lightBlue,
                      marginTop: "20%",
                      marginBottom: "10%",
                    }}
                    mode="contained"
                  >
                    <Text style={{ padding: "1%" }} bold size={14}>
                      Annuler le rendez-vous
                    </Text>
                  </Button>

                  <Button
                    style={{ backgroundColor: "#0f0f0f" }}
                    onPress={hideCancelAppointmentModal}
                    mode="contained"
                  >
                    <Text style={{ padding: "5%" }} bold size={14}>
                      retour
                    </Text>
                  </Button>
                </View>
              </View>
            </View>
          </Modal>

       

          <Modal animationType="slide" visible={calendarModal}>
        
          <View style={{ padding: "2%" }}>
          
        </View>

            
            <View style={styles.containerPermanence}>
            
              

              <View
              style={{
                flexDirection: "row",
               
              
              }}
            >
              <View style={{ width: "20%" }}>
                <Ionicons
                  onPress={cancelCalendar} 
                  style={{ marginLeft: 10, marginTop: -5 }}
                  name="arrow-back-circle-sharp"
                  color={theme.colors.lightBlue}
                  size={30}
                />
              </View>

              <View style={{ width: "60%" }}>
                <Text   style={{ fontSize:25,color:theme.colors.lightBlue }}>
                  
                  {"Choissisez les Horaires"}
                </Text>
              </View>

             
              <View style={{ width: "10%" }}>
            <TouchableOpacity 
      style={styles.paypalButton}
      onPress={validAppointment}
    >
      <Text style={styles.paypalButtonText}>Valider</Text>
    </TouchableOpacity>
            </View>
            </View>
            <View
              style={{
                borderBottomColor: "grey",
                borderBottomWidth: 0.5,
                
              }}
            />





              <View style={{height:900}}>
                <FlatList
                  data={days}
                  renderItem={renderItemDay}
                  keyExtractor={(item, index) => index.toString()}
                  showsHorizontalScrollIndicator={true}
                  contentContainerStyle={{ paddingBottom: 350 }}
                />
              </View>
            </View>
          </Modal>

          <Modal animationType="slide" visible={validAppointmentModal}>
            <View>
              <View
                style={{
                  flexDirection: "row",
                  marginVertical: "3%",
                  marginHorizontal: "3%",
                  marginTop:  "5%",
                }}
              >
                <View style={{ width: "20%" }}>
                  <Ionicons
                    onPress={() => setValidAppointmentModal(false)}
                    style={{ marginLeft: 10, marginTop: -5 }}
                    name="arrow-back-circle-sharp"
                    color={theme.colors.lightBlue}
                    size={30}
                  />
                </View>

                <View style={{ width: "80%" }}>
                  <Text h4 color={theme.colors.lightBlue}>
                    {"RDV du "}
                    {dateAnnonce}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  borderBottomColor: "grey",
                  borderBottomWidth: 0.5,
                  marginBottom: "5%",
                }}
              />
              
                {handlePermanenceDetail.updateManager == true ? (
            

                  <View style={styles.formContainer}>
                  <View style={styles.rowContainer}>
                    <View style={styles.inputContainer}>
                      <Text style={styles.label}>Nom</Text>
                      <TextInput
                        style={styles.input}
                        onChangeText={(text) => setFirstnameCustomer(text)}
                        value={firstnameCustomer}
                        placeholder="Nom"
                      />
                    </View>
            
                    <View style={styles.inputContainer}>
                      <Text style={styles.label}>Prénom</Text>
                      <TextInput
                        style={styles.input}
                        value={lastnameCustomer}
                         onChangeText={(text) => setLastnameCustomer(text)}
                        placeholder="Prénom"
                      />
                    </View>
                  </View>
            
              
                </View>





                ) : null}


                <View style={styles.formContainer}>
                <View style={styles.inputContainer}>
                  <Text style={styles.label}>Téléphone</Text>
                  <TextInput
                    style={styles.input}
                    keyboardType='numeric'
                    onChangeText={(text) => setPhoneNumber(text.replace(/[^0-9]/g, ''))}
                    value={phoneNumber}
                    maxLength={10}
                    placeholder="Numéro de tel"
                  />
                </View>
          
                <View style={styles.inputContainer}>
                  <Text style={styles.label}>Sujet du rendez-vous</Text>
                  <TextInput
                    style={styles.input}
                    onChangeText={(text) => setPurpose(text)}
                    value={purpose}
                    placeholder="Sujet du rendez-vous"
                  />
                </View>
          
                <TouchableOpacity style={styles.button} onPress={confirmAppointment}>
                  <Text style={styles.buttonText}>{"Confirmer le rendez-vous"}</Text>
                </TouchableOpacity>
              </View>


            
             
            </View>
          </Modal>

          <Modal
            animationType="slide"
            transparent={true}
            visible={visibleMaxUser}
          >
            <View
              style={{
                backgroundColor: "rgba(0,0,0,0.7)",
              }}
            >
              <View
                style={{
                  alignItems: "center",
                  width: "90%",
                  height: "50%",
                  borderColor: "#ccc",
                  borderWidth: 1,
                  borderRadius: 20,
                  borderStyle: "solid",
                  backgroundColor: "white",
                  opacity: 1,

                  marginVertical: "50%",
                  marginLeft: "5%",
                  borderRadius: 4,
                  justifyContent: "center",
                }}
              >
                <Text
                  h4
                  color="#232043"
                  style={{ margin: theme.sizes.padding }}
                >
                  {"Désolé le nombre maximum de participants a été atteint."}
                </Text>

                <Button
                  style={{ backgroundColor: theme.colors.lightBlue }}
                  onPress={hideMaxUserModal}
                  mode="contained"
                >
                  <Text style={{ padding: "5%" }} bold size={14}>
                    {"J'ai compris"}
                  </Text>
                </Button>
              </View>
            </View>
          </Modal>

          <View style={{alignSelf:"center"}}>
  

            <Text style={{ marginTop: "2%", fontSize:25, color: "#0f0c29" }}>
              {permanence.description}
            </Text>

            {route.params.concept == "permanence" ? (
              <Text
                body3
                style={{ marginTop: "5%", color: "#0f0c29", fontWeight: "700" }}
              >
                {"Lieu de L'activité:"} {permanence.location}
              </Text>
            ) : (
              <Text
                body3
                style={{ marginTop: "5%", color: "#0f0c29", fontWeight: "700" }}
              >
                {"Lieu de L'évènement:"} {permanence.location}
              </Text>
            )}

            <View style={{ flexDirection: "row" }}>
              <View style={{ width: "85%" }}>
                <Text h4 style={{ marginTop: "10%", color: "#0f0c29" }}>
                  {"Organisé par : "}
                  {creatorDetail.firstnameCreator}{" "}
                  {creatorDetail.lastnameCreator}
                </Text>
              </View>

              <View style={styles.postAuthorWrapper}>
                <View
                  colors={"#2acaea"}
                  style={{
                    alignContent: "flex-end",
                    marginTop: "30%",
                    width: 45,
                    height: 45,
                    borderRadius: 30,
                  }}
                >
                  <View style={styles.imageReferent}>
                    <Image
                      source={{
                        uri:
                          route.params.permanence.idUser == undefined
                            ? route.params.permanence.avatarCreator
                            : creatorDetail.photo,
                      }}
                      resizeMode="cover"
                      style={styles.postProfileImgReferent}
                    />
                  </View>
                </View>
              </View>
            </View>
            <View style={{ marginTop: "5%", color: "#0f0c29" }}>
              <View style={styles.divider} />
            </View>

            {incomingAppointment.length !== 0 ? (
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: "5%",
                  }}
                >
                  <Text style={styles.titleHeader}>
                    Rendez-vous a venir
                  </Text>
                  <Text
                    style={{ marginTop: -14 }}
                    bold
                    h1
                    color={theme.colors.lightBlue}
                  >
                    {" "}
                    {"."}
                  </Text>
                </View>
                <FlatList
                  horizontal
                  data={incomingAppointment}
                  renderItem={renderIncomingAppointment}
                  keyExtractor={(item, index) => index.toString()}
                  showsHorizontalScrollIndicator={true}
                />
              </View>
            ) : null}

            <Modal animationType="slide" visible={participationModal}>
              <View style={styles.centeredView}>
                <View
                  style={{
                    flexDirection: "row",
                    marginVertical: "2%",
                    marginHorizontal: "3%",
                    paddingTop: Platform.OS === "ios" ? "8%" : 0,
                  }}
                >
                  <View style={{ width: "30%" }}>
                    <Ionicons
                      onPress={hideListParticipation}
                      style={{ marginLeft: 10 }}
                      name="arrow-back-circle-sharp"
                      color={theme.colors.lightBlue}
                      size={30}
                    />
                  </View>

                  <View style={{ width: "70%" }}>
                    <Text style={styles.titleHeader}>
                      {" "}
                      Rendez-vous passés
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    height: 1,
                    width: "100%",
                    backgroundColor: "#CED0CE",
                    marginBottom: "5%",
                  }}
                />

                <FlatList
                  horizontal
                  data={oldAppointment}
                  renderItem={renderOldAppointment}
                  showsHorizontalScrollIndicator={true}
                  keyExtractor={(item, index) => index.toString()}
                />
              </View>
            </Modal>
          </View>

        
            <View
              style={{
                height: 100,
                zIndex: -1,
                borderRadius: 20,

                overflow: "hidden",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20,
              }}
            ></View>
            <View style={{ paddingBottom: 130 }}></View>
          
        </ScrollView>

        <View style={styles.bottomBarSection
        }>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginHorizontal: 30,
            }}
          >
            <View>
              <Text
                h6
                style={{ color: "#0f0c29", opacity: 0.5, letterSpacing: 1 }}
              >
                Entrée
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Text
                  h5
                  style={{
                    color: "#0f0c29",
                    fontSize: 16,
                  }}
                >
                  {permanence.price !== undefined &&
                  permanence.price !== 0 &&
                  permanence.price !== null
                    ? permanence.price + "€  "
                    : "Gratuite"}
                </Text>
                <Text
                  h6
                  style={{
                    color: "#0f0c29",
                    fontSize: 13,
                  }}
                >
                  {permanence.priceTag !== undefined &&
                  permanence.priceTag !== 0 &&
                  permanence.priceTag !== null &&
                  permanence.price !== 0
                    ? "/ " + permanence.priceTag
                    : ""}
                </Text>
              </View>
            </View>

            {handlePermanenceDetail.updateManager == true ? (
              <TouchableOpacity onPress={userAppointmentHandler}>
                <View
                  style={{
                    width: 180,
                    height: 45,
                    borderRadius: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor:
                      handlePermanenceDetail.userStructure ==
                        idAllowParticipate ||
                      handlePermanenceDetail.updateManager == true
                        ? "#146cd9"
                        : "#ced5e0",
                  }}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View>
                      <Text style={{color:"white"}}>Prendre rendez-vous </Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            ) : incomingAppointment.length !== 0  ? (
              <View
                style={{
                  width: 200,
                  height: 50,
                  borderRadius: 15,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    handlePermanenceDetail.userStructure ==
                      idAllowParticipate ||
                    handlePermanenceDetail.updateManager == true
                      ? "#146cd9"
                      : "#ced5e0",
                }}
              >
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <View>
                    <Text>
                      RDV :{" "}
                      {moment
                        .utc(incomingAppointment[0].datesAppointment)
                        .format("dddd DD MMMM HH:mm")}{" "}
                    </Text>
                  </View>
                </View>
              </View>
            ) : ( 
              permanence.openAppointementUser !== false ?
              <TouchableOpacity onPress={userAppointmentHandler}>
                <View
                  style={{
                    width: 180,
                    height: 45,
                    borderRadius: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor:
                      handlePermanenceDetail.userStructure ==
                        idAllowParticipate ||
                      handlePermanenceDetail.updateManager == true
                        ? "#146cd9"
                        : "#ced5e0",
                  }}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View>
                      <Text>Prendre rendez-vous </Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>:
              <TouchableOpacity onPress={userCallHandler}>
              <View
                style={{
                  width: 180,
                  height: 45,
                  borderRadius: 15,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    handlePermanenceDetail.userStructure ==
                      idAllowParticipate ||
                    handlePermanenceDetail.updateManager == true
                      ? "#146cd9"
                      : "#ced5e0",
                }}
              >
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <View>
                    <Text>Contacter</Text>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
            )}
          </View>
        </View>

      </View>
    );
  }

  const chatHandler = () => {
    if (idUser == null || idUser == "undefined" || idUser == "") {
      if (expand == 0) {
        bottomSheetHandle.current.expand();
        setSetExpand(1);
      } else {
        bottomSheetHandle.current.close();
        setSetExpand(0);
      }
    } else {
      if (route.params.permanence.activeChat == true) {
        navigation.navigate("Chat", {
          firstname: firstname,
          lastname: lastname,
          idUser: idUser,
          photoUser: photoUser,
          concept: route.params.permanence.id,
          title: route.params.permanence.title,
          isManager: handlePermanenceDetail.updateManager,
        });

        SOCKET.connect();

        SOCKET.emit("joinGroup", {
          firstname: firstname,
          room: route.params.permanence.title,
        });
      }
    }
  };

  const loginHandler = () => {
    if (password.length < 6 || email.length < 4) {
      if (email.length < 4) {
        setMessage("Vérifiez vos informations et réessayez");
      }

      if (password.length < 6) {
        setMessage("Vérifiez vos informations et réessayez");
      }
    } else {
      setButtonLoading(true);

      const fetchResponse = async () => {
        await getData("idC")
          .then((data) => data)
          .then(async (idStructure) => {
            const loginData = {
              password: password,
              email: email,
              idStructure: idStructure,
            };

            const loginResponse = await loginActions(loginData);

            if (loginResponse.message == "login success") {
              closeBottomSheet();

              //const isManager = await doesHeManage(route.params.idCentre);

              const avatar =
                loginResponse.photo == undefined || loginResponse.photo == null
                  ? handlePermanenceDetail.setUpdateAvatar(
                      "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImagepermanence/profileDefault.png"
                    )
                  : handlePermanenceDetail.setUpdateAvatar(avatar);

              loginResponse.photo !== "" || loginResponse.photo !== undefined
                ? handlePermanenceDetail.setUpdateAvatar(loginResponse.photo)
                : handlePermanenceDetail.setUpdateAvatar(
                    "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImagepermanence/profileDefault.png"
                  );
              loginResponse.firstname !== null
                ? handlePermanenceDetail.setFirstname(loginResponse.firstname)
                : handlePermanenceDetail.setFirstname("");
              loginResponse.lastname !== null
                ? handlePermanenceDetail.setLastname(loginResponse.lastname)
                : handlePermanenceDetail.setLastname("");

              const isManager = loginResponse.isManager;
              const isHeManager = loginResponse.isHeManager;
              const isHeSuperAdmin = loginResponse.isHeSuperAdmin;

              isManager == true
                ? handlePermanenceDetail.setUpdateManager(true)
                : handlePermanenceDetail.setUpdateManager(false);

              isHeManager == true
                ? handlePermanenceDetail.setUpdateIsHeManager(true)
                : handlePermanenceDetail.setUpdateIsHeManager(false);

              isHeSuperAdmin == true
                ? handlePermanenceDetail.setUpdateIsHeSuperAdmin(true)
                : handlePermanenceDetail.setUpdateIsHeSuperAdmin(false);

              handlePermanenceDetail.setIdUser(loginResponse.idUser);
              handlePermanenceDetail.setUserStructure(
                loginResponse.userStructure
              );
              handlePermanenceDetail.setUserProfil(
                !handlePermanenceDetail.userProfil
              );

              userAppointmentHandler;

              handlePermanenceDetail.updatePermanence == "false"
                ? handlePermanenceDetail.setUpdatePermanence("true")
                : handlePermanenceDetail.setUpdatePermanence("false");

              setManage(isManager);
              setIdUser(loginResponse.idUser);
              setLastname(loginResponse.lastname);
              setFirstname(loginResponse.firstname);
              setPhotoUser(avatar);

              setButtonLoading(false);
            }

            if (
              loginResponse.message == "Mot de passe incorrect" ||
              loginResponse.message == "Email Inconnu"
            ) {
              setMessage("Email ou Mot de passe non reconnu");

              setButtonLoading(false);
            }
          });
      };

      fetchResponse();
    }
  };

  const userAppointmentHandler = () => {
    if (idUser == null || idUser == "undefined" || idUser == "") {

     
      navigation.navigate('Login')
    } else {
      if (
        handlePermanenceDetail.userStructure == idAllowParticipate ||
        handlePermanenceDetail.updateManager == true
      ) {
        setCalendarModal(true);
      }
    }
  };

  const deleteParticipationHandler = (item) => {
    const formData = {
      idUser: item.idUser,
      idConcept: route.params.permanence.id,
    };

    removeParticipation(formData);

    setUpdateParticipation(!updateParticipation);

    handlePermanenceDetail.updatePole == "false"
      ? handlePermanenceDetail.setUpdatePole("true")
      : handlePermanenceDetail.setUpdatePole("false");
  };

  const renderItemListParticipation = ({ item }) => {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            padding: 16,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Image
            source={{ uri: item.photo }}
            resizeMode="cover"
            style={styles.postListProfileImg}
          />

          <Text
            
            style={{
              color: "#000",
            }}
          >{`${item.firstname} ${item.lastname}`}</Text>

          <Button
            onPress={() => {
              deleteParticipationHandler(item);
            }}
            loading={loading}
            style={{ marginHorizontal: "10%", width: "20%" }}
          >
            retirer
          </Button>
        </View>

        <View
          style={{
            height: 1,
            width: "86%",
            backgroundColor: "#CED0CE",
            marginLeft: "5%",
          }}
        />
      </View>
    );
  };

  const goBack = () => {
    StatusBar.setHidden(false);

    Platform.OS == "android" ? StatusBar.setBackgroundColor("#FF573300") : null;

    navigation.goBack();
  };

  return jsx();
};






const styles = StyleSheet.create({

  formContainer: {
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 5,
    width:"50%",
    alignSelf:"center"
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  inputContainer: {
    flex: 1,
    marginBottom: 20,
    paddingLeft:10,
    paddingRight:10
  },
  label: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ddd',
    padding: 10,
    borderRadius: 5,
    backgroundColor: '#fff'
  },
  button: {
    backgroundColor: '#006cff',
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    marginTop: 20,
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold'
  },
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  sectionImageHeader:{
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Platform.OS === 'ios' ? 40 : 20,
    marginLeft: 30,
    marginRight: 30,
    zIndex: 1,

  },
  sectionImageFooter:{
    flex: 1,
    justifyContent: 'flex-end',
    position: 'relative',

  },
  titleHeader: {
    fontSize: 25,
    color: "black",
    fontWeight: "800",
    width: theme.sizes.ITEM_WIDTH * 0.8,
    textTransform: "uppercase",
    top: theme.sizes.SPACING + 90,
    marginLeft: 60,
  
  },
  titlePermanence: {
    fontSize: 25,
    color: "white",
    fontWeight: "800",
    width: theme.sizes.ITEM_WIDTH * 0.8,
    textTransform: "uppercase",
    top: theme.sizes.SPACING + 90,
    marginLeft: 60,
    margin:"2%"
  },
  bottomBarSection:{
    height:  theme.sizes.height *0.1,
    width:  theme.sizes.width,
    borderRadius: 20,
    position: 'absolute',
    bottom: 0,
    justifyContent: 'center',
    backgroundColor: '#f5f5f5',
  },
  footerContentView:{
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    paddingHorizontal: 30,
  },
  centeredView: {
    justifyContent: "center",
    flex: 1,
  },
  cardAppointment: {
    width: theme.sizes.width * 0.14,
    height: 250,
    margin: 20,
    backgroundColor: "#fcfcfc",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3.65,
    elevation: 3,
  },

  cardOldAppointment: {
    width: theme.sizes.width * 0.14,
    height: 200,
    margin: 20,
    backgroundColor: "#fcfcfc",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3.65,
    elevation: 3,
  },
  incomingAppointmentDate: {
    backgroundColor: "#0e114d",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    flexDirection: "row",
  },
  paypalButton: {
    backgroundColor: '#003087',
    padding: 10,
    alignItems: 'center',
    borderRadius: 5
  },
  paypalButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  oldAppointmentDate: {
    backgroundColor: "#405666",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    flexDirection: "row",
  },

  postAuthorWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },

  imageReferent: {
    borderColor: "#409ddb",
    borderWidth: 2,

    borderRadius: 30,
    width: 50.5,
    height: 50.5,
  },

  divider: {
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
    width: '100%'
  },

  imageNb: {
    borderRadius: 30,
    width: 50.5,
    height: 50.5,
    backgroundColor: theme.colors.lightBlue,
    opacity: 0.8,
    position: "absolute",
    left: -17,
    zIndex: 100,
  },

  postProfileImgReferent: {
    width: 40.5,
    height: 40.5,
    borderRadius: 30,
    margin: 3,
  },

  image: {
    borderColor: "#8741bb",
    borderWidth: 2,
    borderRadius: 30,
    width: 61.5,
    height: 61.5,
  },

  postProfileImg: {
    width: 55.5,
    height: 55.5,
    borderRadius: 30,
    margin: 3,
  },

  postListProfileImg: {
    width: 55.5,
    height: 55.5,
    borderRadius: 30,
    margin: 15,
  },
  postUsername: {
    fontWeight: "600",
  },

  postBottomLeftWrapper: {
    flexDirection: "row",
  },
  postDetailContainers: {
    marginRight: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  postDetailIcon: {
    marginRight: 0,
  },
  postDetailNumbers: {
    fontSize: 12,
  },

  containerPermanence: {
    backgroundColor: "#f5f5f5",
    
  },

  hourUnselected: {
    borderRadius: 5,
    margin: 10,

    backgroundColor: "#e6eced",
    minWidth: 80,
    minHeight: 45,
    justifyContent: "center",
  },

  hourSelected: {
    borderRadius: 5,
    margin: 10,

    backgroundColor: theme.colors.lightBlue,
    minWidth: 80,
    minHeight: 45,
    justifyContent: "center",
  },

  text: { alignSelf: "center" },
  unselected: { color: "white" },
  selected: { color: theme.colors.lightBlue },

  card: {
    backgroundColor: theme.colors.white,

    flex: 1,
    margin: "2%",
    borderRadius: 10,
  },
  textInput2: {
    width: 135,
    height: 55,
    color: "#0f0c29",
    borderColor: "#d1d1d1",
    borderWidth: 0,
    fontSize: 16,
    lineHeight: 15,
    marginBottom: theme.sizes.height * 0.036,
    marginRight: theme.sizes.width * 0.056,
    borderBottomColor: theme.colors.secondary,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
});

export default PermanenceDetail;

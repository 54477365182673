import * as ImagePicker from "expo-image-picker";
import React, { useState, useRef, useContext, useEffect,useCallback,useMemo } from "react";

import moment from "moment";
import { ManageContext } from "../provider/manageStateProvider";

import {
  StyleSheet,
  Dimensions,
  StatusBar,
  View,
  TouchableOpacity,
  Modal,
  FlatList,
  Image,
  ScrollView,
  Platform,
  SafeAreaView,
  
  
} from "react-native";

import argonTheme from "../constants/argonTheme";

import * as DocumentPicker from "expo-document-picker";

import { EditEventImageGallery } from "../actions/EditConceptGalleryAction";
import { editConceptImagePredefined } from "../actions/EditConceptPredefinedAction";
import { deleteConceptActions }  from "../actions/DeleteConcept";

import { TimePickerModal } from 'react-native-paper-dates';

import { theme } from "../constants";
import { Picker } from "@react-native-picker/picker";
import {
  Feather,
  MaterialIcons,
  Ionicons,
} from "@expo/vector-icons";

import {Toggle,Spinner, Input,ButtonGroup, Text,TopNavigation,Button,Icon, Layout,TopNavigationAction,Divider,IndexPath, Select, SelectItem } from '@ui-kitten/components';

const { width, height } = Dimensions.get("screen");

const EditConcept = ({ route, navigation }) => {

  const [imagePreset, setImagePreset] = useState([
    {
      id: 9,
      name: "blastoise",
      url: "https://pokeapi.co/api/v2/pokemon/9/",
      image:
        "https://image.freepik.com/photos-gratuite/vue-dessus-recadre-mains-cuisinier-aine-decoupage-carotte-cuisson-ragout-legumes_1098-20510.jpg",
    },
    {
      id: 10,
      name: "caterpie",
      url: "https://pokeapi.co/api/v2/pokemon/10/",
      image:
        "https://image.freepik.com/vecteurs-libre/carte-journee-internationale-du-yoga-femme-contre-ciel-coucher-soleil_1048-14205.jpg",
    },
    {
      id: 11,
      name: "metapod",
      url: "https://pokeapi.co/api/v2/pokemon/11/",
      image:
        "https://image.freepik.com/photos-gratuite/groupe-enfants-qui-etudient-ecole_1303-26838.jpg",
    },
    {
      id: 12,
      name: "butterfree",
      url: "https://pokeapi.co/api/v2/pokemon/12/",
      image:
        "https://image.freepik.com/photos-gratuite/mains-femme-aide-puce-telephone-mobile_35534-132.jpg",
    },
    {
      id: 13,
      name: "weedle",
      url: "https://pokeapi.co/api/v2/pokemon/13/",
      image:
        "https://image.freepik.com/photos-gratuite/asiatique-jolie-fille-lunettes-sac-dos-assise-livre-ciel-bleu_9083-3226.jpg",
    },
    {
      id: 14,
      name: "kakuna",
      url: "https://pokeapi.co/api/v2/pokemon/14/",
      image:
        "https://image.freepik.com/photos-gratuite/gros-plan-image-programmeur-travaillant-son-bureau-dans-bureau_1098-18707.jpg",
    },
    {
      id: 15,
      name: "beedrill",
      url: "https://pokeapi.co/api/v2/pokemon/15/",
      image:
        "https://image.freepik.com/photos-gratuite/deux-boxeurs-professionnels-smoky-noir_155003-14259.jpg",
    },

    {
      id: 17,
      name: "pidgeottop",
      url: "https://image.freepik.com/photos-gratuite/famille-noire-profiter-ete-ensemble-dans-cour_53876-22989.jpg",
      image:
        "https://image.freepik.com/photos-gratuite/famille-noire-profiter-ete-ensemble-dans-cour_53876-22989.jpg",
    },

    {
      id: 19,
      name: "pidgeottol",
      url: "https://i.f1g.fr/media/cms/616x347_cropupscale/2020/09/23/10ec77d466dfff13488c90503f887c870eb97a6b897bcf194cbd96addfbe3b31.jpeg",
      image:
        "https://i.f1g.fr/media/cms/616x347_cropupscale/2020/09/23/10ec77d466dfff13488c90503f887c870eb97a6b897bcf194cbd96addfbe3b31.jpeg",
    },

    {
      id: 20,
      name: "pidgeottof",
      url: "https://www.francetvinfo.fr/pictures/kefDba1bJOWDQatk6-eRgK9yR0I/752x423/2021/04/27/6087cd30886a2_5f1ed83c7c215_maxsportsfrtwo618855.jpg",
      image:
        "https://www.francetvinfo.fr/pictures/kefDba1bJOWDQatk6-eRgK9yR0I/752x423/2021/04/27/6087cd30886a2_5f1ed83c7c215_maxsportsfrtwo618855.jpg",
    },

    {
      id: 18,
      name: "raticate",
      url: "https://image.freepik.com/photos-gratuite/jeune-mere-passant-du-temps-sa-fille_23-2148988991.jpg",
      image:
        "https://image.freepik.com/photos-gratuite/reunion-brainstorming-travail-equipe-nouveau-projet-demarrage-milieu-travail_2034-1617.jpg",
    },

    {
      id: 21,
      name: "boxe",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/boxe.png",
    },
 
    {
      id: 2,
      name: "boxe3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/boxe3.jpeg",
    },
    {
      id: 22,
      name: "yoga",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/coaching.jpg",
    },
    {
      id: 27,
      name: "yogaillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/yogaillu.jpg",
    },

    {
      id: 1,
      name: "yoga2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/yoga2.jpeg",
    },
    {
      id: 4,
      name: "yoga3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/yoga3.jpeg",
    },

    {
      id: 23,
      name: "art",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/Yoga.png",
    },
    {
      id: 40,
      name: "art1",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/art.png",
    },

    {
      id: 41,
      name: "art2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/art.jpg",
    },
    {
      id: 42,
      name: "art3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/art2.jpg",
    },
    {
      id: 43,
      name: "art4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/art3.jpg",
    },

    {
      id: 45,
      name: "culinaire",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/culinaire.jpg",
    }, 
    {
      id: 56,
      name: "culinaire2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/culinaire2.jpeg",
    },

    {
      id: 46,
      name: "familli",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/familli.jpg",
    },

    {
      id: 47,
      name: "famille1",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/famille1.jpg",
    },

    {
      id: 48,
      name: "math",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math.jpg",
    },
    {
      id: 49,
      name: "math2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math2.jpg",
    },
    {
      id: 50,
      name: "math4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math4.jpg",
    },
    {
      id: 51,
      name: "math5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math5.jpg",
    },
    {
      id: 52,
      name: "math6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math6.jpg",
    },
    {
      id: 53,
      name: "math7",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math7.jpg",
    },
    {
      id: 54,
      name: "math8",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math8.jpg",
    },

    {
      id: 55,
      name: "math9",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math9.jpg",
    },

    {
      id: 7,
      name: "musique",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique.jpg",
    },

    {
      id: 57,
      name: "musique2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique2.jpg",
    },
    {
      id: 58,
      name: "musique3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique3.jpg",
    },
    {
      id: 59,
      name: "musique4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique4.jpg",
    },

    {
      id: 60,
      name: "musique5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique5.jpg",
    },

    {
      id: 61,
      name: "musique6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique6.jpg",
    },

    {
      id: 62,
      name: "musique7",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique7.jpg",
    },
    {
      id: 63,
      name: "musique8",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique8.jpg",
    },
    {
      id: 64,
      name: "parentalité",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/parentalité.jpg",
    },
    {
      id: 65,
      name: "scolaire",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/scolaire.jpg",
    },
    {
      id: 66,
      name: "scolaire2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/scolaire2.jpg",
    },
    {
      id: 67,
      name: "scolaire3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/scolaire3.jpg",
    },

    {
      id: 68,
      name: "traveau",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/traveau.jpg",
    },
    {
      id: 69,
      name: "lecture",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture.jpg",
    },
    {
      id: 70,
      name: "lecture2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture2.jpg",
    },
    {
      id: 71,
      name: "lecture3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture3.jpg",
    },

    {
      id: 72,
      name: "lecture4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture4.jpg",
    },

    {
      id: 73,
      name: "lecture5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture5.jpg",
    },
    {
      id: 74,
      name: "lecture6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture6.jpg",
    },

    {
      id: 75,
      name: "loisir",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir.jpg",
    },

    {
      id: 76,
      name: "loisir2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir2.jpg",
    },

    {
      id: 77,
      name: "loisir4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir4.jpg",
    },
    {
      id: 78,
      name: "loisir5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir5.jpg",
    },
    {
      id: 79,
      name: "loisir6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir6.jpg",
    },
    {
      id: 80,
      name: "loisir7",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir7.jpg",
    },
    {
      id: 81,
      name: "loisir8",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir8.jpg",
    },

    {
      id: 24,
      name: "bureutique",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/bureautique.png",
    },
    {
      id: 39,
      name: "alphabet",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/alphabet.jpeg",
    },

    {
      id: 24,
      name: "français",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/français.png",
    },

    {
      id: 99,
      name: "mathKid",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/mathKid.png",
    },

    {
      id: 26,
      name: "potterie",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/potterie.png",
    },
    
    {
      id: 5,
      name: "potterie4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/potterie4.jpeg",
    },
  
  
    {
      id: 37,
      name: "musique9",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique9.jpeg",
    },
  
    {
      id: 38,
      name: "musique10",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique10.jpeg",
    },

  
 
    {
      id: 87,
      name: "informatique2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/informatique2.jpeg",
    },
  
    
    {
      id: 92,
      name: "couture2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/couture2.jpeg",
    },
  
   
 
  
  
   
    {
      id: 93,
      name: "perma6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma6.jpeg",
    },
  
    {
      id: 97,
      name: "perma1",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma1.jpeg",
    },
    {
      id: 98,
      name: "perma2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma2.jpeg",
    },

    {
      id: 96,
      name: "perma3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma3.jpeg",
    },
  
  
    {
      id: 90,
      name: "perma4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma4.jpeg",
    },
  
    {
      id: 91,
      name: "perma5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma5.jpeg",
    },
  
 

    {
      id: 28,
      name: "sportIllu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/sportIllu.jpg",
    },
    {
      id: 29,
      name: "beachillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/beachillu.png",
    },
    {
      id: 30,
      name: "projectillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu.jpg",
    },
   
    {
      id: 32,
      name: "constructillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/constructillu.png",
    },
    {
      id: 33,
      name: "projecttillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu.jpg",
    },
    {
      id: 34,
      name: "projecttillu2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu2.png",
    },

    {
      id: 35,
      name: "pitchtillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/pitchillu.png",
    },
    {
      id: 36,
      name: "contentillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/contentillu.png",
    },

  ]);





  const data = [
    {
       "id": 1, 
       "name": "Mensuelle",
    },
    {
       "id": 2, 
       "name": "Ponctuelle",
    },
    {
       "id": 3, 
       "name": "Trimestrielle",
    },
    {
       "id": 4, 
       "name": "Annuelle",
    }
]





const handleConcept = useContext(ManageContext);

  const [idCentre, setIdCentre] = useState(route.params.idCentre);
  const [idPole, setIdPole] = useState(route.params.idPole);
 
  const [date, setDate] = useState(new Date(route.params.activity.date));
  const [time, setTime] = useState(new Date(route.params.activity.date));

  const [image, setImage] = useState(route.params.activity.preview);
  const [price, setPrice] = route.params.activity.price !== null ?   useState(route.params.activity.price.toString()) : useState(route.params.activity.price);
  const [limitParticipation, setLimitParticipation] =  route.params.activity.maxUser !== null ?  useState(route.params.activity.maxUser.toString()) : useState(route.params.activity.maxUser);
  
  const [dateStart, setDateStart] = route.params.activity.dateStart !== undefined ? useState(new Date(route.params.activity.dateStart)) : useState(new Date());
  const [dateEnd, setDateEnd] = route.params.activity.dateEnd !== undefined ? useState(new Date(route.params.activity.dateEnd)) : useState(new Date());
  const [title, setTitle] = useState(route.params.activity.title);
  const [priceTag, setPriceTag] = route.params.activity.priceTag == "" ? useState("Ponctuelle"): useState(route.params.activity.priceTag);
  const [description, setDescription] = useState(route.params.activity.description);

  const [fileName, setFileName] = useState("");
  const [location, setLocation] = useState(route.params.activity.location);
  const [mode, setMode] = useState("date");
  const [show, setShow] = useState(false);
  const [deletePoleModal, setDeletePoleModal] = useState(false);
  const [activeChat, setActiveChat] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dayActivity, setDayActivity] = useState([]);
  const [samedi, setSamedi] = useState(false);
  const [dimanche, setDimanche] = useState(false);
  const [lundi, setLundi] = useState(false);
  const [mardi, setMardi] = useState(false);
  const [mercredi, setMercredi] = useState(false);
  const [jeudi, setJeudi] = useState(false);
  const [vendredi, setVendredi] = useState(false);
  const [timeActivity, setTimeActivity] = useState(route.params.activity.timeActivity);
  const [calendarModal, setCalendarModal] = useState(false);
  const [priceLimitModal, setPriceLimitModal] = useState(false);

  const [visible, setVisible] = useState(false);
  const [visibleTimeStart, setVisibleTimeStart] = useState(false);
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(false);
  const [presetModal, setPresetModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  
  const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0));


  const onDismissStart = useCallback(() => {
    setVisibleTimeStart(false)
  }, [setVisibleTimeStart])

  const onConfirmStart = useCallback(
    ({ hours, minutes }) => {
      setVisibleTimeStart(false);
      setDateStart({ hours, minutes })
      
    },
    [setVisibleTimeStart]
  );

  const onDismissEnd = useCallback(() => {
    setVisibleTimeEnd(false)
  }, [setVisibleTimeEnd])

  const onConfirmEnd = useCallback(
    ({ hours, minutes }) => {
      setVisibleTimeEnd(false);
      setDateEnd({ hours, minutes })
      
    },
    [setVisibleTimeEnd]
  );





  const showTimepicker = () => {
    setVisibleTimeStart(true)
  };



  const [showDateEnd, setShowDateEnd] = useState(false);
  
  const onChange = (event, selectedDate) => {

    
    const currentDate = selectedDate || dateStart;
    setShow(Platform.OS === 'ios');
    setDateStart(currentDate);
    

  };


  const onChangeDateEnd = (event, selectedDateEnd) => {

    
    const currentDate = selectedDateEnd || dateEnd;
    setShowDateEnd(Platform.OS === 'ios');
    setDateEnd(currentDate);
    
  };
  const showMode = (currentMode) => {

      
    setShow(true);
    setMode(currentMode);
  };


  const showModeDateEnd = (currentMode) => {

  
    setShowDateEnd(true);
    setMode(currentMode);
  };

  const showDatepicker = () => {
    showMode('date');
  };

  const showDateEndpicker = () => {
    showModeDateEnd('date');
  };

  
  const showDescriptionModal = () => {
    setDescriptionModal(true);
  };
  
  const hideDescriptionModal = () => {
    setDescriptionModal(false);
  };
 
  
  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({ 
    
     copyToCacheDirectory: true })
      .then(response => {
        if (response.type == 'success') {          
          let { name, size, uri } = response;

       / ------------------------/
          if (Platform.OS === "android" && uri[0] === "/") {
             uri = `file://${uri}`;
             uri = uri.replace(/%/g, "%25");
          }
      / ------------------------/

          let nameParts = name.split('.');
          let fileType = nameParts[nameParts.length - 1];
          var fileToUpload = {
            name: name,
            size: size,
            uri: uri,
            type: "application/" + fileType
          };
     
          setFileName(fileToUpload);
        } 
      });
    
}



  const onDaySelect = (day) => {
    var contain = dayActivity.includes(day);
  
    if (contain == false) {
  
      dayActivity.push(day);
  
    } else {
     
  
      for (var i = 0; i < dayActivity.length; i++) {
        if (dayActivity[i] === day) {
          dayActivity.splice(i, 1);
        }
      }
    }
    if (day == "Samedi") {
      if (dayActivity.includes("Samedi")) {
        setSamedi(true);
      } else {
        setSamedi(false);
      }
    }
    if (day == "Dimanche") {
      if (dayActivity.includes("Dimanche")) {
        setDimanche(true);
      } else {
        setDimanche(false);
      }
    }
    if (day == "Lundi") {
      if (dayActivity.includes("Lundi")) {
        setLundi(true);
      } else {
        setLundi(false);
      }
    }
    if (day == "Mercredi") {
      if (dayActivity.includes("Mercredi")) {
        setMercredi(true);
      } else {
        setMercredi(false);
      }
    }
    if (day == "Jeudi") {
      if (dayActivity.includes("Jeudi")) {
        setJeudi(true);
      } else {
        setJeudi(false);
      }
    }
  
    if (day == "Mardi") {
      if (dayActivity.includes("Mardi")) {
        setMardi(true);
      } else {
        setMardi(false);
      }
    }
  
    if (day == "Vendredi") {
      if (dayActivity.includes("Vendredi")) {
        setVendredi(true);
      } else {
        setVendredi(false);
      }
    }
  };
  
  const publishHandler = async () => {

    setButtonLoading(true)

    
    if (fileName.length !== 0) {


      var dayActivityStringified = JSON.stringify(dayActivity);
      
      const formData = new FormData();

      var castedDateEnd = moment(dateEnd).toDate();

        const dateEnd = castedDateEnd.toString();

        var castedDateStart = moment(dateStart).toDate();

        const dateStart = castedDateStart.toString();


      const dates = (new Date(date)).toUTCString();

      const lastPrice = price == null ||  price == "" ? 0 : price
      const maxParticipation = limitParticipation == null ||  limitParticipation == ""   ? 99 : limitParticipation

      formData.append("delete", "false");
      formData.append("title", title);
      formData.append("idCentre", idCentre);
      formData.append("dayActivity", dayActivityStringified);
      formData.append("timeActivity", timeActivity);
      formData.append("idPole", idPole);
      formData.append("description", description);
      formData.append("date", dates );
      formData.append("location", location);
      formData.append("price", lastPrice);
      formData.append("limitParticipation", maxParticipation);
      formData.append("idActivity", route.params.activity.id);
      formData.append("dateStart", dateStart);
      formData.append("dateEnd", dateEnd);
      formData.append("idEvent", route.params.activity.idEvent == undefined ? "0":route.params.activity.idEvent);
      formData.append("priceTag", priceTag);    
      formData.append("previewGallery", {
        name: "image.png",
        type: "image/png",
        uri:fileName
      });
     

     const response = await EditEventImageGallery(formData, route.params.concept);

    


if (response.message =='Good'){

        
  if (route.params.concept == "event") {
    handleConcept.updateEvent == "false"
      ? handleConcept.setUpdateEvent("true")
      : handleConcept.setUpdateEvent("false");
      
  setButtonLoading(false)
  navigation.navigate("Home",{idCentre:route.params.idCentre})
  }     
  
  
  if (route.params.concept == "activity") {

    
    handleConcept.updatePole == "false"
      ? handleConcept.setUpdatePole("true")
      : handleConcept.setUpdatePole("false");
      
  setButtonLoading(false)
  navigation.navigate("Home",{idCentre:route.params.idCentre})

    
  }

 

  if (route.params.concept == "activityHoliday") {
    setButtonLoading(false)

    navigation.navigate("Home",{idCentre:route.params.idCentre})  
    
  }


    } else{

      setVisible(true)
      setButtonLoading(false)



    }

    } else {


      
      const formData = {
        title: title,
        idPole: route.params.idPole,
        image: image,
        description: description,
        dayActivity: dayActivity,
        timeActivity: timeActivity,
        date: date,
        price: price,
        limitParticipation:limitParticipation,
        location:location,
        idCentre: idCentre,
        idActivity: route.params.activity.id,
        idEvent: route.params.activity.idEvent,
        priceTag:  priceTag,
        dateEnd: dateEnd,
        dateStart:dateStart,
      };

    editConceptImagePredefined(formData, route.params.concept);


 


    setTimeout(() => {

    if (route.params.concept == "event") {
        handleConcept.updateEvent == "false"
          ? handleConcept.setUpdateEvent("true")
          : handleConcept.setUpdateEvent("false");
          setButtonLoading(false)
    
          navigation.navigate("Home",{idCentre:route.params.idCentre})
      }     
      
      
      if (route.params.concept == "activity") {

        
        handleConcept.updatePole == "false"
          ? handleConcept.setUpdatePole("true")
          : handleConcept.setUpdatePole("false");
          setButtonLoading(false)
    
          navigation.navigate("Home",{idCentre:route.params.idCentre})
        
      }



      if (route.params.concept == "activityHoliday") {
        setButtonLoading(false)
        navigation.navigate("Home",{idCentre:route.params.idCentre})
    
        
      }

     
   
    }, 

    2000);




    }

  
  };

  const validImage = () => {
    setPresetModal(false);
  };

  const cancelImage = () => {
    setPresetModal(false);

    setImage("");
  };
  const cancelCalendar = () => {
    setCalendarModal(false);

  };

  const onClickItem = (item, index) => {
    setImage(item.image);
    setFileName("");

    const newArrData = imagePreset.map((e, index) => {
      if (item.name == e.name) {
        return {
          ...e,
          selected: true,
        };
      }
      return {
        ...e,
        selected: false,
      };
    });

    setImagePreset(newArrData);
  };

  const renderItemPreset = ({ item, index }) => {
    return (
      <View style={{flex:1}}>
      <TouchableOpacity
        onPress={() => onClickItem(item, index)}
        style={{
          backgroundColor: item.selected ? "#0f0c29" : "white",
          padding: 2,
          borderRadius: 10,
        }}
      >
        <View style={stylesPreset.item}>
          <Image
            style={stylesPreset.image}
            resizeMode="contain"
            source={{ uri: item.image }}
          />
        </View>
      </TouchableOpacity>
      </View>
      
    );
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      allowsMultipleSelection: false,
      aspect: [3, 2],
      quality: 0.2
    });

    if (!result.canceled) {
      setFileName(result.uri);
      setImage("");
    }
  };

  
  const hideDeletePoleModal = () => {
    setDeletePoleModal(false);
  };
  const showDeletePoleModal = () => {
    setDeletePoleModal(true);
  };

  const titleErrors = () => {
    return  title.length > 18;
  };
  const cancelPriceLimit = () => {
    setPriceLimitModal(false);

    setPrice(null);
    setLimitParticipation(null);
  };
  const DeletePoleHandler = () => {

    setButtonLoading(true)

    const deleteConceptData = {
      idActivity: route.params.activity.id,
      urlImage: route.params.activity.preview,
      idEvent: route.params.activity.idEvent,
      idPole: idPole,
      
    };

    deleteConceptActions(deleteConceptData, route.params.concept);

     setTimeout(() => {

      setButtonLoading(false)
      hideDeletePoleModal()

    if (route.params.concept == "event") {
      handleConcept.updateEvent == "false"
        ? handleConcept.setUpdateEvent("true")
        : handleConcept.setUpdateEvent("false");
    } 
    if (route.params.concept == "activity") {
      handleConcept.updatePole== "false"
        ? handleConcept.setUpdatePole("true")
        : handleConcept.setUpdatePole("false");
    }

    if (route.params.concept == "holiday") {
      handleConcept.updateHoliday== "false"
        ? handleConcept.setUpdateHoliday("true")
        : handleConcept.setUpdateHoliday("false");
    }

    navigation.navigate("Home",{idCentre:route.params.idCentre})
   
    }, 
    2000);

    
  };
  const LoadingIndicator = (props) => (


    buttonLoading == true ?
    <View style={[props.style, styles.indicator]}>
      <Spinner size='small'/>
    </View> :null
  );
  
  
  return (
    <>
      <View style={styles.Container}>
       
        <Layout style={styles.container} level='1'>
        <TopNavigation
          alignment='center'
          title={
            route.params.concept == "event"
              ? "Modifier Evénement"
              : "Modifier Activité"
          }
          
          accessoryLeft={
            
            <TouchableOpacity    onPress={() => navigation.navigate("Home",{idCentre:route.params.idCentre})} > 
            <Ionicons name="arrow-back"
          color={"#4e32a8"}
          size={35} />
  
          </TouchableOpacity>
        }
          accessoryRight={ 
            
  
              (route.params.subActivity !== undefined  ? 
            
              <Button
            onPress={publishHandler}
            appearance='outline' 
            accessoryLeft={LoadingIndicator}
            color={
              !title ||
              !description ||
              !location ||
              !date ||
              (!image && !fileName) ||
              dayActivity.length == 0
                ? "grey"
                : "#0f0c29"
            }
           
          >
            <Text
              bold
              disable={
                !title ||
                !description ||
                !location ||
               dayActivity.length == 0
              }
              size={14}
              color={
                !title ||
                !description ||
                !location ||
                !date ||
                (!image && !fileName) ||
                dayActivity.length == 0
                  ? "#bdbdbd"
                  : "white"
              }
            >
              Modifier
            </Text>
          </Button>:
          <Button
          onPress={publishHandler}
          appearance='outline' 
          accessoryLeft={LoadingIndicator}
           
            color={
              !title ||
              !description ||
              !location ||
              !date ||
              (!image && !fileName) 
                ? "grey"
                : "#0f0c29"
            }
           
          >
            <Text
              bold
              disable={
                !title ||
                !description ||
                !location 
               
              }
              size={14}
              color={
                !title ||
                !description ||
                !location ||
                !date ||
                (!image && !fileName) 
                  ? "#bdbdbd"
                  : "white"
              }
            >
              Modifier
            </Text>
          </Button>
              )
        }
        />
      </Layout>




        <View>
          <Divider />
        </View>

     
          <View>
          

      <View style={{alignSelf:"center",width:"50%",}}>
            
  
      <Input
                  placeholder="Titre"      
                 
                  onChangeText={text => setTitle(text.slice(0, 13))}
                  value={title}
                 
                ></Input>
              
              
    
                <Input
            multiline={true}
            textStyle={{ minHeight: 64 }}
            placeholder={route.params.activity.description}
            onChangeText={(text) => setDescription(text)}
            value={description}
           
          />
    
                <Input
                  placeholder={route.params.activity.location}
                
             
                  onChangeText={(text) => setLocation(text)}
                  value={location}
               
                ></Input>
                
    
                <Layout style={styles.containerInput} level='1'>
    
                <Input
                  style={styles.input}
                  value={price}
                  placeholder={route.params.activity.price}
                  keyboardType='numeric'
                  onChangeText={nextValue => setPrice(nextValue.replace(/[^0-9]/, ''))}
                />
      

     
              <Select
              selectedIndex={selectedIndex}
              status="basic"
              size="large"
              value={data[selectedIndex.row]?.name}
              onSelect={(index) => {
                setSelectedIndex(index);
                setPriceTag(data[index.row]?.name)
              }}
            >
              {data.map((item, i) => (
                <SelectItem title={item.name} />
              ))}
            </Select>
            
                    <Input
                    style={styles.input}
                    value={limitParticipation}
                    placeholder={route.params.activity.limitParticipation}
                    onChangeText={nextValue => setLimitParticipation(nextValue.replace(/[^0-9]/, ''))}
                  />
                  </Layout>
        
               
        
     

      
      </View>
    






    {route.params.concept == "event" ?
    
    <View style={{alignSelf:"center"}}>
           
      <Button  onPress={showTimepicker} color={theme.colors.lightBlue} style={styles.DateButton}>
      <View style={{flexDirection :"row"}}>
     <View style={{marginLeft:'10%'} }>
    <Text style={{color:'white'}} category='s5'>
             Heure :
        </Text>
     </View>
 
     <View>
    
     <Text style={{color:'white'}} category='h5'>
     {moment(dateStart)
     .format('HH:mm')
     .toLocaleUpperCase()}
 
     </Text>
 
 
     </View>
    
 
   </View>
 
   </Button>
   <TimePickerModal
           visible={visibleTimeStart}
           onDismiss={onDismissStart}
           onConfirm={onConfirmStart}
           hours={12}
           minutes={14}
           locale="fr"
         />


         <TimePickerModal
           visible={visibleTimeEnd}
           onDismiss={onDismissEnd}
           onConfirm={onConfirmEnd}
           hours={12}
           minutes={14}
           locale="fr"
         />
         </View>
            : null}
          </View>
      
            
            <View style={{ paddingTop: "1%" }}>
              <Divider />
            </View>

            <View style={{ flex: 1 }}>

            {

              route.params.subActivity !== undefined ?   
              
              
             
              <View style={{flexDirection :"row",marginTop:'5%', alignSelf:'center'}}>
              <TimePickerModal
              visible={visibleTimeStart}
              onDismiss={onDismissStart}
              onConfirm={onConfirmStart}
              hours={12}
              minutes={14}
              locale="fr"
            />
   
   
            <TimePickerModal
              visible={visibleTimeEnd}
              onDismiss={onDismissEnd}
              onConfirm={onConfirmEnd}
              hours={12}
              minutes={14}
              locale="fr"
            />
            </View>


          : null

      
            }
            {   route.params.concept == "activity " ?  
            <View>
            <View style={{ marginTop: "1%", marginHorizontal: "10%" }}>
            <Text  color={"#2d4150"}>
              Choisir le(s) Jour(s)
            </Text>
          </View>
  
          <View style={{flexDirection:'row',alignSelf:"center"}}>
           
            
          <Button  appearance={
            lundi == true ? "filled": "outline"
          } onPress={() => onDaySelect("Lundi")}>Lundi</Button>
          <Button appearance={
            mardi == true ? "filled": "outline"
          }  onPress={() => onDaySelect("Mardi")}>Mardi</Button>
          <Button appearance={
            mercredi == true ? "filled": "outline"
          } onPress={() => onDaySelect("Mercredi")}>Mercredi</Button>
          <Button appearance={
            jeudi == true ? "filled": "outline"
          } onPress={() => onDaySelect("Jeudi")}>Jeudi</Button>
          <Button appearance={
           vendredi == true ? "filled": "outline"
          } onPress={() => onDaySelect("Vendredi")}>Vendredi</Button>
          <Button  appearance={
            samedi == true ? "filled": "outline"
          } onPress={() => onDaySelect("Samedi")}>Samedi</Button>
          <Button appearance={
            dimanche  == true ? "filled": "outline"
          } onPress={() => onDaySelect("Dimanche")}>Dimanche</Button>
  
          <Input
          multiline={true}
          textStyle={{ minHeight: 64 }}
          placeholder='Décrivez les Horaires'
          onChangeText={(text) => setTimeActivity(text)}
            value={timeActivity}
         
        />
        
          </View>
  
     
        
         </View> : null}
              
          
           
      
            <Modal animationType="slide" visible={presetModal}>
            <View style={styles.centeredView}>
              
              
              <Layout  level='1'>
              <TopNavigation
                alignment='center'
                title='Choisir une Image'
                
                accessoryLeft={
                  
                  <TouchableOpacity   onPress={cancelImage} > 
                  <Ionicons name="arrow-back"
                color={"#4e32a8"}
                size={35} />
        
                </TouchableOpacity>
              }
                accessoryRight={ <Button
                
                  onPress={() => {
                    setPresetModal(false);
                  }}
                >
                  <Text
                    bold
                    onPress={validImage}
                    size={14}
                   
                  >
                    Valider
                  </Text>
                </Button>}
              />
            </Layout>
        
             <Divider></Divider>
             <View style={{flex:1}}>
             <View style={{height: 1000}}>
              <FlatList
              contentContainerStyle={{flexGrow: 1}}
                numColumns={6}
                extraData={imagePreset}
                data={imagePreset}
                scrollEnabled={true}
                renderItem={renderItemPreset}        
                keyExtractor={(item) => item.id}
              />
              </View>
              </View>
            </View>
          </Modal>

        <Modal
          animationType={"slide"}
          transparent={true}
          visible={deletePoleModal}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.8)",
            }}
          >
            <View style={styles.styleModal}>
             

              <View style={styles.modalStyle}>
                <View style={styles.socialTitle}>
                  <Text
                    bold
                    style={{ marginBottom: "10%" }}
                    color='#172B4D'
                    size={18}
                  >
                  {
                    route.params.concept == "event"
                      ? "Supprimer cet évènement ?"
                      : "Supprimer cette activité ?"
                  }
                   
                  </Text>
                  <Text bold color='#172B4D' size={14}>
                    

                    {
                      route.params.concept == "event"
                        ? "Attention si vous supprimer cet évènement toutes les informations qu'il contient seront égalements supprimés"
                        : "Attention si vous supprimer cette activité toutes les informations qu'elle contient seront égalements supprimés"
                    }
                     
                  </Text>
                </View>
                <View
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Button
                    onPress={DeletePoleHandler}
                    loading={buttonLoading} 
                    color='#960541'
                    style={styles.createButtonDelete}
                  >
                    <Text bold size={14} color={"#fff"}>
                      Supprimer
                    </Text>
                  </Button>
                  <Button
                  onPress={hideDeletePoleModal}
                    loading={buttonLoading} 
                    color='#172B4D'
                    style={styles.createButtonDelete}
                  >
                    <Text bold size={14} color={"#fff"}>
                      Annuler
                    </Text>
                  </Button>
                </View>
              </View>
            </View>
          </View>
        </Modal>

       


        <View
         
        >
          <View>
             
            <View style={styles.contentContainer}>

    
        {route.params.concept == "activityHoliday" ?   
        <View> 
        <TouchableOpacity onPress={() => setCalendarModal(true)}>
        <View style={{ flexDirection: "row"}}>
          <View
            style={{
              width: "12%",
              alignItems: "flex-start",
              marginVertical:"1%"
            }}
          >
            <Feather name='command' color={"#b57704"} size={22} />
          </View>
          <View
            style={{
              width: "50%",
              alignItems: "flex-start",
              marginVertical:"1%"
              
            }}
          >
            <Text h4 color='#302b63'>
              Date
            </Text>
          </View>


          <View>
          
          { (timeActivity !== "") ? <View
            style={{
              
              width:'10%',marginHorizontal:"7%"
               
            }}>
            <Image
            style={{width: 30,
              height: 30,
              resizeMode: 'stretch'}}
              source={ {uri: "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/approved.png" }}
          />        
          </View>: <View
          style={{
            
            marginTop:"2%",justifyContent:"center",marginRight:"17%"
          }}><Text h6 color="#969595" size={12}>
          ( Optionnel )
          </Text>
          </View>
            }
    
        
        </View>
        </View>
      </TouchableOpacity>
          
      <View style={{ marginTop: "2%" }}>
      <Divider />
    </View> 
    </View>: null }


           

              <View
                style={{
                  flexDirection: "row",
                  paddingTop: "2%",
                  paddingBottom: "1%",
                }}
              >
                <View
                  style={{
                    width: "12%",

                    paddingTop: "1%",
                  }}
                >
                  <View>
                    <Ionicons name='ios-image' color={"#036963"} size={22} />
                  </View>
                </View>

                <View
                  style={{
                    width: "65%",

                    paddingTop: "1%",
                    paddingRight: 15,
                  }}
                >
                  <TouchableOpacity onPress={() => setPresetModal(true)}>
                    <View>
                      <Text h4 color='#302b63' size={12}>
                        Utiliser Image Prédéfinie
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    width: "10%",
                  }}
                >
                  <View>
                    <Image
                    style={{ width: 65, height: 50, resizeMode: "stretch" }}
                    source={image !== "" ? { uri: image } : null}
                  />
                  </View>
                </View>
              </View>

              <Divider />

   

          

              <TouchableOpacity onPress={showDeletePoleModal}>
                <View
                  style={{
                    flexDirection: "row",
                    paddingTop: "1%",
                    paddingBottom: "1%",
                  }}
                >
                  <View
                    style={{
                      width: "12%",
                      paddingTop: "1%",
                    }}
                  >
                    <Feather name='command' color={"#b57704"} size={22} />
                  </View>
                  <View
                    style={{
                      width: "50%",
                      paddingTop: "1%",
                    }}
                  >
                    <Text h4 color='#302b63'>
                     {route.params.concept == "event"
                    ? "Supprimer Evénement"
                    : "Supprimer Activité"}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>

          
              

            
            </View>
            
          </View>
        </View>



  
      </View>
      </View> 
    </>
  );
};

const styles = StyleSheet.create({
  container: {
  
    paddingTop: 20,
    backgroundColor: "white",
  },
  modalStyle: {
    backgroundColor: "#fff",
    height: "50%",
    width: "85%",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 20,
  },

  styleModal: {
    backgroundColor: "#fff",
    height: "50%",
    width: "90%",

    borderRadius: 70,
  },

  createButtonAddPole: {
    width: width * 0.3,
    marginTop: theme.sizes.width * 0.07,
    alignSelf: "center",
  },
  errorInput: {},
  contentContainer: {
    paddingHorizontal: 15,
    fontSize: 12,
  },
  contentFont: {
    fontSize: 20,
  },
  Container: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
   
    backgroundColor: "#FFF",
    
   
  },
  socialConnect: {
    backgroundColor: argonTheme.COLORS.WHITE,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#8898AA",
  },

  socialTerme: {
    paddingTop: "5%",
  },
  socialTitle: {
    paddingBottom: "5%",
  },
  socialButtons: {
    width: 120,
    height: 30,
    backgroundColor: "#fff",
    shadowColor: argonTheme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 0.1,
    elevation: 1,
  },
  socialTextButtons: {
    color: "#0f0c29",
    fontWeight: "800",
    fontSize: 14,
  },
  inputIcons: {
    marginRight: 12,
  },

  createButton: {
    width: width * 0.2,
    marginTop: 25,
  },
  createButtonDelete:{
    width: width * 0.4,
    marginTop: 25,

  },
  DateButton: {
    width: width * 0.3,
    marginTop: 15,
  },
  contain: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    
  },
  chip: {
    marginTop: 10,
    height:40
  },
});


const stylesPreset = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: theme.sizes.width * 0.056,
    marginBottom: theme.sizes.width * 0.056,
  },
  item: {
    padding: 1,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    marginTop: 2,
    marginHorizontal: 2,

    elevation: 5,
  },

  image: {
    width: theme.sizes.width * 0.15,
    height: 115,
  },
  text: {
    color: "#0f0c29",
    fontWeight: "bold",
  },
});


export default EditConcept;



export const fetchListHolidaySeason = formData => {

    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/holidays/listHolidaySeason", {
         method: "POST",
         headers: {
             "Content-Type": "application/json"
         },
          body: JSON.stringify({
             formData
         
          })
     }).then((response) => response.json())
     .then((json) => {
       
 
       return json;
     }).catch((err) => {
         console.log("dataHolidayActions.js, Request Error: ", err.message);
      
     });
   
 
 }
 
 
 
 
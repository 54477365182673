import * as ImagePicker from "expo-image-picker";
import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import moment from "moment";

import {
  StyleSheet,
  Dimensions,
  View,
  TouchableOpacity,
  Modal,
  
  Image,
  Platform,
  
  
} from "react-native";

import { FlatList } from "react-native-gesture-handler";


import {Toggle, Input,ButtonGroup, Text,TopNavigation,Button,Icon, Layout,TopNavigationAction,Divider,IndexPath, Select, SelectItem } from '@ui-kitten/components';

import { LocaleConfig } from "react-native-calendars";
import { ManageContext } from "../../provider/manageStateProvider";
import argonTheme from "../../constants/argonTheme";

import { addHolidayPredefined } from "../../actions/holiday/AddHolidayPredefinedAction";
import { addHolidayGallery } from "../../actions/holiday/AddHolidayGalleryAction";

import { DatePickerModal } from 'react-native-paper-dates'; 
import { theme } from "../../constants";
import { Feather, MaterialIcons, Ionicons } from "@expo/vector-icons";

const { width } = Dimensions.get("screen");

const AddHoliday = ({ route, navigation }) => {
 

  const handleHoliday = useContext(ManageContext);

  const [openDateStart, setOpenDateStart] = useState(false);

  const onDismissSingleDateStart = useCallback(() => {
    setOpenDateStart(false);
  }, [setOpenDateStart]);

  const onConfirmSingleDateStart = useCallback(
    (params) => {
      setOpenDateStart(false);
      setDateStart(params.date);
    },
    [setOpenDateStart, setDateStart]
  );
  const showDateStartpicker = () => {
    setOpenDateStart(true)
  };

  const showDateEndpicker = () => {
    setOpenDateEnd(true)
  };


  const [openDateEnd, setOpenDateEnd] = useState(false);

  const onDismissSingleDateEnd = useCallback(() => {
    setOpenDateEnd(false);
  }, [setOpenDateEnd]);

  const onConfirmSingleDateEnd = useCallback(
    (params) => {
      setOpenDateEnd(false);
      setDateEnd(params.date);
    },
    [setOpenDateEnd, setDateEnd]
  );

  
  const data = [
    {
       "id": 1, 
       "name": "Toussaint",
    },
    {
       "id": 2, 
       "name": "Noël",
    },
    {
       "id": 3, 
       "name": "Hiver",
    },
    {
       "id": 4, 
       "name": "Printemps",
    },
    {
      "id": 5, 
      "name": "Ascension",
   },
   {
    "id": 6, 
    "name": "Été",
 }

]

const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0));
  
  const [date, setDate] = useState(new Date());
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [dateCalendars, setDateCalendars] = useState(new Date());
  const [image, setImage] = useState("");
 

  const [price, setPrice] = useState(null);
  const [limitParticipation, setLimitParticipation] = useState(null);
  const [title, setTitle] = useState("");
  
  const [monday, setMonday] = useState("");
  const [tuesday, setTuesday] = useState("");
  const [wednesday, setWednesday] = useState("");
  const [thursday, setThursday] = useState("");
  const [friday, setFriday] = useState("");
  const [saturday, setSaturday] = useState("");
  const [sunday, setSunday] = useState("");
  const [description, setDescription] = useState("");
  const [modality, setModality] = useState("");

  const [location, setLocation] = useState("");

  const [activeChat, setActiveChat] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [mondayModal, setMondayModal] = useState(false);
  const [tuesdayModal, setTuesdayModal] = useState(false);
  const [wednesdayModal, setWednesdayModal] = useState(false);
  const [thursdayModal, setThursdayModal] = useState(false);
  const [fridayModal, setFridayModal] = useState(false);
  const [saturdayModal, setSaturdayModal] = useState(false);
  const [sundayModal, setSundayModal] = useState(false);



  const [visible, setVisible] = useState(false);

  const [holidayCreate, setHolidayCreate] = useState("Toussaint");


  const onDismissSnackBar = () => setVisible(false);
  const [mode, setMode] = useState('date');
  const [show, setShow] = useState(false);

  const [showDateEnd, setShowDateEnd] = useState(false);

  const [imagePreset, setImagePreset] = useState([
    {
      id: 130,
      name: "toussaint11",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/toussaint11.jpeg",
    },
    {
      id: 131,
      name: "holiday",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/holiday.jpeg",
    },
    {
      id: 132,
      name: "holiday2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/holiday2.jpeg",
    },
    {
      id: 133,
      name: "holiday3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/holiday3.jpeg",
    },
    {
      id: 134,
      name: "spring7",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/spring7.jpeg",
    },
    {
      id: 135,
      name: "spring8",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/spring8.jpeg",
    },
    {
      id: 136,
      name: "spring9",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/spring9.jpeg",
    },
    {
      id: 137,
      name: "summer5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/summer5.jpeg",
    },
    {
      id: 138,
      name: "summer6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/summer6.jpeg",
    },
    {
      id: 139,
      name: "summer7",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/summer7.jpeg",
    },
    {
      id: 140,
      name: "summer11",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/summer11.jpeg",
    },
    {
      id: 141,
      name: "summer12",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/summer12.jpeg",
    },

    {
      id: 142,
      name: "summerImg",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/summerImg.jpeg",
    },

    {
      id: 143,
      name: "été",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/été.jpeg",
    },

    {
      id: 144,
      name: "winterImg",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/winterImg.jpeg",
    },

    {
      id: 145,
      name: "paque",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/paque.jpeg",
    },




    {
      id: 12,
      name: "butterfree",
      url: "https://pokeapi.co/api/v2/pokemon/12/",
      image:
        "https://image.freepik.com/photos-gratuite/mains-femme-aide-puce-telephone-mobile_35534-132.jpg",
    },
    {
      id: 9,
      name: "blastoise",
      url: "https://pokeapi.co/api/v2/pokemon/9/",
      image:
        "https://image.freepik.com/photos-gratuite/vue-dessus-recadre-mains-cuisinier-aine-decoupage-carotte-cuisson-ragout-legumes_1098-20510.jpg",
    },
    {
      id: 10,
      name: "caterpie",
      url: "https://pokeapi.co/api/v2/pokemon/10/",
      image:
        "https://image.freepik.com/vecteurs-libre/carte-journee-internationale-du-yoga-femme-contre-ciel-coucher-soleil_1048-14205.jpg",
    },
    {
      id: 11,
      name: "metapod",
      url: "https://pokeapi.co/api/v2/pokemon/11/",
      image:
        "https://image.freepik.com/photos-gratuite/groupe-enfants-qui-etudient-ecole_1303-26838.jpg",
    },
    {
      id: 12,
      name: "butterfree",
      url: "https://pokeapi.co/api/v2/pokemon/12/",
      image:
        "https://image.freepik.com/photos-gratuite/mains-femme-aide-puce-telephone-mobile_35534-132.jpg",
    },
    {
      id: 13,
      name: "weedle",
      url: "https://pokeapi.co/api/v2/pokemon/13/",
      image:
        "https://image.freepik.com/photos-gratuite/asiatique-jolie-fille-lunettes-sac-dos-assise-livre-ciel-bleu_9083-3226.jpg",
    },
    {
      id: 14,
      name: "kakuna",
      url: "https://pokeapi.co/api/v2/pokemon/14/",
      image:
        "https://image.freepik.com/photos-gratuite/gros-plan-image-programmeur-travaillant-son-bureau-dans-bureau_1098-18707.jpg",
    },
    {
      id: 15,
      name: "beedrill",
      url: "https://pokeapi.co/api/v2/pokemon/15/",
      image:
        "https://image.freepik.com/photos-gratuite/deux-boxeurs-professionnels-smoky-noir_155003-14259.jpg",
    },

    {
      id: 17,
      name: "pidgeottop",
      url: "https://image.freepik.com/photos-gratuite/famille-noire-profiter-ete-ensemble-dans-cour_53876-22989.jpg",
      image:
        "https://image.freepik.com/photos-gratuite/famille-noire-profiter-ete-ensemble-dans-cour_53876-22989.jpg",
    },

    {
      id: 19,
      name: "pidgeottol",
      url: "https://i.f1g.fr/media/cms/616x347_cropupscale/2020/09/23/10ec77d466dfff13488c90503f887c870eb97a6b897bcf194cbd96addfbe3b31.jpeg",
      image:
        "https://i.f1g.fr/media/cms/616x347_cropupscale/2020/09/23/10ec77d466dfff13488c90503f887c870eb97a6b897bcf194cbd96addfbe3b31.jpeg",
    },

    {
      id: 20,
      name: "pidgeottof",
      url: "https://www.francetvinfo.fr/pictures/kefDba1bJOWDQatk6-eRgK9yR0I/752x423/2021/04/27/6087cd30886a2_5f1ed83c7c215_maxsportsfrtwo618855.jpg",
      image:
        "https://www.francetvinfo.fr/pictures/kefDba1bJOWDQatk6-eRgK9yR0I/752x423/2021/04/27/6087cd30886a2_5f1ed83c7c215_maxsportsfrtwo618855.jpg",
    },

    {
      id: 18,
      name: "raticate",
      url: "https://image.freepik.com/photos-gratuite/jeune-mere-passant-du-temps-sa-fille_23-2148988991.jpg",
      image:
        "https://image.freepik.com/photos-gratuite/reunion-brainstorming-travail-equipe-nouveau-projet-demarrage-milieu-travail_2034-1617.jpg",
    },

    {
      id: 21,
      name: "boxe",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/boxe.png",
    },

    {
      id: 22,
      name: "yoga",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/coaching.jpg",
    },

    {
      id: 23,
      name: "art",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/Yoga.png",
    },
    {
      id: 40,
      name: "art1",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/art.png",
    },

    {
      id: 41,
      name: "art2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/art.jpg",
    },
    {
      id: 42,
      name: "art3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/art2.jpg",
    },
    {
      id: 43,
      name: "art4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/art3.jpg",
    },

    {
      id: 45,
      name: "culinaire",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/culinaire.jpg",
    },

    {
      id: 46,
      name: "familli",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/familli.jpg",
    },

    {
      id: 47,
      name: "famille1",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/famille1.jpg",
    },

    {
      id: 48,
      name: "math",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math.jpg",
    },
    {
      id: 49,
      name: "math2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math2.jpg",
    },
    {
      id: 50,
      name: "math4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math4.jpg",
    },
    {
      id: 51,
      name: "math5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math5.jpg",
    },
    {
      id: 52,
      name: "math6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math6.jpg",
    },
    {
      id: 53,
      name: "math7",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math7.jpg",
    },
    {
      id: 54,
      name: "math8",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math8.jpg",
    },

    {
      id: 55,
      name: "math9",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math9.jpg",
    },

    {
      id: 56,
      name: "musique",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique.jpg",
    },

    {
      id: 57,
      name: "musique2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique2.jpg",
    },
    {
      id: 58,
      name: "musique3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique3.jpg",
    },
    {
      id: 59,
      name: "musique4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique4.jpg",
    },

    {
      id: 60,
      name: "musique5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique5.jpg",
    },

    {
      id: 61,
      name: "musique6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique6.jpg",
    },

    {
      id: 62,
      name: "musique7",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique7.jpg",
    },
    {
      id: 63,
      name: "musique8",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique8.jpg",
    },
    {
      id: 64,
      name: "parentalité",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/parentalité.jpg",
    },
    {
      id: 65,
      name: "scolaire",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/scolaire.jpg",
    },
    {
      id: 66,
      name: "scolaire2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/scolaire2.jpg",
    },
    {
      id: 67,
      name: "scolaire3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/scolaire3.jpg",
    },

    {
      id: 68,
      name: "traveau",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/traveau.jpg",
    },
    {
      id: 69,
      name: "lecture",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture.jpg",
    },
    {
      id: 70,
      name: "lecture2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture2.jpg",
    },
    {
      id: 71,
      name: "lecture3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture3.jpg",
    },

    {
      id: 72,
      name: "lecture4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture4.jpg",
    },

    {
      id: 73,
      name: "lecture5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture5.jpg",
    },
    {
      id: 74,
      name: "lecture6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture6.jpg",
    },

    {
      id: 75,
      name: "loisir",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir.jpg",
    },

    {
      id: 76,
      name: "loisir2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir2.jpg",
    },

    {
      id: 77,
      name: "loisir4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir4.jpg",
    },
    {
      id: 78,
      name: "loisir5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir5.jpg",
    },
    {
      id: 79,
      name: "loisir6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir6.jpg",
    },
    {
      id: 80,
      name: "loisir7",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir7.jpg",
    },
    {
      id: 81,
      name: "loisir8",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir8.jpg",
    },

    {
      id: 24,
      name: "bureutique",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/bureautique.png",
    },

    {
      id: 24,
      name: "français",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/français.png",
    },

    {
      id: 99,
      name: "mathKid",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/mathKid.png",
    },

    {
      id: 26,
      name: "potterie",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/potterie.png",
    },
    
    {
      id: 27,
      name: "yogaillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/yogaillu.jpg",
    },
    {
      id: 28,
      name: "sportIllu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/sportIllu.jpg",
    },
    {
      id: 29,
      name: "beachillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/beachillu.png",
    },
    {
      id: 30,
      name: "projectillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu.jpg",
    },
   
    {
      id: 32,
      name: "constructillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/constructillu.png",
    },
    {
      id: 33,
      name: "projecttillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu.jpg",
    },
    {
      id: 34,
      name: "projecttillu2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu2.png",
    },

    {
      id: 35,
      name: "pitchtillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/pitchillu.png",
    },
    {
      id: 36,
      name: "contentillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/contentillu.png",
    },
  ]);
  const [presetModal, setPresetModal] = useState(false);
  const [calendarModal, setCalendarModal] = useState(false);
  const [priceLimitModal, setPriceLimitModal] = useState(false);
  const [calendarDates, setCalendarDates] = useState({});
  
  const [dayActivity, setDayActivity] = useState([]);
  const [samedi, setSamedi] = useState(false);
  const [dimanche, setDimanche] = useState(false);
  const [lundi, setLundi] = useState(false);
  const [mardi, setMardi] = useState(false);
  const [mercredi, setMercredi] = useState(false);
  const [jeudi, setJeudi] = useState(false);
  const [vendredi, setVendredi] = useState(false);
  const [timeActivity, setTimeActivity] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [priceTag, setPriceTag] = useState("Mensuelle");

  LocaleConfig.locales["fr"] = {
    monthNames: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    monthNamesShort: [
      "Janv.",
      "Févr.",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juil.",
      "Août",
      "Sept.",
      "Oct.",
      "Nov.",
      "Déc.",
    ],
    dayNames: [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ],
    dayNamesShort: ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."],
    today: "Aujourd'hui",
  };



  const showDescriptionModal = () => {
    setDescriptionModal(true);
  };

  const hideDescriptionModal = () => {
    setDescriptionModal(false);
  };
  const hideMondayModal = () => {
    setMondayModal(false);
  };
  const hideTuesdayModal = () => {
    setTuesdayModal(false);
  };
  const hideWednesdayModal = () => {
    setWednesdayModal(false);
  };
  const hideThursdayModal = () => {
    setThursdayModal(false)
  };
  const hideFridayModal = () => {
    setFridayModal(false);
  };
  const hideSaturdayModal = () => {
    setSaturdayModal(false);
  };
  const hideSundayModal = () => {
    setSundayModal(false);
  };






  


  const publishHandler = async () => {


    if (
      title.length > 4 ||
      timeActivity.length > 4 ||
      title.length < 18 ||
      description.length > 4 ||
      location.length > 4 ||
      date.length > 4 ||
      dayActivity.length !== 0 || 
      ageRange.length > 4 
    ) {
      setButtonLoading(true);

      if (image !== "") {

        const formData = {
          title: holidayCreate, 
          image: image,
          description: description,
          dateEnd: dateEnd,
          dateStart:dateStart,
          price: price,
          location: location,
          activeChat: activeChat,
          calendarDates: calendarDates,
          limitParticipation: limitParticipation,
          idStructure:route.params.idCentre,
          ageRange:ageRange,
          priceTag: priceTag,
          modality: modality,
          monday: dayActivity.includes('Lundi') ? monday :"" ,
          tuesday:dayActivity.includes('Mardi') ? tuesday :""  ,
          wednesday: dayActivity.includes('Mercredi') ? wednesday :"" ,
          thursday:dayActivity.includes('Jeudi') ? thursday :"" ,
          friday:dayActivity.includes('Vendredi') ? friday :"" ,
          saturday:dayActivity.includes('Samedi') ? saturday :"" ,
          sunday:dayActivity.includes('Dimanche') ? sunday :"" ,     
          idUser: handleHoliday.idUser,
        };

        const response = await addHolidayPredefined(formData);

        if (response.message == "Good") {
          handleHoliday.updateHoliday == "false"
            ? handleHoliday.setUpdateHoliday("true")
            : handleHoliday.setUpdateHoliday("false");

          setButtonLoading(false);

          navigation.navigate("Home",{idCentre:route.params.idCentre})
        }
      }

      if (fileName.length !== 0) {
        var castedDate = moment(date.getTime())
          .add(dateCalendars, "s")
          .toDate();
        const dates = castedDate.toString();

        const formData = new FormData();

        var castedDateEnd = moment(dateEnd).toDate();

        const dateEnd = castedDateEnd.toString();

        var castedDateStart = moment(dateStart).toDate();

        const dateStart = castedDateStart.toString();

        if (price !== null) {
          formData.append("price", price);
        } else {
          formData.append("price", 0);
        }

        if (limitParticipation !== null) {
          formData.append("limitParticipation", limitParticipation);
        } else {
          formData.append("limitParticipation", 0);
        }


        formData.append("title", holidayCreate);
        formData.append("description", description);
        formData.append("location", location);
        formData.append("activeChat", activeChat);
        formData.append("calendarDates", dates);
        formData.append("dateStart", dateStart);
        formData.append("dateEnd", dateEnd);
        formData.append("ageRange", ageRange);
        formData.append("modality", modality);
        formData.append("priceTag", priceTag);
        formData.append("idStructure", route.params.idCentre);
        formData.append("monday", dayActivity.includes('monday') ? monday :"");
        formData.append("tuesday", dayActivity.includes('tuesday') ? tuesday :"");
        formData.append("wednesday", dayActivity.includes('wednesday') ? wednesday :"");
        formData.append("thursday", dayActivity.includes('thursday') ? thursday :"");
        formData.append("friday", dayActivity.includes('friday') ? friday :"");
        formData.append("saturday", dayActivity.includes('saturday') ? saturday :"");
        formData.append("sunday", dayActivity.includes('sunday') ? sunday :"");
        formData.append("idUser", handleHoliday.idUser);
        formData.append("previewGallery", {
          name: "image.png",
          type: "image/png",
          uri:
            Platform.OS === "android"
              ? fileName
              : fileName.replace("file://", ""),
        });

        const response = await addHolidayGallery(formData);

        if (response.message == "Good") {
          handleHoliday.updateHoliday == "false"
            ? handleHoliday.setUpdateHoliday("true")
            : handleHoliday.setUpdateHoliday("false");

          setButtonLoading(false);

          navigation.navigate("Home",{idCentre:route.params.idCentre})
        } else {
          setVisible(true);
          setButtonLoading(false);
        }
      }
    } else {
    }
  };

  const validImage = () => {
    setPresetModal(false);
  };

  const cancelImage = () => {
    setPresetModal(false);

    setImage("");
  };

  const cancelCalendar = () => {
    setCalendarModal(false);

    setCalendarDates({});
  };

  const cancelPriceLimit = () => {
    setPriceLimitModal(false);

    setPrice(null);
    setLimitParticipation(null);
  };
  const onClickItem = (item, index) => {
    setImage(item.image);
    setFileName("");

    const newArrData = imagePreset.map((e, index) => {
      if (item.name == e.name) {
        return {
          ...e,
          selected: true,
        };
      }
      return {
        ...e,
        selected: false,
      };
    });

    setImagePreset(newArrData);
  };

  const renderItemPreset = ({ item, index }) => {
    return (
      <TouchableOpacity
        onPress={() => onClickItem(item, index)}
        style={{
          backgroundColor: item.selected ? "#0f0c29" : "white",
          padding: 5,
          borderRadius: 10,
        }}
      >
        <View style={stylesPreset.item}>
          <Image
            style={stylesPreset.image}
            resizeMode="contain"
            source={{ uri: item.image }}
          />
        </View>
      </TouchableOpacity>
    );
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      allowsMultipleSelection: false,
      aspect: [3, 2],
      quality: 0.2,
    });

    if (!result.cancelled) {
      setFileName(result.uri);
      setImage("");
    }
  };
const changeIndex =(index)=>{
  
  setHolidayCreate(data[index.row]?.name)
    
}
  return (
    <>
      <View style={styles.Container}>

        <Layout style={styles.container} level='1'>
        <TopNavigation
          alignment='center'
          title='Ajouter des Vacances'
          
          accessoryLeft={
            
            <TouchableOpacity    onPress={() => navigation.navigate("Home",{idCentre:route.params.idCentre})} > 
            <Ionicons name="arrow-back"
          color={"#4e32a8"}
          size={35} />
  
          </TouchableOpacity>
        }
          accessoryRight={ 
            
        
           
          <Button
          onPress={publishHandler}
          loading={buttonLoading}
          disabled={ 
            !holidayCreate ||
            holidayCreate =="Choisir" ||
            !description ||    
       
            !ageRange ||
            (!image && !fileName) 
            }
          color={
            !holidayCreate ||
            !description ||
            holidayCreate =="Choisir" ||
            !ageRange ||
            (!image && !fileName) 
            
              ? "grey"
              : "#0f0c29"
          }
          style={styles.createButton}
        >
          <Text
            bold
           
            size={14}
            color={
              !holidayCreate ||
              !description ||
              holidayCreate =="Choisir" ||
               
              !ageRange ||
              (!image && !fileName) 
              
                ? "#bdbdbd"
                : argonTheme.COLORS.WHITE
            }
          >
            Publier
          </Text>
        </Button>}
        />
      </Layout>
  


        <View style={{ marginTop: "1%" }}>
          <Divider />
        </View>

        
          <View style={{ justifyContent: "center" }}>
      

            <View style={{flexDirection:"row",alignSelf:"center",marginVertical:"1%",marginHorizontal:'1%'}}>

            <View style={{marginVertical:"1%",marginHorizontal:'1%'}}>
              
            <Text
            
              style={{
                color: '#000',
              
              }}>Choisir Vacances :</Text>
         
          </View>
                      
      
          
            <View>
                 
                
                <Select
                selectedIndex={selectedIndex}
                status="basic"
                size="large"
                value={data[selectedIndex.row]?.name}
                onSelect={(index) => {
                  setSelectedIndex(index);
                  
                  
                  changeIndex(index)
                  
                }}
              >
                {data.map((item, i) => (
                  <SelectItem title={item.name} />
                ))}
              </Select>
       
          
          </View>
        

            </View>
          
       
       <View>
      <View style={{flexDirection :"row",paddingTop:'1%', alignSelf:'center'}}>
        
    <View style={{width:"40%"} }>
   
                   <Button  onPress={showDateStartpicker} color={theme.colors.lightBlue} style={styles.DateButton}>
                   <View style={{flexDirection :"row"}}>
                  <View style={{marginLeft:'10%'} }>
                 <Text style={{color:'white'}} category='s5'>
                          Debut :
                     </Text>
                  </View>
              
                  <View>
                 
                  <Text style={{color:'white'}} category='h5'>
                  {moment(dateStart)
                    .format('DD')
                    .toLocaleUpperCase()}{' '}
                     {moment(dateStart)
                          .format('MMM')
                          .toLocaleUpperCase()}
    
              
                  </Text>
              
              
                  </View>
                 
              
                </View>
              
                </Button>   
 
      <DatePickerModal
      locale="fr"
      mode="single"
      visible={openDateStart}
      onDismiss={onDismissSingleDateStart}
      value={dateStart}
      onConfirm={onConfirmSingleDateStart}
    />
  

    </View>
    <View style={{width:"40%"} }>
   


                   <Button  onPress={showDateEndpicker} color={theme.colors.lightBlue} style={styles.DateButton}>
                   <View style={{flexDirection :"row"}}>
                  <View style={{marginLeft:'10%'} }>
                 <Text style={{color:'white'}} category='s5'>
                          Fin :
                     </Text>
                  </View>
              
                  <View>
                 
                  <Text style={{color:'white'}} category='h5'>
                  {moment(dateEnd)
                    .format('DD')
                    .toLocaleUpperCase()}{' '}
                     {moment(dateEnd)
                          .format('MMM')
                          .toLocaleUpperCase()}

              
                  </Text>
              
              
                  </View>
                 
              
                </View>
              
                </Button> 

  
                   <DatePickerModal
                   locale="fr"
                   mode="single"
                   visible={openDateEnd}
                   onDismiss={onDismissSingleDateEnd}
                   value={dateEnd}
                   onConfirm={onConfirmSingleDateEnd}
                 />
    </View>
    
    </View>
    
    
    </View> 


    <View style={{ marginTop: "1%", marginHorizontal: "30%" }}>
          <Text>
          {"Tranche d'age Ex: 9-12 ans"}
          </Text>
        </View>
     
    <View style={{ marginHorizontal: "30%", marginVertical: "1%" }}>

  <Input
    placeholder="Tranche d'age Ex: 9-12 ans"
  

    onChangeText={(text) => setAgeRange(text)}
    value={ageRange}
 
  ></Input>
  
  </View> 
          
          </View>
       
          <View style={{ marginTop: "1%", marginHorizontal: "30%" }}>
          <Text>
          Description Globale
          </Text>
        </View>
     
                <View style={{ marginHorizontal: "30%", marginVertical: "1%" }}>
                <Input
                multiline={true}
                textStyle={{ minHeight: 64 }}
                placeholder='Description Globale'
                onChangeText={(text) => setDescription(text)}
                value={description}
               
              />
                </View>
                

                <View style={{ marginTop: "1%", marginHorizontal: "30%" }}>
                  <Text h3 color={"#2d4150"}>
                  Modalités
                  </Text>
                </View>

                <View style={{ marginHorizontal: "30%", marginTop: "1%", marginBottom: "1%"  }}>
          
                <Input
                multiline={true}
                textStyle={{ minHeight: 64 }}
                placeholder='Modalités'
                onChangeText={(text) => setModality(text)}
                value={modality}
               
              />
              </View>

            
 
        <Modal animationType="slide" visible={presetModal}>
        <View style={styles.centeredView}>
          
          
          <Layout  level='1'>
          <TopNavigation
            alignment='center'
            title='Choisir une Image'
            
            accessoryLeft={
              
              <TouchableOpacity   onPress={cancelImage} > 
              <Ionicons name="arrow-back"
            color={"#4e32a8"}
            size={35} />
    
            </TouchableOpacity>
          }
            accessoryRight={ <Button
            
              onPress={() => {
                setPresetModal(false);
              }}
            >
              <Text
                bold
                
                size={14}
               
              >
                Valider
              </Text>
            </Button>}
          />
        </Layout>
    
         <Divider></Divider>
         <View style={{flex:1}}>
         <View style={{height: 1000}}>
          <FlatList
          contentContainerStyle={{flexGrow: 1}}
            numColumns={6}
            extraData={imagePreset}
            data={imagePreset}
            scrollEnabled={true}
            renderItem={renderItemPreset}        
            keyExtractor={(item) => item.id}
          />
          </View>
          </View>
        </View>
      </Modal>

        <View>
            <View>
              <View style={styles.contentContainer}>
               
                <View style={{ marginTop: "1%" }}>
                  <Divider />
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    paddingTop: "1%",
                    paddingBottom: "1%",
                  }}
                >
                  <View
                    style={{
                      width: "12%",

                      paddingTop: "1%",
                    }}
                  >
                    <View>
                      <Ionicons name="ios-image" color={"#036963"} size={22} />
                    </View>
                  </View>

                  <View
                    style={{
                      width: "55%",

                      paddingTop: "1%",
                      paddingRight: 15,
                    }}
                  >
                    <TouchableOpacity onPress={() => setPresetModal(true)}>
                      <View>
                        <Text h4 color="#302b63" size={12}>
                          Image Prédéfinie
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View>
                    {image !== "" ? (
                      <View
                        style={{
                          width: "10%",
                          marginHorizontal: "7%",
                        }}
                      >
                        <Image
                          style={{
                            width: 70,
                            height: 60,
                            resizeMode: "stretch",
                          }}
                          source={image !== "" ? { uri: image } : null}
                        />
                      </View>
                    ) : (
                      <View
                        style={{
                          marginTop: "1%",
                          justifyContent: "center",
                          marginRight: "17%",
                        }}
                      >
                        <Text h6 color="#969595" size={12}>
                          ( Obligatoire )
                        </Text>
                      </View>
                    )}
                  </View>
                </View>

                <Divider />


                <View style={{ marginTop: "1%" }}>
                  <Divider />
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    paddingTop: "1%",
                    paddingBottom: "1%",
                  }}
                >
                  <View
                    style={{
                      width: "12%",
                      paddingTop: "1%",
                    }}
                  >
                    <Feather
                      name="message-square"
                      color={"#b57704"}
                      size={22}
                    />
                  </View>
                  <View
                    style={{
                      width: "50%",
                      paddingTop: "1%",
                    }}
                  >
                    <Text h4 color="#302b63">
                      Discussion
                    </Text>
                  </View>

                  <Toggle
                  style={styles.toggle}
                  checked={activeChat}
                  onChange={(value) => setActiveChat(value)}>
                  Active
                </Toggle>
                </View>
              </View>
            </View>
         
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,

    backgroundColor: "white",
  },
  scrollView: {
    paddingTop: "1%",
  },

  contentContainer: {
    padding: "1%",
    fontSize: 15,
    marginBottom: 100,
  },

  Container: {
  
    backgroundColor: "#FFF",
    borderRadius: 4,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 0.1,
    elevation: 1,
    overflow: "hidden",
  },

  createButton: {
    width: width * 0.2,
  },
  DateButton: {
    width: width * 0.1,
    marginTop: theme.sizes.width * 0.02,
  },
  DateButtonPreview: {
    width: width * 0.1,
    height: theme.sizes.width * 0.09,
  },
  centeredView: {
    marginBottom: "30%",
  },
  contain: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  chip: {
    marginTop: 10,
    height: 40,
  },
  createButtonAddPole: {
    width: width * 0.3,
    marginTop: theme.sizes.width * 0.07,
    alignSelf: "center",
  },
  picker: {
    margin: "7%",

    color: theme.colors.lightBlue,
    borderRadius: 10,
    alignContent:"center",

   
    borderColor: 'black',
    borderWidth: 1,
  },

  pickerItem:{
    width: 200,
    height: 88,
    backgroundColor: '#FFF0E0',
    borderColor: 'black',
    borderWidth: 1,

  }
});

const stylesPreset = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: theme.sizes.width * 0.056,
    marginBottom: theme.sizes.width * 0.056,
  },

  image: {
    width: theme.sizes.width * 0.15,
    height: 115,
  },
  text: {
    color: "#0f0c29",
    fontWeight: "bold",
  },
});




export default AddHoliday;

export const fetchListEvent = structureData => {


    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/events/listEvent", {
         method: "POST",
         headers: {
             "Content-Type": "application/json",
             "Access-Control-Allow-Origin": "*",
         },
          body: JSON.stringify(structureData)
          
     }).then((response) => response.json())
     .then((json) => {
       
       return json;
     }).catch((err) => {
         console.log("eventDataActions.js, eventData Request Error: ", err.message);
      
     });
   
 
 }
 
 
 

import React, { useEffect,useRef } from 'react';
import {  View, StyleSheet } from 'react-native';

import LottieView from 'lottie-react-native';



const AddAppointmentPermanence = () => {
  const animation = useRef(null);

  useEffect(() => {

    animation.current.play();
    
  }, [])


  return (
    
    <View style={styles.container}>

      <LottieView ref={animation} source={require('./animationComponent/addAppointmentPermanence.json')} autoPlay
      loop={false}
      speed={1}
      resizeMode='contain'
      />
      
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop:0,
    backgroundColor: 'white',
    width:"100%"
    
    
 
  },

});

export default AddAppointmentPermanence;
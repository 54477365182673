
export const listParticipant = (formData) => {


    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/participants/listParticipant", {
         method: "POST",
         headers: {
             "Content-Type": "application/json"
         },
          body: JSON.stringify({
        formData
      
       })
     }).then((response) => response.json())
     .then((json) => {
       
       return json;
     }).catch((err) => {
         console.log("allParticipationsSuccess.js, allParticipations Request Error: ", err.message);
      
     });
   
 
 }
 
 
 
 
 










export const getOldAppointement = (idStructure,idPermanence,manage,idUser,localISOTime) => {



    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/permanences/getOldAppointement", {
         method: "POST",
         headers: {
             "Content-Type": "application/json"
         },
          body: JSON.stringify({
             idStructure:idStructure,
             id:idPermanence,
             manage:manage,
             idUser:idUser,
             localISOTime:localISOTime
         
          })
     }).then((response) => response.json())
     .then((json) => {
       
 
       return json;
     }).catch((err) => {
         console.log("getOldAppointementActions.js, Request Error: ", err.message);
      
     });
   
 
 }
 
 
 
 
import { getData } from "../../storage/manageStorage";
export const reservePermanence = async (formData) => {

const token = await getData("authToken")
    
 return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/permanences/reservePermanence", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          'Authorization':'Bearer'+token,
      },
       body: JSON.stringify({
        formData
      
       })
  }).then((response) => response.json())
  .then((json) => {

    return json;
  }).catch((err) => {
    console.log("reservePermanence.js, reservePermanence Request Error: ", err.message);
    
});



}









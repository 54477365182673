import * as React from 'react';
import { Platform ,Linking} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ManageProvider } from './provider/manageStateProvider';
import * as eva from '@eva-design/eva';
import { ApplicationProvider} from '@ui-kitten/components';

import HomeScreen from './screens/Home'
import WelcomeScreen from './screens/Welcome'
import ActivityDetailScreen from './screens/ActivityDetail'
import LoginScreen from './screens/Login'
import SignupScreen from './screens/SignUp'
import ConfirmAccountScreen from './screens/ConfirmAccount'
import KeyRegisterScreen from './screens/KeyRegister'
import PermanenceDetailScreen from './screens/PermanenceDetail'
import ChatScreen from './screens/ChatScreen'
import HolidayBridge from './screens/holiday/HolidayBridge'
import HolidayDetail from './screens/holiday/HolidayDetail'
import HolidayActivityDetail from './screens/holiday/HolidayActivityDetail'
import ProfileScreen from './screens/Profile'
import AddPole from './screens/AddPole'
import AddActivity from './screens/AddActivity'
import AddEvent from './screens/AddEvent'
import EditConcept from './screens/EditConcept'
import AddPermanence from './screens/AddPermanence';
import EditPermanence from './screens/EditPermanence';
import AddHoliday from './screens/holiday/AddHoliday';
import EditHoliday from './screens/holiday/EditHoliday';

const Stack = createNativeStackNavigator();

function MyStack() {
  return (

    
    <Stack.Navigator  screenOptions={{ cardStyle: { flex: 1 } }}>

  
      <Stack.Screen  options={{
        headerShown: false,
      }} name="Welcome" component={WelcomeScreen} />

      <Stack.Screen  options={{
        headerShown: false,
      }} name="Profile" component={ProfileScreen} />


      <Stack.Screen  options={{
        headerShown: false,
      }} name="Home" component={HomeScreen} />
      <Stack.Screen  options={{
        headerShown: false,
      }} name="ActivityDetail" component={ActivityDetailScreen} />
      <Stack.Screen  options={{
        headerShown: false,
      }} name="Login" component={LoginScreen} />
      <Stack.Screen  options={{
        headerShown: false,
      }} name="Signup" component={SignupScreen} />
      
      <Stack.Screen  options={{
        headerShown: false,
      }} name="ConfirmAccount" component={ConfirmAccountScreen} />

      <Stack.Screen  options={{
        headerShown: false,
      }} name="KeyRegister" component={KeyRegisterScreen} />

  
      <Stack.Screen  options={{
        headerShown: false,
      }} name="PermanenceDetail" component={PermanenceDetailScreen} />
      
      <Stack.Screen  options={{
        headerShown: false,
      }} name="Chat" component={ChatScreen} />
  
      <Stack.Screen  options={{
        headerShown: false,
      }} name="HolidayBridge" component={HolidayBridge} />


      <Stack.Screen  options={{
        headerShown: false,
      }} name="HolidayDetail" component={HolidayDetail} />
      
      <Stack.Screen  options={{
        headerShown: false,
      }} name="HolidayActivityDetail" component={HolidayActivityDetail} />

      <Stack.Screen  options={{
        headerShown: false,
      }} name="AddPole" component={AddPole} />

      <Stack.Screen  options={{
        headerShown: false,
      }} name="AddActivity" component={AddActivity} />
      <Stack.Screen  options={{
        headerShown: false,
      }} name="AddEvent" component={AddEvent} />
      <Stack.Screen  options={{
        headerShown: false,
      }} name="EditConcept" component={EditConcept} />
      <Stack.Screen  options={{
        headerShown: false,
      }} name="AddPermanence" component={AddPermanence} />
      <Stack.Screen  options={{
        headerShown: false,
      }} name="EditPermanence" component={EditPermanence} />

      <Stack.Screen  options={{
        headerShown: false,
      }} name="AddHoliday" component={AddHoliday} />

      <Stack.Screen  options={{
        headerShown: false,
      }} name="EditHoliday" component={EditHoliday} />

    </Stack.Navigator>
  );
}

export default function App() {

  
  if (Platform.OS === 'ios') {
    const supported = Linking.canOpenURL("https://apps.apple.com/fr/app/mon-centre-social/id1610144427?platform=iphone");
if (supported) {
 Linking.openURL("https://apps.apple.com/fr/app/mon-centre-social/id1610144427?platform=iphone");
}

  } else if (Platform.OS === 'android') {
    const supported = Linking.canOpenURL("https://play.google.com/store/apps/details?id=com.mcsexpo.MCS");
    if (supported) {
     Linking.openURL("https://play.google.com/store/apps/details?id=com.mcsexpo.MCS");
    }
  } else if (Platform.OS === 'web') {

    return (
      <ManageProvider>
    
      <ApplicationProvider {...eva} theme={eva.light}>
      <NavigationContainer>
        <MyStack />
      </NavigationContainer>
      </ApplicationProvider>
      
      </ManageProvider>
    );
    // it's on web!
  } else {
    // you probably won't end up here unless you support another platform!
  }

}

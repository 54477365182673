import * as ImagePicker from "expo-image-picker";
import React, {
  useState,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from "react";

import {
  StyleSheet,
  View,
  TouchableOpacity,
  Modal,
  ScrollView,
  Image,
  Platform,
  
} from "react-native";

import { FlatList } from "react-native-gesture-handler";

import moment from 'moment';


import { ManageContext } from "../provider/manageStateProvider";

import argonTheme from "../constants/argonTheme";


import { addPermanenceGallery } from "../actions/permanence/AddPermanenceGalleryAction";
import { addPermanencePredefined } from "../actions/permanence/AddPermanencePredefinedAction";

import { theme } from "../constants";
import { Feather, MaterialIcons, Ionicons } from "@expo/vector-icons";


import { DatePickerModal } from 'react-native-paper-dates';

import {Toggle, Spinner,Input,ButtonGroup, Text,TopNavigation,Button,Icon, Layout,TopNavigationAction,Divider,IndexPath, Select, SelectItem } from '@ui-kitten/components';

const AddPermanence = ({ route, navigation }) => {


  const handlePermanence = useContext(ManageContext);
  const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0));

  const [date, setDate] = useState(new Date(moment().add(672, 'hours')));
  const [image, setImage] = useState("");
  const [price, setPrice] = useState(null);
  const [limitParticipation, setLimitParticipation] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [activeChat, setActiveChat] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [fileName, setFileName] = useState("");

  const [visible, setVisible] = useState(false);
  const [openConfirmDateModal, setOpenConfirmDateModal] = useState(false);
  
  const [hourSelected, setHourSelected] = useState([]);
  
  const [openAppointementUser, setOpenAppointementUser] = useState(true);
  

  
  const [open, setOpen] = React.useState(false);

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );

  const ArrayDay = [
    {
      day: "Lundi",
    },
    {
      day: "Mardi",
    },
    {
      day: "Mercredi",
    },
    {
      day: "Jeudi",
    },
    {
      day: "Vendredi",
    },
    {
      day: "Samedi",
    },
    {
      day: "Dimanche",
    },
  ];
  const data = [
    {
       "id": 1, 
       "name": "Mensuelle",
    },
    {
       "id": 2, 
       "name": "Ponctuelle",
    },
    {
       "id": 3, 
       "name": "Trimestrielle",
    },
    {
       "id": 4, 
       "name": "Annuelle",
    }
]

  const allHours = [
    "9h00",
    "9h30",
    "10h00",
    "10h30",
    "11h00",
    "11h30",
    "13h00",
    "13h30",
    "14h00",
    "14h30",
    "15h00",
    "15h30",
    "16h00",
    "16h30",
    "17h00",
    "17h30",
    "18h00",
    "18h30",
    "19h00",
  ];

  const [imagePreset, setImagePreset] = useState([
   
   
    {
      id: 93,
      name: "perma6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma6.jpeg",
    },
  
    {
      id: 97,
      name: "perma1",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma1.jpeg",
    },
    {
      id: 98,
      name: "perma2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma2.jpeg",
    },

    {
      id: 96,
      name: "perma3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma3.jpeg",
    },
  
  
    {
      id: 90,
      name: "perma4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma4.jpeg",
    },
  
    {
      id: 91,
      name: "perma5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma5.jpeg",
    },
  
 

    {
      id: 28,
      name: "sportIllu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/sportIllu.jpg",
    },
    {
      id: 29,
      name: "beachillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/beachillu.png",
    },
    {
      id: 30,
      name: "projectillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu.jpg",
    },
   
    {
      id: 32,
      name: "constructillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/constructillu.png",
    },
    {
      id: 33,
      name: "projecttillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu.jpg",
    },
    {
      id: 34,
      name: "projecttillu2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu2.png",
    },

    {
      id: 35,
      name: "pitchtillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/pitchillu.png",
    },
    {
      id: 36,
      name: "contentillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/contentillu.png",
    },

  ]);



  const [presetModal, setPresetModal] = useState(false);
  const [calendarModal, setCalendarModal] = useState(false);
  const [priceLimitModal, setPriceLimitModal] = useState(false);
  const bottomSheet = useRef(null);

  const [timeActivity, setTimeActivity] = useState("");
  const [priceTag, setPriceTag] = useState("Mensuelle");

  const handleDateSelected = (day, hour) => {
    const oldData = hourSelected;

    let Data = [];
    const selected = day + hour;

    Data.includes(selected);

    if (oldData.includes(selected) == false) {
      Data.push(selected);

      setHourSelected(Data.concat(oldData));
    } else {
      var filteredArray = oldData.filter(function (e) {
        return e !== selected;
      });

      setHourSelected(filteredArray);
    }
  };

  const renderItemDay = ({ item, index }) => {
    return (
      <View style={styles.card}>
        <Text style={{ color: "#1b3a59", margin: "4%" }} h5>
          {item.day}
        </Text>
        <Divider />
        <View style={{ flex: 1 }}>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {allHours.map((itemHour, i) => (
              <TouchableOpacity
                key={i}
                onPress={() => handleDateSelected(item.day, itemHour)}
              >
                <View
                  style={
                    hourSelected.includes(item.day + itemHour) == true
                      ? styles.hourSelected
                      : styles.hourUnselected
                  }
                >
                  <Text
                    style={[
                      styles.text,
                      hourSelected.includes(item.day + itemHour) == true
                        ? styles.unselected
                        : styles.selected,
                    ]}
                    h6
                  >
                    {itemHour}
                  </Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </View>
    );
  };



  const showDatepicker = () => {
    setOpen(true)
  };

 




  const openConfirmDate =() => {
    setCalendarModal(false)
     
     setOpenConfirmDateModal(true)

  }


  const confirmDate =() => {
     
    setOpenConfirmDateModal(false)
    setCalendarModal(false)

 }


  const publishHandler = async () => {
    if (
      title.length > 4 ||
      timeActivity.length > 4 ||
      title.length < 18 ||
      description.length > 4 ||
      location.length > 4 ||
      date.length > 4 ||
      hourSelected.length !== 0
    ) {
      setButtonLoading(true);

      if (image !== "") {
        const formData = {
          title: title,
          image: image,
          description: description,
          price: price,
          location: location,
          activeChat: activeChat,
          limitParticipation: limitParticipation,
          priceTag: priceTag,
          initialHour:hourSelected,
          openAppointementUser:openAppointementUser,
          idUser: handlePermanence.idUser,
          idStructure:route.params.idCentre,
          dateEnd:date
        
        };


        const response = await addPermanencePredefined(formData);

        if (response.message == "Good") {
          handlePermanence.updatePermanence == "false"
            ? handlePermanence.setUpdatePermanence("true")
            : handlePermanence.setUpdatePermanence("false");

          setButtonLoading(false);

          navigation.navigate("Home",{idCentre:route.params.idCentre})
        } 
      }

      if (fileName.length !== 0) {
        const formData = new FormData();

        var castedDate = moment(date).toDate();

        const dates = castedDate.toString();
      
        if (price !== null) {
          formData.append("price", price);
        } else {
          formData.append("price", 0);
        }

        if (limitParticipation !== null) {
          formData.append("limitParticipation", limitParticipation);
        } else {
          formData.append("limitParticipation", 0);
        }

        formData.append("title", title);
        formData.append("description", description);
        formData.append("location", location);
        formData.append("activeChat", activeChat);
        formData.append("openAppointementUser", openAppointementUser);
        formData.append("initialHour", JSON.stringify(hourSelected));
        formData.append("priceTag", priceTag);
        formData.append("dateEnd", dates);
        formData.append("idStructure", route.params.idCentre);
        formData.append("idUser", handlePermanence.idUser);
        formData.append("previewGallery", {
          name: "image.png",
          type: "image/png",
          uri:
            Platform.OS === "android"
              ? fileName
              : fileName.replace("file://", ""),
        });

        const response = await addPermanenceGallery(formData);

       
        if (response.message == "Good") {
          handlePermanence.updatePermanence == "false"
            ? handlePermanence.setUpdatePermanence("true")
            : handlePermanence.setUpdatePermanence("false");

          setButtonLoading(false);

          navigation.navigate("Home",{idCentre:route.params.idCentre})
        } else {
          setVisible(true);
          setButtonLoading(false);
        }
      }
    } else {
    }
  };

  const validImage = () => {
    setPresetModal(false);
  };

  const cancelImage = () => {
    setPresetModal(false);

    setImage("");
  };

  const cancelCalendar = () => {
    setCalendarModal(false);

   
  };


  const onClickItem = (item, index) => {
    setImage(item.image);
    setFileName("");

    const newArrData = imagePreset.map((e, index) => {
      if (item.name == e.name) {
        return {
          ...e,
          selected: true,
        };
      }
      return {
        ...e,
        selected: false,
      };
    });

    setImagePreset(newArrData);
  };

  const renderItemPreset = ({ item, index }) => {
    return (
      <TouchableOpacity
        onPress={() => onClickItem(item, index)}
        style={{
          backgroundColor: item.selected ? "#0f0c29" : "white",
          padding: 5,
          borderRadius: 10,
        }}
      >
        <View style={stylesPreset.item}>
          <Image
            style={stylesPreset.image}
            resizeMode="contain"
            source={{ uri: item.image }}
          />
        </View>
      </TouchableOpacity>
    );
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      allowsMultipleSelection: false,
      aspect: [3, 2],
      quality: 0.2,
    });

    if (!result.cancelled) {
      setFileName(result.uri);
      setImage("");
    }
  };

  const LoadingIndicator = (props) => (


    buttonLoading == true ?
    <View style={[props.style, styles.indicator]}>
      <Spinner size='small'/>
    </View> :null
  );
  

  return (
    <>
      <View style={styles.Container}>
     

        <Layout style={styles.container} level='1'>
        <TopNavigation
          alignment='center'
          title='Ajouter une Permanence'
          
          accessoryLeft={
            
            <TouchableOpacity    onPress={() => navigation.navigate("Home",{idCentre:route.params.idCentre})} > 
            <Ionicons name="arrow-back"
          color={"#4e32a8"}
          size={35} />
  
          </TouchableOpacity>
        }
          accessoryRight={ 
            
  
             
            
              <Button
            onPress={publishHandler}
            
            appearance='outline' 
            accessoryLeft={LoadingIndicator}
            color={
              !title ||
              !description ||
              !location ||
              !date ||
              (!image && !fileName) ||
              hourSelected.length == 0
                ? "grey"
                : "#0f0c29"
            }
           
          >
            <Text
              bold
              disable={
                !title || !description || !location || hourSelected.length == 0
              }
              size={14}
              color={
                !title ||
                !description ||
                !location ||
                !date ||
                (!image && !fileName) ||
                hourSelected.length == 0
                  ? "#bdbdbd"
                  : argonTheme.COLORS.WHITE
              }
            >
              Publier
            </Text>
          </Button>}
        />
      </Layout>
  

        <View style={{ marginTop: "1%" }}>
          <Divider />
        </View>

 
    
        <View style={{alignSelf:"center",width:"50%",}}>
            
  
        <Input
                    placeholder="Titre"      
                   
                    onChangeText={text => setTitle(text.slice(0, 13))}
                    value={title}
                   
                  ></Input>
                
                
      
                  <Input
              multiline={true}
              textStyle={{ minHeight: 64 }}
              placeholder='Description'
              onChangeText={(text) => setDescription(text)}
              value={description}
             
            />
      
                  <Input
                    placeholder="Adresse"
                  
               
                    onChangeText={(text) => setLocation(text)}
                    value={location}
                 
                  ></Input>
                  
      
                  <Layout style={styles.containerInput} level='1'>
      
                  <Input
                    style={styles.input}
                    value={price}
                    placeholder='Prix'
                    keyboardType='numeric'
                    onChangeText={nextValue => setPrice(nextValue.replace(/[^0-9]/, ''))}
                  />
        
      
      
      
                 
       
                <Select
                selectedIndex={selectedIndex}
                status="basic"
                size="large"
                value={data[selectedIndex.row]?.name}
                onSelect={(index) => {
                  setSelectedIndex(index);
                  setPriceTag(data[selectedIndex.row]?.name)
                }}
              >
                {data.map((item, i) => (
                  <SelectItem title={item.name} />
                ))}
              </Select>
              
                      <Input
                      style={styles.input}
                      value={limitParticipation}
                      placeholder='Maximun Participant'
                      onChangeText={nextValue => setLimitParticipation(nextValue.replace(/[^0-9]/, ''))}
                    />
                    </Layout>
          
                 
          
       

        
        </View>
     

    
        
        <Modal animationType="slide" visible={calendarModal}>
        
        <View style={{ padding: "2%" }}>
        
      </View>

          
          <View style={styles.containerPermanence}>
          
            

            <View
            style={{
              flexDirection: "row",
             
            
            }}
          >
            <View style={{ width: "20%" }}>
              <Ionicons
                onPress={cancelCalendar} 
                style={{ marginLeft: 10, marginTop: -5 }}
                name="arrow-back-circle-sharp"
                color={theme.colors.lightBlue}
                size={30}
              />
            </View>

            <View style={{ width: "60%" }}>
              <Text   style={{ fontSize:25,color:theme.colors.lightBlue }}>
                
                {" Définir les Horaires séléctionnables"}
              </Text>
            </View>

           
            <View style={{ width: "10%" }}>
          <TouchableOpacity 
    style={styles.paypalButton}
     onPress={openConfirmDate}
  
  >
    <Text style={styles.paypalButtonText}>Valider</Text>
  </TouchableOpacity>
          </View>
          </View>
          <View
            style={{
              borderBottomColor: "grey",
              borderBottomWidth: 0.5,
              
            }}
          />





            <View style={{height:900}}>
              <FlatList
                data={ArrayDay}
                renderItem={renderItemDay}
                keyExtractor={(item, index) => index.toString()}
                showsHorizontalScrollIndicator={true}
                contentContainerStyle={{ paddingBottom: 350 }}
              />
            </View>
          </View>
        </Modal>
      

        <Modal animationType="slide" visible={openConfirmDateModal}>

        <Layout  level='1'>
        <TopNavigation
          alignment='center'
          title='Fin de la Permanencee'
          
          accessoryLeft={
            
            <TouchableOpacity   onPress={()=>setOpenConfirmDateModal(false)} > 
            <Ionicons name="arrow-back"
          color={"#4e32a8"}
          size={35} />
  
          </TouchableOpacity>
        }
          accessoryRight={ <Button
          
        
              onPress={confirmDate}
            
          >
            <Text
              
            >
              Valider
            </Text>
          </Button>}
        />
      </Layout>
      

            <Text
            style={{ alignSelf: "center",marginVertical:"10%" }}
           
            color={theme.colors.black} 
          >
            Choisissez la date de fin de la permanence
          </Text>



          <View style={{alignSelf:"center"}}>
            <View>
            <Button  onPress={showDatepicker} color={theme.colors.lightBlue} style={styles.DateButton}>
                  <Text bold color={argonTheme.COLORS.WHITE}>
                  {moment(date)
            .format('dddd')
            .toLocaleUpperCase()}{' '}
            {moment(date)
              .format('DD')
              .toLocaleUpperCase()}{' '}
             {moment(date)
                  .format('MMMM')
                  .toLocaleUpperCase()}
                  {' '}
             {moment(date)
                  .format('YYYY')
                  .toLocaleUpperCase()}
                  </Text>
                </Button>
            </View>
        
       
          <DatePickerModal
          locale="fr"
          mode="single"
          visible={open}
          onDismiss={onDismissSingle}
          date={date}
          onConfirm={onConfirmSingle}
        />
      
        
        </View>
      

      
      
       
        
        </Modal>
      
        <Modal animationType="slide" visible={presetModal}>
        <View style={styles.centeredView}>
          
          
          <Layout  level='1'>
          <TopNavigation
            alignment='center'
            title='Choisir une Image'
            
            accessoryLeft={
              
              <TouchableOpacity   onPress={cancelImage} > 
              <Ionicons name="arrow-back"
            color={"#4e32a8"}
            size={35} />
    
            </TouchableOpacity>
          }
            accessoryRight={ <Button
            
              onPress={() => {
                setPresetModal(false);
              }}
            >
              <Text
                bold
                onPress={validImage}
                size={14}
               
              >
                Valider
              </Text>
            </Button>}
          />
        </Layout>
    
         <Divider></Divider>
         <View style={{flex:1}}>
         <View style={{height: 1000}}>
          <FlatList
          contentContainerStyle={{flexGrow: 1}}
            numColumns={6}
            extraData={imagePreset}
            data={imagePreset}
            scrollEnabled={true}
            renderItem={renderItemPreset}        
            keyExtractor={(item) => item.id}
          />
          </View>
          </View>
        </View>
      </Modal>

      

        <View>
          
            <View>
              <View style={styles.contentContainer}>
                <TouchableOpacity onPress={() => setCalendarModal(true)}>
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{
                        width: "12%",
                        alignItems: "flex-start",
                        marginVertical: "1%",
                      }}
                    >
                      <Feather name="calendar" color={"#b57704"} size={22} />
                    </View>
                    <View
                      style={{
                        width: "50%",
                        alignItems: "flex-start",
                        marginVertical: "1%",
                      }}
                    >
                      <Text h4 color="#302b63">
                        Plage Horaires
                      </Text>
                    </View>

                    <View>
                      {hourSelected.length !== 0 ? (
                        <View
                          style={{
                            minWidth: "50%",
                            marginHorizontal: "5%",
                            marginTop: "1%",
                          }}
                        >
                          <Image
                            style={{
                              width: 30,
                              height: 30,
                              resizeMode: "stretch",
                            }}
                            source={{
                              uri: "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/approved.png",
                            }}
                          />
                        </View>
                      ) : (
                        <View
                          style={{
                            marginTop: "2%",
                            justifyContent: "center",
                          }}
                        >
                          <Text h6 color="#969595" size={12}>
                            ( Obligatoire )
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                </TouchableOpacity>
                <View style={{ marginTop: "1%" }}>
                  <Divider />
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    paddingTop: "1%",
                    paddingBottom: "1%",
                  }}
                >
                  <View
                    style={{
                      width: "12%",

                      paddingTop: "1%",
                    }}
                  >
                    <View>
                      <Ionicons name="ios-image" color={"#036963"} size={22} />
                    </View>
                  </View>

                  <View
                    style={{
                      width: "55%",

                      paddingTop: "1%",
                      paddingRight: 151
                    }}
                  >
                    <TouchableOpacity onPress={() => setPresetModal(true)}>
                      <View>
                        <Text h4 color="#302b63" size={12}>
                          Image Prédéfinie
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View>
                    {image !== "" ? (
                      <View
                        style={{
                          width: "2%",
                          marginHorizontal: "7%",
                        }}
                      >
                        <Image
                          style={{
                            width: 65,
                            height: 50,
                            resizeMode: "stretch",
                          }}
                          source={image !== "" ? { uri: image } : null}
                        />
                      </View>
                    ) : (
                      <View
                        style={{
                          marginTop: "1%",
                          justifyContent: "center",
                          marginRight: "17%",
                        }}
                      >
                        <Text h6 color="#969595" size={12}>
                          ( Obligatoire )
                        </Text>
                      </View>
                    )}
                  </View>
                </View>

                <Divider />

              

                <View
                style={{
                  flexDirection: "row",
                  paddingTop: "1%",
                  paddingBottom: "1%",
                }}
              >
                <View
                  style={{
                    width: "12%",
                    paddingTop: "1%",
                  }}
                >
                  <Feather
                    name="check-square"
                    color={"#6b0409"}
                    size={22}
                  />
                </View>
                <View
                  style={{
                    width: "60%",
                    paddingTop: "1%",
                  }}
                >
                  <Text h4 color="#302b63">
                   RDV Ouvert aux Usagers
                  </Text>
                </View>

           

                <Toggle
                style={styles.toggle}
                checked={openAppointementUser}
                onChange={(value) => setOpenAppointementUser(value)}>
                Active
              </Toggle>
              </View>
              <Text h7 color="#302b63">
              La prise de rendez-vous sera ouverte aux usagers, dans le cas échéant les rendez-vous seront uniquement pris et géré par les administrateurs.
            </Text>

                <View style={{ marginTop: "1%" }}>
                  <Divider />
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    paddingTop: "1%",
                    paddingBottom: "1%",
                  }}
                >
                  <View
                    style={{
                      width: "12%",
                      paddingTop: "1%",
                    }}
                  >
                    <Feather
                      name="message-square"
                      color={"#b57704"}
                      size={22}
                    />
                  </View>
                  <View
                    style={{
                      width: "50%",
                      paddingTop: "%",
                    }}
                  >
                    <Text h4 color="#302b63">
                      Discussion
                    </Text>
                  </View>

                 
                  <Toggle
                  style={styles.toggle}
                  checked={activeChat}
                  onChange={(value) => setActiveChat(value)}>
                  Active
                </Toggle>
                </View>
              </View>
            </View>
          
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,

    backgroundColor: "white",
  },
  paypalButton: {
    backgroundColor: '#003087',
    padding: 10,
    alignItems: 'center',
    borderRadius: 5
  },
  paypalButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  scrollView: {
    paddingTop: "2%",
  },
  toggle: {
    margin: 2,
  },
  indicator: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    padding: theme.sizes.width * 0.04,
    fontSize: 15,
    marginBottom: 100,
  },

  Container: {
    
    backgroundColor: "#FFF",
    borderRadius: 4,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 0.1,
    elevation: 1,
    overflow: "hidden",
  },

  createButton: {
    width: theme.sizes.width * 0.2,
  },
  DateButton: {
    width: theme.sizes.width * 0.6,
    marginTop: theme.sizes.width * 0.04,
    borderRadius:20
  },
  DateButtonPreview: {
    width: theme.sizes.width * 0.2,
    height: theme.sizes.width * 0.09,
  },
  centeredView: {
    marginBottom: "30%",
  },
  contain: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  chip: {
    marginTop: 10,
    height: 40,
  },
  createButtonAddPole: {
    width: theme.sizes.width * 0.3,
    marginTop: theme.sizes.width * 0.07,
    alignSelf: "center",
  },
  picker: {
    margin: "7%",

    color: theme.colors.lightBlue,
    borderRadius: 10,
  },

  containerPermanence: {
    backgroundColor: "#f5f5f5",
    flex: 1,
  },
  hourUnselected: {
    borderRadius: 5,
    margin: 10,

    backgroundColor: "#e6eced",
    minWidth: 80,
    minHeight: 45,
    justifyContent: "center",
  },

  hourSelected: {
    borderRadius: 5,
    margin: 10,

    backgroundColor: theme.colors.lightBlue,
    minWidth: 80,
    minHeight: 45,
    justifyContent: "center",
  },

  text: { alignSelf: "center" },
  unselected: { color: "white" },
  selected: { color: theme.colors.lightBlue },

  card: {
    backgroundColor: theme.colors.white,

    flex: 1,
    margin: "5%",
    borderRadius: 10,
  },
  
});

const stylesPreset = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: theme.sizes.width * 0.056,
    marginBottom: theme.sizes.width * 0.056,
  },

  image: {
    width: theme.sizes.width * 0.15,
    height: 115,
  },
  text: {
    color: "#0f0c29",
    fontWeight: "bold",
  },
});



export default AddPermanence;

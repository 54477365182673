


export const checkVersion = (versionData) => {


    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/users/checkVersion", {
         method: "POST",
         headers: {
             "Content-Type": "application/json"
         },
          body: JSON.stringify({versionData})
     }).then((response) => response.json())
     .then((json) => {


       return json;
     }).catch((err) => {
         console.log("VersionActions.js, Version Request Error: ", err.message);
      
     });
   
 
 }
import * as ImagePicker from "expo-image-picker";
import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import moment from "moment";
import { getData } from "../storage/manageStorage";
import { ManageContext } from "../provider/manageStateProvider";

import {
  StyleSheet,
  Dimensions,
  View,
  TouchableOpacity,
  Modal,
  Image,
  ScrollView,
  Platform,


} from "react-native";




import { addPoleImageGallery } from "../actions/AddPoleAction";
import { addPolePredefinedImage } from "../actions/AddPoleImagePredefinedAction";

import { theme } from "../constants";
import { Feather, MaterialIcons, Ionicons } from "@expo/vector-icons";

import { FlatList } from "react-native-gesture-handler";

import {Toggle,Spinner, Input,ButtonGroup, Text,TopNavigation,Button,Icon, Layout,TopNavigationAction,Divider,IndexPath, Select, SelectItem } from '@ui-kitten/components';


const { width} = Dimensions.get("screen");



const AddPole = ({ route, navigation }) => {

  const data = [
    {
       "id": 1, 
       "name": "Mensuelle",
    },
    {
       "id": 2, 
       "name": "Ponctuelle",
    },
    {
       "id": 3, 
       "name": "Trimestrielle",
    },
    {
       "id": 4, 
       "name": "Annuelle",
    }
]
  


  const handleActivity = useContext(ManageContext);

  const _format = "YYYY-MM-DD";
  const _today = moment().format(_format);
  const snapPoints =  useMemo(() => [theme.sizes.height * 0.50,theme.sizes.height * 0.50], [])
 // callbacks

  const handleSheetChange = useCallback((index) => {}, []);

  const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0));

  const [idCentre, setIdCentre] = useState(route.params.idCentre);
  const [timeActivity, setTimeActivity] = useState("");
  const [date, setDate] = useState(new Date());
 
  const [error, setError] = useState("");

  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [namePole, setNamePole] = useState("");
  const [description, setDescription] = useState("");
  const [fileName, setFileName] = useState("");
  const [location, setLocation] = useState("");

  const [activeChat, setActiveChat] = useState(true);


  const [priceLimitModal, setPriceLimitModal] = useState(false);

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [price, setPrice] = useState(null);
  const [limitParticipation, setLimitParticipation] = useState(null);
  const [calendarDates, setCalendarDates] = useState({});
  const [calendarModal, setCalendarModal] = useState(false);
  
  const [section1, setSection1] = useState("");
  const [dateCalendars, setDateCalendars] = useState(new Date());
  const [addPoleModal, setAddPoleModal] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dayActivity, setDayActivity] = useState([]);
  const [samedi, setSamedi] = useState(false);
  const [dimanche, setDimanche] = useState(false);
  const [lundi, setLundi] = useState(false);
  const [mardi, setMardi] = useState(false);
  const [mercredi, setMercredi] = useState(false);
  const [jeudi, setJeudi] = useState(false);
  const [vendredi, setVendredi] = useState(false);
  const [priceTag, setPriceTag] = useState("Mensuelle");

  const [visible, setVisible] = useState(false);



  const [imagePreset, setImagePreset] = useState([
    {
      id: 9,
      name: "blastoise",
      url: "https://pokeapi.co/api/v2/pokemon/9/",
      image:
        "https://image.freepik.com/photos-gratuite/vue-dessus-recadre-mains-cuisinier-aine-decoupage-carotte-cuisson-ragout-legumes_1098-20510.jpg",
    },
    {
      id: 10,
      name: "caterpie",
      url: "https://pokeapi.co/api/v2/pokemon/10/",
      image:
        "https://image.freepik.com/vecteurs-libre/carte-journee-internationale-du-yoga-femme-contre-ciel-coucher-soleil_1048-14205.jpg",
    },
    {
      id: 11,
      name: "metapod",
      url: "https://pokeapi.co/api/v2/pokemon/11/",
      image:
        "https://image.freepik.com/photos-gratuite/groupe-enfants-qui-etudient-ecole_1303-26838.jpg",
    },
    {
      id: 12,
      name: "butterfree",
      url: "https://pokeapi.co/api/v2/pokemon/12/",
      image:
        "https://image.freepik.com/photos-gratuite/mains-femme-aide-puce-telephone-mobile_35534-132.jpg",
    },
    {
      id: 13,
      name: "weedle",
      url: "https://pokeapi.co/api/v2/pokemon/13/",
      image:
        "https://image.freepik.com/photos-gratuite/asiatique-jolie-fille-lunettes-sac-dos-assise-livre-ciel-bleu_9083-3226.jpg",
    },
    {
      id: 14,
      name: "kakuna",
      url: "https://pokeapi.co/api/v2/pokemon/14/",
      image:
        "https://image.freepik.com/photos-gratuite/gros-plan-image-programmeur-travaillant-son-bureau-dans-bureau_1098-18707.jpg",
    },
    {
      id: 15,
      name: "beedrill",
      url: "https://pokeapi.co/api/v2/pokemon/15/",
      image:
        "https://image.freepik.com/photos-gratuite/deux-boxeurs-professionnels-smoky-noir_155003-14259.jpg",
    },

    {
      id: 17,
      name: "pidgeottop",
      url: "https://image.freepik.com/photos-gratuite/famille-noire-profiter-ete-ensemble-dans-cour_53876-22989.jpg",
      image:
        "https://image.freepik.com/photos-gratuite/famille-noire-profiter-ete-ensemble-dans-cour_53876-22989.jpg",
    },

    {
      id: 19,
      name: "pidgeottol",
      url: "https://i.f1g.fr/media/cms/616x347_cropupscale/2020/09/23/10ec77d466dfff13488c90503f887c870eb97a6b897bcf194cbd96addfbe3b31.jpeg",
      image:
        "https://i.f1g.fr/media/cms/616x347_cropupscale/2020/09/23/10ec77d466dfff13488c90503f887c870eb97a6b897bcf194cbd96addfbe3b31.jpeg",
    },

    {
      id: 20,
      name: "pidgeottof",
      url: "https://www.francetvinfo.fr/pictures/kefDba1bJOWDQatk6-eRgK9yR0I/752x423/2021/04/27/6087cd30886a2_5f1ed83c7c215_maxsportsfrtwo618855.jpg",
      image:
        "https://www.francetvinfo.fr/pictures/kefDba1bJOWDQatk6-eRgK9yR0I/752x423/2021/04/27/6087cd30886a2_5f1ed83c7c215_maxsportsfrtwo618855.jpg",
    },

    {
      id: 18,
      name: "raticate",
      url: "https://image.freepik.com/photos-gratuite/jeune-mere-passant-du-temps-sa-fille_23-2148988991.jpg",
      image:
        "https://image.freepik.com/photos-gratuite/reunion-brainstorming-travail-equipe-nouveau-projet-demarrage-milieu-travail_2034-1617.jpg",
    },

    {
      id: 21,
      name: "boxe",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/boxe.png",
    },
 
    {
      id: 2,
      name: "boxe3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/boxe3.jpeg",
    },
    {
      id: 22,
      name: "yoga",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/coaching.jpg",
    },
    {
      id: 27,
      name: "yogaillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/yogaillu.jpg",
    },

    {
      id: 1,
      name: "yoga2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/yoga2.jpeg",
    },
    {
      id: 4,
      name: "yoga3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/yoga3.jpeg",
    },

    {
      id: 23,
      name: "art",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/Yoga.png",
    },
    {
      id: 40,
      name: "art1",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/art.png",
    },

    {
      id: 41,
      name: "art2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/art.jpg",
    },
    {
      id: 42,
      name: "art3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/art2.jpg",
    },
    {
      id: 43,
      name: "art4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/art3.jpg",
    },

    {
      id: 45,
      name: "culinaire",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/culinaire.jpg",
    }, 
    {
      id: 56,
      name: "culinaire2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/culinaire2.jpeg",
    },

    {
      id: 46,
      name: "familli",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/familli.jpg",
    },

    {
      id: 47,
      name: "famille1",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/famille1.jpg",
    },

    {
      id: 48,
      name: "math",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math.jpg",
    },
    {
      id: 49,
      name: "math2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math2.jpg",
    },
    {
      id: 50,
      name: "math4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math4.jpg",
    },
    {
      id: 51,
      name: "math5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math5.jpg",
    },
    {
      id: 52,
      name: "math6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math6.jpg",
    },
    {
      id: 53,
      name: "math7",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math7.jpg",
    },
    {
      id: 54,
      name: "math8",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math8.jpg",
    },

    {
      id: 55,
      name: "math9",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/math9.jpg",
    },

    {
      id: 7,
      name: "musique",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique.jpg",
    },

    {
      id: 57,
      name: "musique2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique2.jpg",
    },
    {
      id: 58,
      name: "musique3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique3.jpg",
    },
    {
      id: 59,
      name: "musique4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique4.jpg",
    },

    {
      id: 60,
      name: "musique5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique5.jpg",
    },

    {
      id: 61,
      name: "musique6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique6.jpg",
    },

    {
      id: 62,
      name: "musique7",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique7.jpg",
    },
    {
      id: 63,
      name: "musique8",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique8.jpg",
    },
    {
      id: 64,
      name: "parentalité",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/parentalité.jpg",
    },
    {
      id: 65,
      name: "scolaire",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/scolaire.jpg",
    },
    {
      id: 66,
      name: "scolaire2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/scolaire2.jpg",
    },
    {
      id: 67,
      name: "scolaire3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/scolaire3.jpg",
    },

    {
      id: 68,
      name: "traveau",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/traveau.jpg",
    },
    {
      id: 69,
      name: "lecture",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture.jpg",
    },
    {
      id: 70,
      name: "lecture2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture2.jpg",
    },
    {
      id: 71,
      name: "lecture3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture3.jpg",
    },

    {
      id: 72,
      name: "lecture4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture4.jpg",
    },

    {
      id: 73,
      name: "lecture5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture5.jpg",
    },
    {
      id: 74,
      name: "lecture6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/lecture6.jpg",
    },

    {
      id: 75,
      name: "loisir",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir.jpg",
    },

    {
      id: 76,
      name: "loisir2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir2.jpg",
    },

    {
      id: 77,
      name: "loisir4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir4.jpg",
    },
    {
      id: 78,
      name: "loisir5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir5.jpg",
    },
    {
      id: 79,
      name: "loisir6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir6.jpg",
    },
    {
      id: 80,
      name: "loisir7",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir7.jpg",
    },
    {
      id: 81,
      name: "loisir8",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/loisir8.jpg",
    },

    {
      id: 24,
      name: "bureutique",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/bureautique.png",
    },
    {
      id: 39,
      name: "alphabet",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/alphabet.jpeg",
    },

    {
      id: 24,
      name: "français",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/français.png",
    },

    {
      id: 99,
      name: "mathKid",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/mathKid.png",
    },

    {
      id: 26,
      name: "potterie",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/potterie.png",
    },
    
    {
      id: 5,
      name: "potterie4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/potterie4.jpeg",
    },
  
  
    {
      id: 37,
      name: "musique9",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique9.jpeg",
    },
  
    {
      id: 38,
      name: "musique10",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/musique10.jpeg",
    },

  
 
    {
      id: 87,
      name: "informatique2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/informatique2.jpeg",
    },
  
    
    {
      id: 92,
      name: "couture2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/couture2.jpeg",
    },
  
   
 
  
  
   
    {
      id: 93,
      name: "perma6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma6.jpeg",
    },
  
    {
      id: 97,
      name: "perma1",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma1.jpeg",
    },
    {
      id: 98,
      name: "perma2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma2.jpeg",
    },

    {
      id: 96,
      name: "perma3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma3.jpeg",
    },
  
  
    {
      id: 90,
      name: "perma4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma4.jpeg",
    },
  
    {
      id: 91,
      name: "perma5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma5.jpeg",
    },
  
 

    {
      id: 28,
      name: "sportIllu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/sportIllu.jpg",
    },
    {
      id: 29,
      name: "beachillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/beachillu.png",
    },
    {
      id: 30,
      name: "projectillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu.jpg",
    },
   
    {
      id: 32,
      name: "constructillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/constructillu.png",
    },
    {
      id: 33,
      name: "projecttillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu.jpg",
    },
    {
      id: 34,
      name: "projecttillu2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu2.png",
    },

    {
      id: 35,
      name: "pitchtillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/pitchillu.png",
    },
    {
      id: 36,
      name: "contentillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/contentillu.png",
    },

  ]);


  const [presetModal, setPresetModal] = useState(false);

  const bottomSheet = useRef(null);

  const cancelCalendar = () => {
    setCalendarModal(false);

    setCalendarDates({});
  };

  const cancelPriceLimit = () => {
    setPriceLimitModal(false);

    setLimitParticipation(null);
  };

  const namePoleErrors = () => {
    return namePole.length > 13;
  };

  const titleErrors = () => {
    return title.length > 24;
  };


const LoadingIndicator = (props) => (


  buttonLoading == true ?
  <View style={[props.style, styles.indicator]}>
    <Spinner size='small'/>
  </View> :null
);


  const publishHandler = async () => {
    if (
      title.length > 4 ||
      timeActivity.length > 4 ||
      title.length < 24 ||
      description.length > 4 ||
      location.length > 4 ||
      date.length > 4 ||
      namePole.length > 4 ||
      namePole.length < 13 ||
      dayActivity.length !== 0
    ) {
      setButtonLoading(true);

      
      if (image !== "") {
        
        const formData = {
          title: title,
          dayActivity: dayActivity,
          timeActivity: timeActivity,
          image: image,
          description: description,
          date: date,
          price: price,
          location: location,
          idCentre: idCentre,
          namePole: namePole,
          avatarCreator: handleActivity.updateAvatar,
          firstnameCreator: handleActivity.firstname,
          lastnameCreator: handleActivity.lastname,
          idUser: handleActivity.idUser,
          activeChat: activeChat,
          calendarDates: calendarDates,
          priceTag:  priceTag,
        };

        const response = await addPolePredefinedImage(formData);

        if (response.message == "Good") {
          handleActivity.updatePole == "false"
            ? handleActivity.setUpdatePole("true")
            : handleActivity.setUpdatePole("false");

          setButtonLoading(false);
          navigation.navigate("Home",{
            idCentre:idCentre
          });
        }
      }

      if (fileName.length !== 0) {

        
        var castedDate = moment(date.getTime())
          .add(dateCalendars, "s")
          .toDate();
        const dates = castedDate.toString();

        const formData = new FormData();

        if (price !== null) {
          formData.append("price", price);
        } else {
          formData.append("price", 0);
        }

        if (limitParticipation !== null) {
          formData.append("limitParticipation", limitParticipation);
        } else {
          formData.append("limitParticipation", 0);
        }

        var dayActivityStringified = JSON.stringify(dayActivity);
        const datesSend = (new Date(date)).toUTCString();
        
        formData.append("title", title);
        formData.append("dayActivity", dayActivityStringified);
        formData.append("timeActivity", timeActivity);
        formData.append("namePole", namePole);
        formData.append("idCentre", idCentre);
        formData.append("description", description);
        formData.append("date", dates);
        formData.append("location", location);
        formData.append("preview", image);
        formData.append("calendarDates", datesSend );
        formData.append("activeChat", activeChat);
        formData.append("priceTag", priceTag);
        formData.append("idUser", handleActivity.idUser);
        formData.append("avatarCreator", handleActivity.updateAvatar);
        formData.append("firstnameCreator", handleActivity.firstname);
        formData.append("lastnameCreator", handleActivity.lastname);
        formData.append("previewGallery", {
          name: title + "image.png",
          type: "image/png",
          uri:
            Platform.OS === "android"
              ? fileName
              : fileName.replace("file://", ""),
        });


        const response = await addPoleImageGallery(formData);

        if (response.message =='Good'){

        
          handleActivity.updatePole == "false"
          ? handleActivity.setUpdatePole("true")
          : handleActivity.setUpdatePole("false");

        setButtonLoading(false);

        navigation.navigate("Home",{
          idCentre:idCentre
        });
        
            } else{
        
              setVisible(true)
              setButtonLoading(false)
        
          
            }
      }
    } else {
    }
  };

  const validImage = () => {
    setPresetModal(false);
  };

  const cancelImage = () => {
    setPresetModal(false);

    setImage("");
  };


  const showDescriptionModal = () => {
    setDescriptionModal(true);
  };
 
  const hideDescriptionModal = () => {
    setDescriptionModal(false);
  };


  const onClickItem = (item, index) => {
    setImage(item.image);
    setFileName("");

    const newArrData = imagePreset.map((e, index) => {
      if (item.name == e.name) {
        return {
          ...e,
          selected: true,
        };
      }
      return {
        ...e,
        selected: false,
      };
    });

    setImagePreset(newArrData);
  };

  const renderItemPreset = ({ item, index }) => {
    return (
      <View style={{flex:1}}>
      <TouchableOpacity
        onPress={() => onClickItem(item, index)}
        style={{
          backgroundColor: item.selected ? "#0f0c29" : "white",
          padding: 2,
          borderRadius: 10,
        }}
      >
        <View style={stylesPreset.item}>
          <Image
            style={stylesPreset.image}
            resizeMode="contain"
            source={{ uri: item.image }}
          />
        </View>
      </TouchableOpacity>
      </View>
      
    );
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Image,
      allowsEditing: true,
      allowsMultipleSelection: false,
      aspect: [3, 2],
      quality: 0.2,
    });


    if (!result.cancelled) {
      
      setFileName(result.uri);
      setImage("");
    }
  };

  const AddNamePole = () => {
    if (namePole.length >= 3 && namePole.length <= 13) {
      setAddPoleModal(false);
    } else {
      if (namePole.length <= 3) {
        setError("Nom trop Court");
      } else {
        setError("Nom trop long");
      }
    }
  };
  const renderBackAction = () => (


   
    <TopNavigationAction onPress={() => navigation.navigate("Home",{
      idCentre:idCentre })} icon={BackIcon}/>

 

  );
  const BackIcon = (props) => (
    <Ionicons name="arrow-back"
                      color={"#4e32a8"}
                      size={35}
                    />
  );
  
  const goBackToHome = () => {
    setAddPoleModal(false);
    navigation.navigate("Home",{
      idCentre:idCentre
    });
  };

  const onDaySelect = (day) => {
    var contain = dayActivity.includes(day);

    if (contain == false) {
  
      dayActivity.push(day);
  
    } else {
     

      for (var i = 0; i < dayActivity.length; i++) {
        if (dayActivity[i] === day) {
          dayActivity.splice(i, 1);
        }
      }
    }
    if (day == "Samedi") {
      if (dayActivity.includes("Samedi")) {
        setSamedi(true);
      } else {
        setSamedi(false);
      }
    }
    if (day == "Dimanche") {
      if (dayActivity.includes("Dimanche")) {
        setDimanche(true);
      } else {
        setDimanche(false);
      }
    }
    if (day == "Lundi") {
      if (dayActivity.includes("Lundi")) {
        setLundi(true);
      } else {
        setLundi(false);
      }
    }
    if (day == "Mercredi") {
      if (dayActivity.includes("Mercredi")) {
        setMercredi(true);
      } else {
        setMercredi(false);
      }
    }
    if (day == "Jeudi") {
      if (dayActivity.includes("Jeudi")) {
        setJeudi(true);
      } else {
        setJeudi(false);
      }
    }

    if (day == "Mardi") {
      if (dayActivity.includes("Mardi")) {
        setMardi(true);
      } else {
        setMardi(false);
      }
    }

    if (day == "Vendredi") {
      if (dayActivity.includes("Vendredi")) {
        setVendredi(true);
      } else {
        setVendredi(false);
      }
    }
  };

  return (
    <>
      <View style={styles.Container}>
        <Modal animationType="slide" transparent={true} visible={addPoleModal}>
          <View
            style={{
              width: "90%",
              
              borderColor: "#ccc",
              borderWidth: 1,
              borderStyle: "solid",
              backgroundColor: "white",
              elevation: 50,
              marginVertical: "4%",
              marginLeft: "5%",
              borderRadius: 4,
              justifyContent: "center",
            }}
          >
          <Layout style={styles.container} level='1'>
      <TopNavigation
        alignment='center'
        title='Création d&rsquo;un nouveau pôle'
        
        accessoryLeft={renderBackAction}
        
      />
    </Layout>

            <View style={{ margin: "10%" }}>
           
            <Text  category='h5'>Insérez le nom du Pôle</Text>
            
            <Input
            maxLength={13}
            onChangeText={text => setNamePole(text.slice(0, 13))}
  
  label={evaProps => <Text {...evaProps}>* Privilégiez des noms court</Text>}
  caption={evaProps => <Text {...evaProps}>Limité a 13 caractères</Text>}
/>

           

              <Button    onPress={AddNamePole} style={{margin:2}} appearance='outline'>
              Confirmer
            </Button>
            </View>
          </View>
        </Modal>





      <Layout style={styles.container} level='1'>
      <TopNavigation
        alignment='center'
        title='Ajouter une activité'
        
        accessoryLeft={
          
          <TouchableOpacity    onPress={() => navigation.navigate("Home",{idCentre:route.params.idCentre})} > 
          <Ionicons name="arrow-back"
        color={"#4e32a8"}
        size={35} />

        </TouchableOpacity>
      }
        accessoryRight={ <Button
          onPress={publishHandler}
          appearance='outline' 
          
          accessoryLeft={LoadingIndicator}
          color={
            !title ||
            !description ||
            !location ||
            !date ||
            (!image && !fileName) ||
            dayActivity.length == 0
              ? "grey"
              : "#0f0c29"
          }
         
        >
          <Text
            bold
            disable={
              !title ||
              !description ||
              !location ||
             dayActivity.length == 0
            }
            size={14}
            color={
              !title ||
              !description ||
              !location ||
              !date ||
              (!image && !fileName) ||
              dayActivity.length == 0
                ? "#bdbdbd"
                : "white"
            }
          >
            Publier
          </Text>
        </Button>}
      />
    </Layout>


        <View style={{ marginTop: "2%" }}>
        <Divider/>
        </View>

                
  <ScrollView style={styles.scrollView}>
  <View style={{alignSelf:"center",width:"50%",}}>
            
  
  <Input
              placeholder="Titre"      
             
              onChangeText={text => setTitle(text.slice(0, 13))}
              value={title}
             
            ></Input>
          
          

            <Input
        multiline={true}
        textStyle={{ minHeight: 64 }}
        placeholder='Description'
        onChangeText={(text) => setDescription(text)}
        value={description}
       
      />

            <Input
              placeholder="Adresse"
            
         
              onChangeText={(text) => setLocation(text)}
              value={location}
           
            ></Input>
            

            <Layout style={styles.containerInput} level='1'>

            <Input
              style={styles.input}
              value={price}
              placeholder='Prix'
              keyboardType='numeric'
              onChangeText={nextValue => setPrice(nextValue.replace(/[^0-9]/, ''))}
            />
  


      <Select
      selectedIndex={selectedIndex}
      status="basic"
      size="large"
      value={data[selectedIndex.row]?.name}
      onSelect={(index) => {
        setSelectedIndex(index);
        setPriceTag(data[index.row]?.name)
      }}
    >
      {data.map((item, i) => (
        <SelectItem title={item.name} />
      ))}
    </Select>
    
            <Input
            style={styles.input}
            value={limitParticipation}
            placeholder='Maximun Participant'
            onChangeText={nextValue => setLimitParticipation(nextValue.replace(/[^0-9]/, ''))}
          />
          </Layout>

          <View style={{flexDirection:'row',alignSelf:"center"}}>
         
          
          <Button  appearance={
            lundi == true ? "filled": "outline"
          } onPress={() => onDaySelect("Lundi")}>Lundi</Button>
          <Button appearance={
            mardi == true ? "filled": "outline"
          }  onPress={() => onDaySelect("Mardi")}>Mardi</Button>
          <Button appearance={
            mercredi == true ? "filled": "outline"
          } onPress={() => onDaySelect("Mercredi")}>Mercredi</Button>
          <Button appearance={
            jeudi == true ? "filled": "outline"
          } onPress={() => onDaySelect("Jeudi")}>Jeudi</Button>
          <Button appearance={
           vendredi == true ? "filled": "outline"
          } onPress={() => onDaySelect("Vendredi")}>Vendredi</Button>
          <Button  appearance={
            samedi == true ? "filled": "outline"
          } onPress={() => onDaySelect("Samedi")}>Samedi</Button>
          <Button appearance={
            dimanche  == true ? "filled": "outline"
          } onPress={() => onDaySelect("Dimanche")}>Dimanche</Button>
        
          </View>

        <Input
        multiline={true}
        textStyle={{ minHeight: 64 }}
        placeholder='Décrivez les Horaires'
        onChangeText={(text) => setTimeActivity(text)}
          value={timeActivity}
       
      />

      
          </View>
        </ScrollView>
        <Modal animationType="slide" visible={presetModal}>
          <View style={styles.centeredView}>
            
            
            <Layout style={styles.container} level='1'>
            <TopNavigation
              alignment='center'
              title='Choisir une Image'
              
              accessoryLeft={
                
                <TouchableOpacity   onPress={cancelImage} > 
                <Ionicons name="arrow-back"
              color={"#4e32a8"}
              size={35} />
      
              </TouchableOpacity>
            }
              accessoryRight={ <Button
              
                onPress={() => {
                  setPresetModal(false);
                }}
              >
                <Text
                  bold
                  onPress={validImage}
                  size={14}
                 
                >
                  Valider
                </Text>
              </Button>}
            />
          </Layout>
      
           <Divider></Divider>
           <View style={{flex:1}}>
           <View style={{height: 1000}}>
            <FlatList
            contentContainerStyle={{flexGrow: 1}}
              numColumns={6}
              extraData={imagePreset}
              data={imagePreset}
              scrollEnabled={true}
              renderItem={renderItemPreset}        
              keyExtractor={(item) => item.id}
            />
            </View>
            </View>
          </View>
        </Modal>

    

    
       
          <View>
            <View style={styles.contentContainer}>
         
              <View style={{ marginTop: "2%" }}>
              <Divider/>
              </View>
              <TouchableOpacity onPress={() => setPresetModal(true)}>
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: "1%",
                  paddingBottom: "1%",
                }}
              >
                <View
                  style={{
                    width: "12%",

                    paddingTop: "1%",
                  }}
                >
                  <View>
                    <Ionicons name="ios-image" color={"#036963"} size={22} />
                  </View>
                </View>

                <View
                  style={{
                    width: "45%",

                    paddingTop: "1%",
                    paddingRight: 15,
                  }}
                >
                 
                    <View>
                      <Text category='label'>
                        Image Prédéfinie
                      </Text>
                    </View>
              
                </View>

                <View>
                  {image !== "" ? (
                    <View
                      style={{
                        width: "20%",
                        marginHorizontal: "2%",
                      }}
                    >
                      <Image
                        style={{ width: 200, height: 160, resizeMode: "stretch" }}
                        source={image !== "" ? { uri: image } : null}
                      />
                    </View>
                  ) : (
                    <View
                      style={{
                        marginTop: "5%",
                        justifyContent: "center",
                        marginRight: "5%",
                      }}
                    >
                      <Text category='label'>
                        ( Obligatoire )
                      </Text>
                    </View>
                  )}
                </View>
              </View>
              </TouchableOpacity>
              <View style={{ marginTop: "1%" }}>
              <Divider/>
              </View>
      




                <View
                  style={{
                    flexDirection: "row",
                    paddingTop: "1%",
                    paddingBottom: "1%",
                  }}
                >
                  <View
                    style={{
                      width: "12%",
                      paddingTop: "1%",
                    }}
                  >
                    <Feather
                      name="message-square"
                      color={"#b57704"}
                      size={22}
                    />
                  </View>
                  <View
                    style={{
                      width: "50%",
                      paddingTop: "%",
                    }}
                  >
                    <Text h4 color="#302b63">
                      Discussion
                    </Text>
                  </View>

                 
                  <Toggle
                  style={styles.toggle}
                  checked={activeChat}
                  onChange={(value) => setActiveChat(value)}>
                  Active
                </Toggle>
                </View>
              </View>
        

            </View>
            </View>
        
      
    
    </>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    paddingTop: "2%",
  },

  contentContainer: {
    padding: theme.sizes.width * 0.04,
    fontSize: 15,
    marginBottom:100
  },

  
  chip: {
    marginTop: 10,
    height:40
  },
  
  createButton: {
    width: width * 0.2,
    marginTop: theme.sizes.width * 0.07,
  },
  createButtonAddPole: {
    width: width * 0.3,
    marginTop: theme.sizes.width * 0.07,
    alignSelf: "center",
  },
  DateButton: {
    width: width * 0.4,
    marginTop: theme.sizes.width * 0.04,
  },
  DateButtonPreview: {
    width: width * 0.2,
    height: theme.sizes.width * 0.09,
  },
  centeredView: {
    marginBottom: "30%",
  },
  Container: {
    backgroundColor:"white"
  },
  containerInput: {
    flexDirection: 'row',
  },
  input: {
    flex: 1,
    margin: 2,
  },
  indicator: {
    justifyContent: 'center',
    alignItems: 'center',
  },

});

const stylesPreset = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: theme.sizes.width * 0.056,
    marginBottom: theme.sizes.width * 0.056,
  },

  image: {
    width: theme.sizes.width * 0.15,
    height: 115,
  },
  text: {
    color: "#0f0c29",
    fontWeight: "bold",
  },
});


;

export default AddPole;


export const fetchListPost = (idStructure) => {
 
  
    return fetch('https://mcsdatamanagesocial.osc-fr1.scalingo.io/posts/listPost', {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        idStructure
    
     })
      
      
      })
    .then((response) => response.json())
 
    .then((json) => {
      
      return json;
    })
    .catch((error) => {
      console.error(error);
    });


}

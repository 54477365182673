import React, { useContext ,useState} from "react";
import {  Layout, MenuItem, OverflowMenu, TopNavigation, TopNavigationAction,Text,Button } from '@ui-kitten/components';
import { StyleSheet ,TouchableOpacity,Image, View,ImageBackground,Modal,TextInput} from 'react-native';
import { MaterialIcons, Octicons,Feather } from "@expo/vector-icons";
import { useNavigation } from '@react-navigation/native';

import { ManageContext } from "../../provider/manageStateProvider";

import { storeData } from "../../storage/manageStorage";

import { theme } from "../../constants";
import axios from 'axios';

const BackIcon = (props) => (
  <View style={{flexDirection:"row"}}>
  <View>
  <Image
  source={require("../../assets/images/iconTransparent.png")}
  style={{
    width: 60,
    height: 60,
    alignSelf:"center",
    borderRadius: 62,
    borderWidth: 0,
    marginTop: theme.sizes.width * 0.01,
    marginBottom: theme.sizes.width * 0.01,
  }}
/>
</View>
<View>
<Text  style={{fontSize:20,marginTop:40,alignSelf:"center",  color:"black" }}>
Mon Centre
<Text   style={{ fontSize:20,marginTop:40,alignSelf:"center", color:"#00BCFF" }}>
  {" "}
  SociaL
</Text>
</Text>
</View>

</View>  

);

const MenuIcon = (props) => (
    <View style={{justifyContent: "center",margin: 30}}>
    <MaterialIcons name="settings" size={25} color="#070326" /></View>
);

const PlusIcon = (props) => (
    <Octicons name="plus" size={25} color="#070326" />
);

const KeyIcon = (props) => (
  <Octicons name="key" size={25} color="#070326" />
);
const InfoIcon = (props) => (
  
    <MaterialIcons name="info-outline" size={25} color="#070326" />
);
const LogoutIcon = (props) => (
  
  <MaterialIcons name="logout" size={25} color="#070326" />
);
const LoginIcon = (props) => (
  
  <MaterialIcons name="login" size={25} color="#070326" />
);
export const TopNavigationAccessoriesShowcase = ({dataFromParent}) => {
  const [fileName, setFileName] = useState("");
  const [fileNameUne, setFileNameUne] = useState("");
  const [imageStory, setImageStory] = useState("");
  const [imageUne, setImageUne] = useState("");
  const [createStoryModal, setCreateStoryModal] = useState(false);
  const [createUneModal, setCreateUneModal] = useState(false);

  const hideCreateStoryModal = () => {
    setFileName(
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/640px-A_black_image.jpg"
    );

    setCreateStoryModal(false);
  };
  const hideCreateUneModal = () => {
    setFileName(
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/640px-A_black_image.jpg"
    );

    setCreateUneModal(false);
  };

    const handleComponent = useContext(ManageContext);


    const onFileChange = (e) => {

      if (e.target.files && e.target.files[0]) {
        setImageStory(URL.createObjectURL(e.target.files[0]))
      }

    setFileName(e.target.files[0]);

};

const onFileChangeUne = (e) => {

  if (e.target.files && e.target.files[0]) {
    setImageUne(URL.createObjectURL(e.target.files[0]))
  }

setFileNameUne(e.target.files[0]);

};
const onSubmit = (e) => {
e.preventDefault();
const formData = new FormData();
formData.append('previewGallery', fileName);
formData.append("idStructure", dataFromParent.idCentre);
       
axios.post('https://mcsdatamanagesocial.osc-fr1.scalingo.io/centers/addStory', formData, {}).then((res) => {
 
console.log(res)
  if (res.data.message == "Good") {
    handleComponent.updateStory == "false"
      ? handleComponent.setUpdateStory("true")
      : handleComponent.setUpdateStory("false");

 
    setCreateStoryModal(false);
  } else {
    console.log("issue")
  }



});
};


const onSubmitUne = (e) => {


  e.preventDefault();
  const formData = new FormData();
  formData.append('previewGallery', fileNameUne);
  formData.append("idCentre", dataFromParent.idCentre);
  formData.append("title","")
  formData.append("description","")
  formData.append("date","")
  formData.append("location","")
         
  axios.post('https://mcsdatamanagesocial.osc-fr1.scalingo.io/posts/addCaptureImageGallery', formData, {}).then((res) => {
   
  console.log(res)

    if (res.data.message == "Good") {
      handleComponent.updateCapture == "false"
      ? handleComponent.setUpdateCapture("true")
      : handleComponent.setUpdateCapture("false");
    
   
      setCreateUneModal(false);
    } else {
      console.log("issue")
    }
  
  
  
  });
  };
  

    const disconnect = () => {
  
      storeData("isManager", "false");
      storeData("idUser", "");
      storeData("authToken", "");
  
      handleComponent.setFirstname("");
      handleComponent.setLastname("");
      handleComponent.setIdUser("0");
      handleComponent.setUpdateManager(false);
      handleComponent.setUpdateAvatar(
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/profileDefault.png"
      );
      storeData("lastname", "");
      storeData("firstname", "");
      storeData("phoneNumber", "");
      storeData("photoUser", "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/profileDefault.png");
     
      
    };

    const EditIcon = (props) => (

      <View style={{flexDirection:"row"}}>
      <View style={{width:"70%", margin:20,}}>
      <Button style={styles.button}  onPress={()=>{navigation.navigate("Welcome")}} appearance='ghost' status='warning'>
      les structures
    </Button>
    </View>
    <View style={{width:"30%"}}>
        <TouchableOpacity onPress={()=>{navigation.navigate("Profile",{
          idCentre:dataFromParent.idCentre
        })}}>
    
    
        <Image
        style={{
          marginVertical:20,
          width: 45,
          borderRadius:20,
          height: 45,
          
          resizeMode: "cover"}}     
          source={{ uri: handleComponent.updateAvatar }}
         
      />
      </TouchableOpacity>
      </View>
      </View>
    );
    

  const navigation = useNavigation();

  const [menuVisible, setMenuVisible] = React.useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const renderMenuAction = () => (
    <TopNavigationAction icon={MenuIcon} onPress={toggleMenu}/>
  );

  const renderRightActions = () => (
    <React.Fragment>
  
      <TopNavigationAction icon={EditIcon}/>
      <OverflowMenu style={{ maxHeight: 500}}
        anchor={renderMenuAction}
        visible={menuVisible}
        onBackdropPress={toggleMenu}>  
        {
          handleComponent.updateManager == true ?
         <View>
        <MenuItem  onPress={()=>{ setMenuVisible(!menuVisible); navigation.navigate("AddPole",{idCentre:dataFromParent.idCentre})}} accessoryLeft={PlusIcon} title='Pôle'/>
        <MenuItem onPress={()=>{ setCreateStoryModal(true)}} accessoryLeft={PlusIcon} title='Storie'/>
        <MenuItem  onPress={()=>{ setCreateUneModal(true)}} accessoryLeft={PlusIcon} title='Une'/>
        <MenuItem  onPress={()=>{ setMenuVisible(!menuVisible); navigation.navigate("AddPermanence",{idCentre:dataFromParent.idCentre})}} accessoryLeft={PlusIcon} title='Permanence'/>
        <MenuItem  onPress={()=>{  setMenuVisible(!menuVisible);navigation.navigate("AddHoliday",{idCentre:dataFromParent.idCentre})}} accessoryLeft={PlusIcon} title='Vacance'/>
        <MenuItem  onPress={()=>{ setMenuVisible(!menuVisible); navigation.navigate("AddEvent",{idCentre:dataFromParent.idCentre})}} accessoryLeft={PlusIcon} title='Evènement'/>
        </View>  :null
    
    } 
    <MenuItem  onPress={()=>{ setMenuVisible(!menuVisible); navigation.navigate("KeyRegister",{idCentre:dataFromParent.idCentre})}}  accessoryLeft={KeyIcon} title="j'ai un numéro d'accès"/>
       
    {
      handleComponent.idUser == "0"  ?
      <MenuItem  onPress={()=>{ setMenuVisible(!menuVisible); navigation.navigate("Login",{idCentre:dataFromParent.idCentre})}}  accessoryLeft={LoginIcon} title="Se Connecter"/>
   
      :
    
      <MenuItem  onPress={()=>{ setMenuVisible(!menuVisible);disconnect()}} accessoryLeft={LogoutIcon} title='Se Deconnecter'/>
      
    }
      
        
      </OverflowMenu>
    </React.Fragment>
  );

  const renderBackAction = () => (
    <TopNavigationAction icon={BackIcon}/>
  );

  const onChangeImg = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      setFileName(event.target.result);
    };

    reader.readAsDataURL(file);
  };


  return (
    <Layout style={styles.container} level='1'>
      <TopNavigation
        alignment='center'
        title={dataFromParent.nameCentre}
        
        accessoryLeft={renderBackAction}
        accessoryRight={renderRightActions}
      />

      <Modal
      animationType="fade"
      transparent={true}
      visible={createStoryModal}
    >
      <View
        style={{
          backgroundColor: "black",

          height: "100%",
        }}
      >
     
        <ImageBackground
          source={{ uri: imageStory }}
          style={styles.imageContent}
          resizeMode="contain"
          imageStyle={{ borderRadius: 15}}
        >
        
          <View style={{ flex: 1 }}>
          <View>
              <TouchableOpacity
                onPress={hideCreateStoryModal}
                style={{
                  width: 452,
                  height: 35,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  justifyContent: "center",
                  borderRadius: 10,
                  alignItems: "center",
                }}
              >
                <Feather
                  name="x"
                  size={theme.sizesPhotos.font * 1.2}
                  color={"#e0e0e0"}
                />
              </TouchableOpacity>

              <View
                style={{
                  width: 150,
                  height: 40,

            
                  
                }}
              >
              <View>

 <input type="file" id="image-input" onChange={onFileChange} />


<Button
style={styles.button}
status='primary'
onPress={onSubmit}
>
Valider
</Button>
</View>
              
              </View>


          
            </View>
          </View>

          <View
            style={{ justifyContent: "center", marginVertical: "20%" }}
          >
           
          </View>
        </ImageBackground>
        
      </View>
    </Modal>

    <Modal
      animationType="fade"
      transparent={true}
      visible={createUneModal}
    >
      <View
        style={{
          backgroundColor: "black",

          height: "100%",
        }}
      >
     
        <ImageBackground
          source={{ uri: imageUne }}
          style={styles.imageContent}
          resizeMode="contain"
          imageStyle={{ borderRadius: 15}}
        >
        
          <View style={{ flex: 1 }}>
          <View>
              <TouchableOpacity
                onPress={hideCreateUneModal}
                style={{
                  width: 452,
                  height: 35,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  justifyContent: "center",
                  borderRadius: 10,
                  alignItems: "center",
                }}
              >
                <Feather
                  name="x"
                  size={theme.sizesPhotos.font * 1.2}
                  color={"#e0e0e0"}
                />
              </TouchableOpacity>

              <View
                style={{
                  width: 150,
                  height: 40,

            
                  
                }}
              >
              <View>

 <input type="file" id="image-input" onChange={onFileChangeUne} />


<Button
style={styles.button}
status='primary'
onPress={onSubmitUne}
>
Valider
</Button>
</View>
              
              </View>


          
            </View>
          </View>

          <View
            style={{ justifyContent: "center", marginVertical: "20%" }}
          >
           
          </View>
        </ImageBackground>
        
      </View>
    </Modal>
    </Layout>

    
  );
};


const styles = StyleSheet.create({
  
  container: {
    minHeight: 100,
  },

 
  imageContent: {
    width: "80%",
    height: "80%",
    marginTop:"5%",
    marginLeft:"2%",
    justifyContent:"center"
  },





});



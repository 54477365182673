/* eslint-disable react-native/no-inline-styles */
/** Messages screen for broadcast messages */

//importing libraries
import React, {  useContext } from "react";
import {
  View,
  StatusBar,
  TouchableOpacity,
  ToastAndroid,
  Platform,
  SafeAreaView,
  Text


} from "react-native";

import {
  GiftedChat,
  Bubble,
  InputToolbar,
  Send,
  

} from "react-native-gifted-chat";

import { Ionicons } from "@expo/vector-icons";
import { SOCKET } from "../config/socket";

import * as ImagePicker from "expo-image-picker";
import axios from "axios";
import { findLastKey } from "lodash";




class Chat extends React.Component {
 
  _isMounted = false;

  constructor(props) {
    super(props);
    this._isMounted = true;
    this.state = {
      userId: this.props.route.params.idUser,
      userName: this.props.route.params.firstname,
      userPhoto: this.props.route.params.photoUser,
      isManager:this.props.route.params.isManager,
      messages: [],
      messageSelected: [],
      fileName: "",
      actionMessageVisible: false,
      visible :false,
      visibleSnackBar:false
    };
  }


   onToggleSnackBar = () => this.setState({
    visibleSnackBar: !visibleSnackBar
  });

 onDismissSnackBar = () =>this.setState({
  visibleSnackBar: false
});

  componentDidMount() {
    StatusBar.setBarStyle("light-content");
    if(Platform.OS === "android"){   StatusBar.setBackgroundColor("#006082")}
  
    StatusBar.setHidden(false);


    this._isMounted = findLastKey;

    //get previous messages
    this.getMessages();

    SOCKET.on("message", () => {
      this.getMessages();
    });
  }

  //styling chat bubbles
  renderBubble = (props) => {
    return (
      <Bubble
        {...props}
        textStyle={{
          right: {
            color: "#fff",
          },
        }}
        wrapperStyle={{
          right: {
            backgroundColor: "#009ca6",
          },
        }}
      />
    );
  };

  leftFromGroup = () => {
    SOCKET.disconnect();
    this.props.navigation.goBack();
  };



  //styling input bar
  renderInputToolbar = (props) => {
    return (
      <>
        <InputToolbar
          {...props}
          containerStyle={{
            backgroundColor: "#e3e3e3",
            borderTopWidth: 0,
            marginHorizontal: 15,

            marginLeft: "12%",
            borderRadius: 80,
          }}
          textInputProps={{
            style: {
              color: "black",
              flex: 1,
              alignItems: "center",
              paddingHorizontal: 25,
              paddingVertical: 10,
            },
            multiline: false,
            returnKeyType: "go",
            onSubmitEditing: () => {
              if (props.text && props.onSend) {
                let text = props.text;
                props.onSend({ text: text.trim() }, true);
              }
            },
          }}
        />
        <TouchableOpacity
          style={{
            position: "absolute",
            marginLeft: "3%",
            marginBottom: "1.7%",
            bottom: 0,
          }}
          onPress={this.handleChoosePhoto}
        >
          <Ionicons
            name='ios-image'
            style={{
              color: "#006082",
            }}
            size={23}
          />
        </TouchableOpacity>
      </>
    );
  };



  //styling send button
  renderSend = (props) => {
    return (
      <>
        <Send {...props}>
        <View style={{marginBottom:"50%"}}>
           
          <Ionicons
            name='ios-send-sharp'
            color="#006082"
            size={23}
          />
          </View>
        </Send>
      </>
    );
  };

  handleChoosePhoto = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes:ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      allowsMultipleSelection: false,
      quality: 0.2
    });

    if (result.cancelled) {
    } else {
      this.setState({
        fileName: result.uri,
      });

      // You can also display the image using data:
      // const source = { uri: 'data:image/jpeg;base64,' + response.data };

      let axiosConfig = {
        headers: {
          Authorization: "Client-ID ead116aab30174c",
        },
        timeout: 8000,
      };

      let formData = new FormData();

      formData.append("previewGallery", {
        name: "image.png",
        type: "image/png",
        uri:
          Platform.OS === "android"
            ? this.state.fileName
            : this.state.fileName.replace("file://", ""),
      });

      if( Platform.OS === "android") {
      ToastAndroid.show("Envoi en cour ...", ToastAndroid.LONG);
    }
   

      axios
        .post(
          "https://mcsdatamanagesocial.osc-fr1.scalingo.io" +
            "/message/sendImage/",
          formData,
          axiosConfig
        )
        .then(async(res) => {
          if (res.status === 200) {

           
            try {
              const random = Math.random().toString(20).substr(2, 12);

              let formData = {
                _id: random,
                createdAt: new Date(),
                text: "",
                concept: this.props.route.params.concept,
                image: res.data,
                user: {
                  _id: this.state.userId,
                  avatar: this.state.userPhoto,
                  name: this.state.userName,
                },
              };

       
              this.setState((previousState) => ({
                messages: GiftedChat.append(previousState.messages, formData),
              }));

this.getResponse(formData)

              
            } catch (error) {}
            //send image
          } else {
            ToastAndroid.show(
              "Uploading failed. Try again",
              ToastAndroid.SHORT
            );
          }
        });
    }
  };

  getMessages = async () => {
    try {
      let response = await axios.get(
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io" +
          "/message/broadcast/" +
          this.props.route.params.concept
      );
      if (response.status === 200) {
        let broadcasts = response.data.reverse();
        this.setState((previousState) => ({
          messages: GiftedChat.append([], broadcasts),
        }));
      }
    } catch (error) {}
  };



  getResponse = async (formData) => {
    let response = await axios.post(
      "https://mcsdatamanagesocial.osc-fr1.scalingo.io" +
        "/message/broadcast/",
      formData
    );


    if (response.status === 200) {

     
      SOCKET.emit("chatMessage", "sent");
    }
  };


  componentWillUnmount() {
    this._isMounted = false;
  }

 async onSend(messages = []) {
    
  this.setState((previousState) => ({
      messages: GiftedChat.append(previousState.messages, messages),
    }));

    try {
      let formData = {
        __v: messages[0].__v,
        _id: messages[0]._id,
        createdAt: messages[0].createdAt,
        text: messages[0].text,
        concept: this.props.route.params.concept,
        user: {
          _id: messages[0].user._id,
          avatar: messages[0].user.avatar,
          name: messages[0].user.name,
        },
      };
      let response = await axios.post(
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io" +
          "/message/broadcast/",
        formData
      );
      if (response.status === 200) {

       
        SOCKET.emit("chatMessage", messages);
      }
    } catch (error) {
   
    }
  }

  async onDelete() {
    this.setState({ actionMessageVisible: false});
    this.setState((previousState) => ({
      messages: previousState.messages.filter(
        (message) => message._id !== this.state.messageSelected._id
      ),
    }));

    try {
      let formData = {
        _id: this.state.messageSelected._id,
      };

      let response = axios.post(
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io" + "/message/delete/",
        formData
      );
      if (response.status === 200) {
    
        SOCKET.emit("chatMessage", messages);
      }
    } catch (error) {
      
    }
  }

  async onLongPress(context, message) {


    if(message.user._id == this.state.userId || this.state.isManager == true){

      this.setState({ actionMessageVisible: true, messageSelected: message });

    }


  
  }



  render() {
    const { navigation } = this.props;
    return (
      
        <View style={{ backgroundColor: "#fff", flex: 1 }}>
          {this.state.actionMessageVisible == false ? (
            <View
              style={{
                flexDirection: "row",
                height:  (Platform.OS === 'ios') ? "12%" : "10%",
                width: "100%",
                backgroundColor: "#006082",
                alignItems: "center",
                justifyContent: "space-between",
                paddingHorizontal: "7%",
              }}
            >
              <TouchableOpacity
                style={{ width: "30%" ,paddingTop:  (Platform.OS === 'ios') ? "8%" : 0}}
                onPress={this.leftFromGroup}
              >
                <Ionicons
                  name='ios-arrow-back'
                  size={22}
                  style={{ color: "#fff" }}
                />
              </TouchableOpacity>
              <Text
             
                style={{
                  fontSize: 18,
                  color: "#f2f2f2",
                  textAlign: "center",
                  width: "70%",
                  paddingTop:  (Platform.OS === 'ios') ? "8%" : 0,
                }}
              >
                {this.props.route.params.title}
              </Text>
            </View>
          ) : (
            
         
            <View
             style={{
              flexDirection: "row",
              height:  (Platform.OS === 'ios') ? "12%" : "10%",
              width: "100%",
              backgroundColor: "#4b007d",
              alignItems: "center",
              justifyContent: "space-between",
              paddingHorizontal: "7%",
              
            
            }}
            >
         
              <TouchableOpacity
                style={{ width: "50%",
                paddingTop:  (Platform.OS === 'ios') ? "8%" : 0 }}
                onPress={() => this.setState({ actionMessageVisible: false })}
              > 
                <Ionicons
                  name='arrow-undo'
                  size={17}
                  style={{ color: "#fff" }}
                />
              </TouchableOpacity>

              <TouchableOpacity
                style={{ flex: 1,paddingTop:  (Platform.OS === 'ios') ? "8%" : 0 }}
                onPress={() => this.setState({ actionMessageVisible: false })}
              >
                <Ionicons name='copy' size={17} style={{ color: "#fff" }} />
              </TouchableOpacity>

              <TouchableOpacity
                style={{ flex: 1,paddingTop:  (Platform.OS === 'ios') ? "8%" : 0, }}
                onPress={this.onDelete.bind(this)}
              >
                <Ionicons
                  name='trash-bin-sharp'
                  size={17}
                  style={{ color: "#fff" }}
                />
              </TouchableOpacity>

              <TouchableOpacity
                style={{ flex: 1,paddingTop:  (Platform.OS === 'ios') ? "8%" : 0}}
                onPress={() => this.setState({ actionMessageVisible: false })}
              >
                <Ionicons
                  name='person-remove'
                  size={17}
                  style={{ color: "#fff" }}
                />
              </TouchableOpacity>
            </View>
            
          )}
   
         
          <GiftedChat
            listViewProps={{
              style: {
                backgroundColor: "#fff",
              },
            }}
            renderUsernameOnMessage={true}
            alwaysShowSend={true}
            messages={this.state.messages}
            renderBubble={this.renderBubble}
            renderInputToolbar={this.renderInputToolbar}
            renderSend={this.renderSend}
            onSend={(messages) => this.onSend(messages)}
            timeFormat='HH:mm'
            locale={"fr"}
            isAnimated
            timeTextStyle={{left:{color:'#009ca6'},right:{color:'yellow'}}}
            onLongPress={this.onLongPress.bind(this)}
            placeholder='Taper votre message'
            user={{
              _id: this.state.userId,
              name: this.state.userName,
              avatar: this.state.userPhoto,
            }}
          />

   
          <View
            style={{
              height: "1%",
              width: "100%",
            }}
          />

          
        </View>
        
    );
  }
}

export default Chat;

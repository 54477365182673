import AsyncStorage from "@react-native-async-storage/async-storage";
// action types

import React, { useContext } from "react";

import { ManageContext } from "../provider/manageStateProvider";
// save the auth token to AsyncStorage

export const setAuthToken = (authToken) => {
  AsyncStorage.setItem("userToken", authToken);
};

export const deleteAuthToken = () => {
  AsyncStorage.setItem("userToken", "");
};

export const setFirstname = (firstname) => {
  //ManageUser.setFirstname(firstname)
  AsyncStorage.setItem("firstname", firstname);
};

export const setLastname = (lastname) => {
  // ManageUser.setLastname(firstname)
  AsyncStorage.setItem("lastname", lastname);
};

export const setRole = (role) => {
  AsyncStorage.setItem("role", JSON.stringify(role));
};

const _mergeData = async (userName1, userName2) => {
  try {
    // first save the userName1 suppose it is “Tom”
    await AsyncStorage.setItem("user_name", userName1);

    // now merge userName2 suppose it is “Jerry”
    await AsyncStorage.mergeItem("user_name", userName2);
    // we have assigned userName2 for same key “user_name”
    const currentUser = await AsyncStorage.getItem("user_name");
  
    // result:Jerry
  } catch (error) {

  }
};

export const storeData = async (key, value) => {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (error) {
  
  }
};

export const getData = async (key) => {
  try {
    const data = await AsyncStorage.getItem(key);
    if (data !== null) {
      return data;
    }
  } catch (error) {
  
  }
};

export const removeData = async (key) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (error) {
 
  }
};

// fonction that will chck if array of centerManaged by user logged contain these idCenter

export const doesHeManage = async (idCentre) => {
  try {
    const manageList = await AsyncStorage.getItem("structureManaged");
    const isLogged = await AsyncStorage.getItem("authToken");

    if (manageList == null || isLogged == undefined || isLogged == null) {
      return false;
    } else {
      const ArrayIdStructure = JSON.parse(manageList);

      const doesContain = ArrayIdStructure.includes(idCentre);
      if (doesContain == true) {
        await AsyncStorage.setItem("isManager", "true");
      } else {
        await AsyncStorage.setItem("isManager", "false");
      }

      return doesContain;
    }
  } catch (error) {
   
  }
};

import { getData } from "../../storage/manageStorage";


export const addHolidayGallery = async  (formData) => {


    const token = await getData("authToken")

  return  fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/holidays/addHolidayGallery", {
        method: "POST",
        headers: {
            'Content-Type': 'multipart/form-data',// this is a imp line
            'Accept': 'application/json',
            'Authorization':'Bearer'+token,
        },
         body:  formData    
         
    }).then((response) => response.json())
    .then((json) => {
         
      
      return json;
    }).catch((err) => {
        console.log("addHolidayGallery.js, addHolidayGallery Request Error: ", err.message);
        
    });
  
  
}






export const checkIfConfirmAccount = (emailData) => {
 
    
    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/users/checkIfComfirmAccount", {
         method: "POST",
         headers: {
             "Content-Type": "application/json"
         },
          body: JSON.stringify({
          emailData
         
          })
     }).then((response) => response.json())
     .then((json) => {
            
       return json;
     }).catch((err) => {
         console.log("CheckIfConfirmAccount.js, email Request Error: ", err.message);
 
     });
   
 
 }
 
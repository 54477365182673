import AsyncStorage from '@react-native-async-storage/async-storage';

import {storeData} from "../../storage/manageStorage"


 const loginSuccess = async (loginData) => {


const message = loginData.message
const authToken = loginData.authToken
const manage = loginData.manageStructure
const photoUser = loginData.photo
const firstname = loginData.firstname
const lastname = loginData.lastname
const idUser = loginData.idUser
const userPhoneNumber = loginData.userPhoneNumber




const idCenter = await AsyncStorage.getItem("idC")
    

 if(message=="login success")
 {


    const containtIdStructure = manage.includes(idCenter)

    const isManager = containtIdStructure == true && loginData.role == "admin" ? "true": "false"


    
    storeData("isManager",isManager)
    storeData("authToken",authToken)
    storeData("lastname",lastname)
    storeData("firstname",firstname)
    storeData("photoUser",photoUser)
    storeData("structureManaged",JSON.stringify(manage))
    storeData("idUser",idUser)
    storeData("phoneNumber",userPhoneNumber)

 }


    
}



export const loginActions = (loginData) => {


    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/users/login", {
         method: "POST",
         headers: {
             "Content-Type": "application/json"
         },
          body: JSON.stringify({loginData})
     }).then((response) => response.json())
     .then((json) => {

         loginSuccess(json)
           
       return json;
     }).catch((err) => {
         console.log("loginDataActions.js, login Request Error: ", err.message);
      
     });
   
 
 }
import { getData } from "../../storage/manageStorage";


export const editHolidayPredefined = async (formData) => {

    const token = await getData("authToken")


    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/holidays/editHolidayPredefined", {
        method: "POST",
      headers: {
          "Content-Type": "application/json",
          'Authorization':'Bearer'+token,
      },
       body: JSON.stringify({formData})
         
    }).then((response) => response.json())
    .then((json) => {
      
    
      return json;
    }).catch((err) => {
        console.log("editHolidayPredefined.js, editHolidayPredefined Request Error: ", err.message);
        
    });
  
  
}






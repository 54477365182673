export default {
    COLORS: {
      DEFAULT: '#172B4D',
      PRIMARY: '#5E72E4',
      SECONDARY: '#F7FAFC',
      LABEL: '#FE2472',
      INFO: '#11CDEF',
      ERROR: '#F5365C',
      SUCCESS: '#2DCE89',
      WARNING: '#FB6340',
      /*not yet changed */
      MUTED: '#ADB5BD',
      INPUT: '#DCDCDC',
      INPUT_SUCCESS: '#7BDEB2',
      INPUT_ERROR: '#FCB3A4',
      ACTIVE: '#5E72E4', //same as primary
      BUTTON_COLOR: '#9C26B0', //wtf
      PLACEHOLDER: '#9FA5AA',
      SWITCH_ON: '#5E72E4',
      SWITCH_OFF: '#D4D9DD',
      GRADIENT_START: '#6B24AA',
      GRADIENT_END: '#AC2688',
      PRICE_COLOR: '#EAD5FB',
      BORDER_COLOR: '#E7E7E7',
      BLOCK: '#E7E7E7',
      ICON: '#172B4D',
      HEADER: '#525F7F',
      BORDER: '#CAD1D7',
      WHITE: '#FFFFFF',
      BLACK: '#000000',
      PURPLE:"#302b63",
    },
    SIZES: {
      BLOCK_SHADOW_RADIUS: 2,
    }




    
  };
import React, {
    useState,
    useEffect,
    useContext,
    useRef
 
  } from "react";

import { AntDesign } from "@expo/vector-icons";


import {
  Text,
  View,
  StyleSheet,
  Image,
  
  StatusBar,
  Animated,
  ImageBackground,
  TouchableOpacity,
  Easing,
  
} from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { fetchListHolidaySeason } from "../../actions/holiday/ListHolidaySeasonAction";



import { SafeAreaView } from "react-native-safe-area-context";

import { theme } from "../../constants";
import { ManageContext } from "../../provider/manageStateProvider";


//import * as animatable from 'react-native-animatable'

const HolidayBridge =  ({ navigation, route }) => {


const {item} = route.params




const handleHolidayBridge = useContext(ManageContext);
const [listHoliday, setListHoliday] = useState([]);
const zoomAnim = useRef(new Animated.Value(0)).current;

useEffect(() => {

    const fetchList = async () => {


      const ListHoliday = await fetchListHolidaySeason({
        idStructure: item.idStructure,
        season:item.title
     });
      
     setListHoliday(ListHoliday.docs);
    

    };

    fetchList();
  }, []);

  
const renderItemHoliday = ({ item, index })=>{


Animated.timing(zoomAnim, {
          toValue: 1,
            duration: 1000,
            easing: Easing.ease,
            useNativeDriver: false,


}).start();
   
    return (



    <TouchableOpacity
    onPress={() =>
      navigation.navigate("HolidayDetail", {
        holiday: item,
        concept: "holiday",
        idCentre: item.idStructure,
        titleHeader: route.params.item.header
      })
    }
    activeOpacity={0.8}
  
  >
    <Animated.View    style={[styles.cardHoliday, {opacity: zoomAnim }]}>
      <View>
        <ImageBackground
          source={{ uri: item.preview }}
          style={styles.imageHoliday}
          resizeMode="cover"
          borderTopRightRadius={10}
          borderTopLeftRadius={10}
        />


        <View style={styles.days}>
        <Text
        h5
        style={styles.ageRange}
        color={theme.colors.lightBlue}
      >
        {item.ageRange}
      </Text>
      
      </View> 
      </View> 
    
    </Animated.View>
  </TouchableOpacity>



)

}
     return (
         
        
    <SafeAreaView style={{flex:1}}>
    <StatusBar hidden />
       
    <AntDesign 
        name='arrowleft'
        size={24}
        color='#fff'
        style={{
        paddingHorizontal:theme.sizes.SPACING,
        position:'absolute',
        top:50,
        left:10,
        zIndex:2
    }}
    onPress={navigation.goBack}
        />

 
        

 
  
<View style={[StyleSheet.absoluteFillObject,{borderRadius:0}]}>



<Image source={{uri :item.image}}
style={[StyleSheet.absoluteFillObject,{
    resizeMode:'cover',
}

]}


/>


</View>


  
<Text

style ={styles.title}
color="#fff"
>
{item.header}
</Text>


<View style={{position:'absolute',bottom:120}}>



{

  handleHolidayBridge.updateManager == true ? 
  <View style={{flexDirection:"row"}}>
 
  <View style={{width:"50%"}}>


<Text

style ={{marginTop: "10%", marginLeft: "9%",   fontSize:25,
color:"#fff",
fontWeight:'800',
width: theme.sizes.ITEM_WIDTH * 0.9,
textTransform:'uppercase',
position:'absolute',

left: theme.sizes.SPACING * 2,}}
color="#fff"
>
{"Tranche d'âge"}
</Text>

</View>
  <View style={{width:"50%"}}>
  <AntDesign 
          name='pluscircle'
          size={40}
          color='#fff'
          style={{
            marginTop: "8%", marginLeft: "50%" ,
            
      }}
      onPress={() => navigation.navigate("AddHoliday", {
        idCentre: route.params.item.idStructure,
      })}
          />
          </View>
          </View>  :
          <View>
        <Text
        
        style={{ marginTop: "10%", marginLeft: "9%",   fontSize:25,
        color:"#fff",
        fontWeight:'800',
        width: theme.sizes.ITEM_WIDTH * 0.9,
        textTransform:'uppercase',
        position:'absolute',
        top:200,
        left: theme.sizes.SPACING * 2,
         }}
        color="#fff"
        >
        {"Tranche d'âge"}
        </Text>
        </View>
         
}

       

<FlatList
data={listHoliday}
horizontal
showsHorizontalScrollIndicator={false}
renderItem={renderItemHoliday}
contentContainerStyle={{padding:theme.sizes.SPACING}}
keyExtractor={(item) => item._id}
/>



</View>
        
        </SafeAreaView>
     
      
        );
    };
    



const styles = StyleSheet.create({
title :{

    fontSize:25,
    color:"#fff",
    fontWeight:'800',
    width: theme.sizes.ITEM_WIDTH * 0.9,
    textTransform:'uppercase',
    position:'absolute',
    top:100,
    left: theme.sizes.SPACING * 2,
    
    
},
ageRange:{
  fontSize:25,
    color:"#0e0833",
    fontWeight:'800',
  
    textTransform:'uppercase',


    marginTop:"10%"
},
cardHoliday: {
  width: theme.sizes.width * 0.15,
  height: 300,
  margin: 20,
  backgroundColor: "#fcfcfc",
  borderRadius: 10,
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.2,
  shadowRadius: 3.65,
  elevation: 3,
},

imageHoliday: {
  width: theme.sizes.width * 0.15,
  height: 240,
  alignSelf: "center",
  resizeMode: "cover",

  marginHorizontal: 0,
},
days: {
  position: "absolute",
  bottom: theme.sizes.SPACING - 68,
  left: theme.sizes.SPACING + 40,
 
  height: 60,
  borderRadius: 26,
  
  justifyContent: "center",
  alignItems: "center",
},
daysValue: {
  fontWeight: "800",
  color: "#fff",
  fontSize: 18,
},
daysLabel: {
  fontWeight: "800",
  color: "#fff",

  fontSize: 10,
},


})
 


export default HolidayBridge;
import React, { useEffect,useState,useContext} from 'react';



import { StyleSheet, View, SafeAreaView, Image, ScrollView,TouchableOpacity,ImageBackground, } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import {  Text, TopNavigation, TopNavigationAction } from '@ui-kitten/components';

import { listParticipation } from '../actions/participation/FindUsersParticipation';

import { ManageContext } from '../provider/manageStateProvider';

import { theme } from "../constants";

import { FlatList } from "react-native-gesture-handler";



const Profile = ({route,navigation})  => {


const idCentre = route.params.idCentre

const ManageProfile  = useContext(ManageContext)


  const [allParticipation, setAllParticipation] = useState([]);
  const [countEvent, setCountEvent] = useState(0);
  const [reload, setReload] = useState(false);
  const [countActivity, setCountActivity] = useState(0);

 useEffect( ()  => {


    //Put your Data loading function here instead of my loadData()

      const fetchParticipation = async () => {
      
      //const userId = await getData("idUser") 
    

      if ((ManageProfile.idUser !== undefined) && (ManageProfile.idUser !== "") ) {

        const formData = {
                idUser:ManageProfile.idUser
              }
  
        const participation = await listParticipation(formData)
  
        if (participation == undefined){
  
        setAllParticipation([]);
      }
  
        else{
      
  
        setAllParticipation(participation.allParticipation);
        setCountEvent(participation.countEvent);
        setCountActivity(participation.countActivity);
      }
  
    };


  }

     fetchParticipation();
  
}, [ManageProfile.userProfil,ManageProfile.updatePole]);

const BackIcon = (props) => (
  <TouchableOpacity onPress={() => navigation.navigate('Home',{
    
    idCentre : idCentre,
    
  })}>
  <Ionicons  name="arrow-back-sharp" size={25}  style={{ marginTop: 6, marginLeft: 2 }}></Ionicons>

  </TouchableOpacity>
);

const BackAction = () => (
  <TopNavigationAction icon={BackIcon}/>
);




 
  const renderItemParticipation = ({ item, index }) => {

   
    return (
      <TouchableOpacity key={`${item._id}`} onPress={() => navigation.navigate('ActivityDetail',{
        activity:item,
        concept:item.dayActivity.length == 0 ? "event" :"activity",
        idCentre : idCentre,
        idEvent : item.id
      })} activeOpacity={0.8} >
        <View
          style={{
            marginLeft: index === 0 ? 30 : 20,
            marginRight: index === allParticipation.length - 1 ? 30 : 0,
            borderRadius:20
          }}
        >
          <ImageBackground
            source={{ uri: item.preview,cache: "force-cache"  }}
            resizeMode="cover"
            borderRadius={20}
            style={{
             width:150,
             height:170,
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                alignItems: "flex-end",
                marginHorizontal: 15,
                marginVertical: 15,
              }}
            >
             
            </View>
            <View style={{ marginLeft: 20, marginBottom: 25 }}>

         
              
              <Text style={{color:"white",fontSize:15}}>{item.title}</Text>
            </View>
          </ImageBackground>
        </View>
      </TouchableOpacity>
    );
  };


    return (
        <SafeAreaView style={styles.container}>
        <TopNavigation
        accessoryLeft={BackAction}
        title='Acceuil'
      />
            <ScrollView  style={{ zIndex:1, }} showsVerticalScrollIndicator={false}>
               
                <View style={{ alignSelf: "center" }}>
                    <View style={styles.profileImage}>
                  
                        <Image    source={{uri: ManageProfile.updateAvatar}}  style={styles.image} resizeMode="cover"></Image>
                    </View>
     
                    {(ManageProfile.idUser !== "") && (ManageProfile.idUser !== undefined) && (ManageProfile.idUser !== "0") ?
                    <TouchableOpacity style={styles.editButton}  onPress={() => navigation.navigate("EditProfile",{
                      idUser:ManageProfile.idUser,
                      lastname:ManageProfile.lastname,
                      firstname:ManageProfile.firstname,
                      photoUser:ManageProfile.updateAvatar
      
                    })}>
                    <View style={styles.edit}>
                    
                  
                        <Ionicons name="ios-pencil-outline" size={17} color="#FFFF" style={{ marginTop: 6, marginLeft: 2 }}></Ionicons>
                    </View>
                    </TouchableOpacity> : null
                
                  }
                    </View>

                <View style={styles.infoContainer}>
                    <Text style={[styles.text, { fontWeight: "200", fontSize: 20 }]}>  { ManageProfile.lastname !== undefined || ManageProfile.firstname !== undefined ? ManageProfile.lastname + " "+ManageProfile.firstname: null }</Text>
                   
                </View>

                <View style={styles.statsContainer}>
                <View style={styles.statsBox}>
                    <Text style={[styles.text, { fontSize: 24 }]}>{(ManageProfile.idUser !== "") && (ManageProfile.idUser !== undefined) ? allParticipation.length : 0 }</Text>
                    <Text style={[styles.text, styles.subText]}>Participation</Text>
                </View>
                <View style={[styles.statsBox, { borderColor: "#DFD8C8", borderLeftWidth: 1, borderRightWidth: 1,marginHorizontal:30 }]}>
                    <Text style={[styles.text, { fontSize: 24 }]}>{(ManageProfile.idUser !== "") ? 0 :0 }</Text>
                    <Text style={[styles.text, styles.subText]}>Photo</Text>
                </View>
                <View style={styles.statsBox}>
                    <Text style={[styles.text, { fontSize: 24 }]}>{(ManageProfile.idUser !== "") ? 0 :0 }</Text>
                    <Text style={[styles.text, styles.subText]}>Sondage</Text>
                </View>
            </View>

                   

            {((allParticipation.length !== 0 ) && (ManageProfile.idUser !== undefined)  && (ManageProfile.idUser !== "")  ) ?
              <View>
              <View style={{ alignItems: "center" }}>
                    

              <View style={styles.recentItem}>
                  <View style={styles.activityIndicator}></View>
                  <View style={{ width: 500 }}>
                  <Text style={[styles.subText]}>Dernières Participations</Text>
              
                  </View>
              </View>
          </View>
              
              
              
              <View style={{ marginTop: 10 }}>
                <View
                      style={{
                        marginTop: theme.sizes.height * 0.02,
                        marginBottom: theme.sizes.height * 0.06,
                      }}
                    >
                    
                      <FlatList
                        horizontal
                        data={allParticipation}
                        renderItem={renderItemParticipation}
                        keyExtractor={(item, index) => index.toString()}
                        showsHorizontalScrollIndicator={true}
                      />
                    </View>
                    <View style={styles.mediaCount}>
                        <Text style={[styles.text, { fontSize: 15, color: "#DFD8C8", fontWeight: "300" }]}>{countActivity}</Text>
                        <Text style={[styles.text, { fontSize: 7, color: "#DFD8C8", textTransform: "uppercase" }]}>Activité</Text>
                        <Text style={[styles.text, { fontSize: 15, color: "#DFD8C8", fontWeight: "300" }]}>{countEvent}</Text>
                        <Text style={[styles.text, { fontSize: 7, color: "#DFD8C8", textTransform: "uppercase" }]}>évènement</Text>
                    </View>
                </View></View>: null}

              
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#FFF",
     
    },
    text: {
      
        color: "#52575D"
    },
    image: {
        flex: 1,
        height: undefined,
        width: undefined
    },
    editButton:{ 
      alignItems:"center", 
      borderRadius : 15, 
      padding:"2%", 
      
    position:"relative",
    top :"-15%"},
    
      
    titleBar: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 24,
        marginHorizontal: 25
    },
    subText: {
        fontSize: 20,
        color: "#AEB5BC",
        textTransform: "uppercase",
        fontWeight: "500"
    },
    profileImage: {
        width: 120,
        height: 120,
        borderRadius: 100,
        overflow: "hidden"
    },
   
    edit: {
        backgroundColor: theme.colors.lightBlue,
        position: "absolute",
        bottom: 0,
        right: 14,
        width: 34,
        height: 34,
        borderRadius: 30,
        alignItems: "center",
     
          
    },
    infoContainer: {
        alignSelf: "center",
        alignItems: "center",
        marginVertical: 25
    },
    statsContainer: {
        flexDirection: "row",
        alignSelf: "center",
        marginTop: 32
    },
    statsBox: {
        alignItems: "center",
        flex: 1
    },
    mediaImageContainer: {
        width: 180,
        height: 200,
        borderRadius: 12,
        overflow: "hidden",
        marginHorizontal: 10
    },
    mediaCount: {
        backgroundColor: "#41444B",
        position: "absolute",
        top: "50%",
        marginTop: -50,
        marginLeft: 30,
        width: 70,
        height: 70,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 12,
        shadowColor: "rgba(0, 0, 0, 0.38)",
        shadowOffset: { width: 0, height: 10 },
        shadowRadius: 20,
        shadowOpacity: 1
    },
  
    recentItem: {
        flexDirection: "row",
        alignItems: "flex-start",
        marginBottom: 16,
        marginTop:"15%"
    },
    activityIndicator: {
        backgroundColor:  theme.colors.lightBlue,
        padding: 4,
        height: 10,
        width: 10,
        borderRadius: 6,
        marginTop: 3,
        marginRight: 20
    }
});

export default Profile;

import { getData } from "../storage/manageStorage";


export const publishActions = async  (formData,idHoliday) => {


    const token = await getData("authToken")

    

    if(idHoliday ==  "0") {



      return  fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/poles/addActivity", {
        method: "POST",
        headers: {
            'Content-Type': 'multipart/form-data',// this is a imp line
            'Accept': 'application/json',
            'Authorization':'Bearer'+token,
        },
         body:formData    
         
    }).then((response) => response.json())
    .then((json) => {
         
      return json;
    }).catch((err) => {
        console.log("addActivityActions.js, addActivity Request Error: ", err.message);
        
    });
  
  

}else{


  return  fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/poles/addActivitySubGallery", {
    method: "POST",
    headers: {
        'Content-Type': 'multipart/form-data',// this is a imp line
        'Accept': 'application/json',
        'Authorization':'Bearer'+token,
    },
     body:formData    
     
}).then((response) => response.json())
.then((json) => {
     
  return json;
}).catch((err) => {
    console.log("addActivityActions.js, addActivity Request Error: ", err.message);
    
});


}


}


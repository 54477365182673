import { getData } from "../storage/manageStorage";

export const publishActionsPredefined = async (formData) => {

const token = await getData("authToken")



if(formData.idHoliday !== "0" ) {
 
 
  return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/poles/addSubHolidayActivityPredefined", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        'Authorization':'Bearer'+token,
    },
     body: JSON.stringify({
      formData
    
     })
}).then((response) => response.json())
.then((json) => {

  return json;
}).catch((err) => {
  console.log("addActivitySuccess.js, addActivity Request Error: ", err.message);
  
});

}
else{

  return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/poles/addActivityPredefined", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        'Authorization':'Bearer'+token,
    },
     body: JSON.stringify({
      formData
    
     })
}).then((response) => response.json())
.then((json) => {

  return json;
}).catch((err) => {
  console.log("addActivitySuccess.js, addActivity Request Error: ", err.message);
  
});


}
    



}









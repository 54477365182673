


  
export const AddEventImagePredefined =  (formData) => {



  return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/events/addEventPredefinedImage", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
 
      },
       body: JSON.stringify({
        formData
      
       })
  }).then((response) => response.json())
  .then((json) => {

    return json;
  }).catch((err) => {
    console.log("addEventSuccess.js, addEvent Request Error: ", err.message);
    
});



}









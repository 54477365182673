import React, { useEffect, useState, useRef, useContext } from "react";
import moment from "moment";

import localization from "moment/locale/fr";
import { SOCKET } from "../../config/socket";

import {
  Text,
  View,
  StyleSheet,
  ImageBackground,
  Platform,
  Image,
  StatusBar,
  Modal,
  Share,
  Animated,
  Button
} from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { LinearGradient } from "expo-linear-gradient";



import { ScrollView } from "react-native-gesture-handler";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Feather } from "@expo/vector-icons";
import { theme } from "../../constants";
import { getData } from "../../storage/manageStorage";
import { addUserParticipation } from "../../actions/AddParticipationFileAction";
import { loginActions } from "../../actions/authentification/LoginAction";
import { removeParticipation } from "../../actions/removeParticipation";
import { listParticipant } from "../../actions/FindUsersParticipate";
import { fetchActivityHoliday } from "../../actions/holiday/ListActivityHoliday";
import { getCreatorData } from "../../actions/GetCreatorData";
import { ManageContext } from "../../provider/manageStateProvider";



import { Ionicons } from "@expo/vector-icons";

const HolidayDetail = ({ navigation, route }) => {


  const flatListRef = useRef();

  const scrollX = useRef(new Animated.Value(0)).current;

  
  const handleHolidayDetail = useContext(ManageContext);
  const [password, setPassword] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [updateParticipation, setUpdateParticipation] = useState(false);
  const [expand, setSetExpand] = useState(0);
  const [manage, setManage] = useState(false);
  const [participationModal, setParticipationModal] = useState(false);
  const [idUser, setIdUser] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [photoUser, setPhotoUser] = useState("");
  const [userParticipation, setUserParticipation] = useState([]);
  const [allParticipation, setAllParticipation] = useState([]);
  const [creatorDetail, setCreatorDetail] = useState([]);
  const [activityHoliday, setActivityHoliday] = useState([]);
  const [isBanned, setIsBanned] = useState();
  const [idStructure, setIdStructure] = useState("");
  const [visibleMaxUser, setVisibleMaxUser] = useState(false);


  moment().locale("fr", localization).format("LLL");

  const holiday = route.params.holiday;

  const bottomSheetHandle = useRef(null);

  useEffect(() => {
    //Put your Data loading function here instead of my loadData()

    const unsubscribe = navigation.addListener("focus", async () => {
      await getData("isManager")
        .then((data) => data)
        .then((value) => {
          value == "true" ? setManage(true) : setManage(false);
        });

      await getData("idUser")
        .then((data) => data)
        .then((value) => {
          setIdUser(value);
        });

      await getData("idC")
        .then((data) => data)
        .then((value) => {
          setIdStructure(value);
        });

      await getData("firstname")
        .then((data) => data)
        .then((value) => {
          setFirstname(value);
        });

      await getData("photoUser")
        .then((data) => data)
        .then((value) => {
          setPhotoUser(value);
        });

      await getData("lastname")
        .then((data) => data)
        .then((value) => {
          setLastname(value);
        });

      return unsubscribe;
    });
  }, [navigation]);

  const idAllowParticipate =
    route.params.concept == "holiday"
      ? idStructure
      : route.params.holiday.idStructure == ""
      ? route.params.holiday.idCentre
      : route.params.holiday.idStructure;


  useEffect(() => {
        //Put your Data loading function here instead of my loadData()
        const fetchData = () => {
     
          setTimeout(async () => {
            const ListActivityHoliday = await fetchActivityHoliday(route.params.holiday.id);

      
            setActivityHoliday([ ...ListActivityHoliday.docs, { key: 'empty-right' }]);
          }, 1000)
         
    
        };
    
        fetchData();
      }, [handleHolidayDetail.updateActivityHoliday]);
    



      useEffect(() => {
        const fetchCreator = async () => {
    
          const formData = {
            idUser: route.params.holiday.idUser,
            
          };
    
    
          const creator = await getCreatorData(formData);
    
         if(creator !== undefined){
        
    
            setCreatorDetail(creator.response);
       
    
         
    
         }
    
      
        };
        fetchCreator();
    
      }, []);

  const goToEditHoliday = () => {
    navigation.navigate("EditHoliday", {
      holiday: route.params.holiday,
      concept: route.params.concept,
      idCentre: route.params.idCentre,
    });
  };

  const sendSmsHoliday = () => {
    const shareOptions = {
      title: "Mon Centre Social",
      message: `Bonjour, je trouve très intéréssant  les  ${
        route.params.titleHeader
      } et je souhaite t'en informer cela aura lieu du ${moment(
        holiday.dateStart
      )
        .format("DD MMM")
        .toLocaleUpperCase()} au ${moment(holiday.dateEnd)
        .format("DD MMM")
        .toLocaleUpperCase()}, pour plus d'information tu peux télécharger l'application Mon Centre Social : 
      
Play Store: 
https://play.google.com/store/apps/details?id=com.mcsexpo.MCS&gl=FR

Apple Store: 
https://apps.apple.com/fr/app/mon-centre-social/id1610144427?platform=iphone`,
      subject: "Participation",
    };

    Share.share(shareOptions);
  };


  
  const closeBottomSheet = () => {
    bottomSheetHandle.current.close();
    setSetExpand(0);
  };

  const hideMaxUserModal = () => {
    setVisibleMaxUser(false);
  };

  const showListParticipation = () => {
    if (
      route.params.concept == "holiday" &&
      handleHolidayDetail.updateManager !== false
    ) {
      setParticipationModal(true);
    } else {
      if (
        idStructure == route.params.holiday.idStructure &&
        handleHolidayDetail.updateManager !== false
      ) {
        setParticipationModal(true);
      }
    }
  };

  const goToAddActivity = () =>{

    navigation.navigate("AddActivity", {
      idHoliday: route.params.holiday.id,
      subActivity: true,
      idPole: "01",
   
    });
  }

  const hideListParticipation = () => {
    setParticipationModal(false);
  }



  const renderItemActivityHolidays = ({ item, index }) => {




    const inputRange = [
      (index - 1) * theme.sizes.FULL_SIZE,
      index * theme.sizes.FULL_SIZE,
      (index + 1) * theme.sizes.FULL_SIZE,
    ];

    const translateX = scrollX.interpolate({
      inputRange,
      outputRange: [theme.sizes.ITEM_WIDTH, 0, -theme.sizes.ITEM_WIDTH],
    });

    const scale = scrollX.interpolate({
      inputRange,
      outputRange: [1, 1.1, 1],
    });

    return (
      <TouchableOpacity
        onPress={() =>
          navigation.navigate("HolidayActivityDetail", {
            
            item:item,      
            concept: "holiday",
            idCentre: route.params.idCentre})
        }
        style={styles.itemContainer}
        activeOpacity={1.0}
      >
    
        <View 
          style={[
            StyleSheet.absoluteFillObject,
            { overflow: "hidden", borderRadius: theme.sizes.RADIUS },
          ]}
        >
          <Animated.Image
            source={{ uri: item.preview }}
            style={[
              StyleSheet.absoluteFillObject,
              { resizeMode: "cover", transform: [{ scale }] },
            ]}
          />
        </View>
        
        <Text
          style={styles.headerTitle}
        >
          {item.title}
        </Text>


       
        <Text
          style={styles.headerDate}
        >
          {moment(item.dateStart)
            .format("DD MMM")
            .toLocaleUpperCase()}
        </Text>

      </TouchableOpacity>
    );
  };
  function jsx() {
    let canHeEdit = "";

    if (
      idStructure == route.params.holiday.idStructure &&
      handleHolidayDetail.updateManager == true
    ) {
      canHeEdit = true;
    } else {
      canHeEdit = false;
    }

    if (route.params.concept == "holiday") {
      canHeEdit = true;
    }

    return (
      <View style={styles.container}>
        <StatusBar style="auto" />

        <ScrollView
          contentContainerStyle={{
            backgroundColor: "#FFF",
          }}
        >
          <ImageBackground
            resizeMode="cover"
            source={{ uri: holiday.preview }}
            style={{
              width: "100%",
            }}
          >
            <View style={{ flex: 1 }}>
              <View style={{    flexDirection: "row",
              justifyContent: "space-between",
              marginTop:80,
              marginLeft: 30,
              margiRight: 30,
              zIndex: 1}}>
                <TouchableOpacity
                  onPress={goBack}
                  style={{
                    width: 56,
                    height: 40,
                    backgroundColor: "rgba(0,0,0,0.2)",
                    justifyContent: "center",
                    borderRadius: 10,
                    alignItems: "center",
                  }}
                >
                <Feather
                name="arrow-left"
                size={theme.sizesPhotos.font * 1.2}
                color={"#e0e0e0"}
              />
                </TouchableOpacity>

                <View
                  style={{
                    width:
                      handleHolidayDetail.updateManager !== false &&
                      canHeEdit == true
                        ? 90
                        : 50,
                    height: 40,
                    backgroundColor: "rgba(0,0,0,0.2)",
                    justifyContent: "space-between",
                    borderRadius: 10,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                <TouchableOpacity onPress={sendSmsHoliday}>
                <Feather
                name="share-2"
                size={theme.sizesPhotos.font * 1.2}
                color={"#e0e0e0"}
                 style={{
                      marginLeft: 16,
                    }}
              />
                </TouchableOpacity>

                  {handleHolidayDetail.updateManager !== false &&
                  canHeEdit == true ? (
                    <TouchableOpacity onPress={goToEditHoliday}>
                      <View
                        style={{
                          margin: 10,
                        }}
                      >
                        <Feather
                          name="edit-2"
                          size={theme.sizesPhotos.font * 1.2}
                          color={"#e0e0e0"}
                        />
                      </View>
                    </TouchableOpacity>
                  ) : null}
                </View>
              </View>

              <View style={{flex: 1,
                justifyContent: 'flex-end',
                position: 'relative',}}>
               
                <LinearGradient
                  colors={["transparent", "transparent"]}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 0, y: 1 }}
                  style={{
                    width: "100%",
                    height: 200,
                    justifyContent: "flex-end",
                  }}
                >
                <View style={{ flexDirection: "row",
                justifyContent: "space-between",
                
                marginHorizontal: "20%"}}>
                    <View>
                      <Text
                      style={styles.header}
                      >
                        {route.params.titleHeader}
                      </Text>

                      <Text
                        
                        style={styles.headerAge}
                      >
                        {holiday.ageRange}
                      </Text>
                    </View>

                    <View
                      style={{
                        width: 170,
                        height: 100,
                        marginBottom: "10%",
                        marginTop: "10%",
                        borderRadius: 15,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: theme.colors.lightBlue,
                      }}
                    >
                      <Text  style={styles.headerTitleDate}>
                        {"Du "}
                        {moment(holiday.dateStart)
                          .format("DD MMM")
                          .toLocaleUpperCase()}
                      </Text>
                      <Text style={styles.headerTitleDate}>
                        {"Au "}
                        {moment(holiday.dateEnd)
                          .format("DD MMM")
                          .toLocaleUpperCase()}
                      </Text>
                    </View>
                  </View>
                </LinearGradient>

                <View
                  style={{
                    flexDirection: "row",
                    borderTopRightRadius: 15,
                    borderTopLeftRadius: 15,
                    paddingTop: 15,
                    backgroundColor: "white",
                  }}
                >
                  <LinearGradient
                    colors={["white", "white"]}
                    start={{ x: 0, y: 1 }}
                    end={{ x: 1, y: 1 }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ width: "19%" }}>
                        <TouchableOpacity
                          onPress={showListParticipation}
                          activeOpacity={0.8}
                        >
                          <View
                            style={{
                              marginHorizontal: theme.sizes.base * 1,
                              marginBottom: theme.sizes.base * 0.4,
                            }}
                          >
                            <View>
                              <View style={styles.postAuthorWrapper}>
                                <View
                                  colors={"#F8F8FF"}
                                  style={{
                                    width: 45,
                                    height: 45,
                                    borderRadius: 30,
                                  }}
                                >
                                  <View style={styles.imageReferent}>
                                    <Image
                                      source={{
                                        uri:
                                          route.params.holiday.idUser ==
                                          undefined
                                            ? route.params.holiday.avatarCreator
                                            : creatorDetail.photo,
                                      }}
                                      resizeMode="cover"
                                      style={styles.postProfileImgReferent}
                                    />
                                  </View>
                                </View>
                                <Text style={styles.postUsername}></Text>
                              </View>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>

                      <View
                        style={{
                          marginLeft: -theme.sizes.width * 0.17,
                          width: "50%",
                        }}
                      >
                        <TouchableOpacity
                          onPress={showListParticipation}
                          activeOpacity={0.8}
                        >
                          <View
                            style={{
                              marginHorizontal: theme.sizes.base * 1,
                              marginBottom: theme.sizes.base * 0.4,
                            }}
                          >
                            <View>
                              <View style={styles.postAuthorWrapper}>
                                <View
                                  colors={"#F8F8FF"}
                                  style={{
                                    width: 45,
                                    height: 45,
                                    borderRadius: 30,
                                  }}
                                >
                                  <View style={styles.imageNb}>
                                    <Text
                                      h4
                                      style={{
                                        marginLeft: "40%",
                                        marginVertical: "25%",
                                      }}
                                    >
                                      {allParticipation.length}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>

                      <View style={{ width: "90%" }}>
                        {route.params.holiday.activeChat == true &&
                        (handleHolidayDetail.userStructure ==
                          handleHolidayDetail.idStructure ||
                          handleHolidayDetail.updateManager == true) ? (
                          <TouchableOpacity onPress={chatHandler}>
                            <Image
                              style={{ width: 50, height: 50 }}
                              source={require("../../assets/images/chatButton.png")}
                            />
                          </TouchableOpacity>
                        ) : (
                          <LinearGradient
                            colors={theme.colors.linearDislabled}
                            start={{ x: 0, y: 1 }}
                            end={{ x: 1, y: 0 }}
                            style={{
                              width: 45,
                              height: 45,
                              borderRadius: 30,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Ionicons
                                name="chatbubble-ellipses"
                                color={
                                  route.params.holiday.activeChat == true
                                    ? "#fff"
                                    : "#c4c0c0"
                                }
                                size={16}
                              />
                            </View>
                          </LinearGradient>
                        )}
                      </View>
                    </View>
                  </LinearGradient>
                </View>
              </View>
            </View>
          </ImageBackground>

          <Modal
            animationType="slide"
            transparent={true}
            visible={visibleMaxUser}
          >
            <View
              style={{
                backgroundColor: "rgba(0,0,0,0.7)",
              }}
            >
              <View
                style={{
                  alignItems: "center",
                  width: "90%",
                  height: "50%",
                  borderColor: "#ccc",
                  borderWidth: 1,
                  borderRadius: 20,
                  borderStyle: "solid",
                  backgroundColor: "white",
                  opacity: 1,

                  marginVertical: "50%",
                  marginLeft: "5%",
                  borderRadius: 4,
                  justifyContent: "center",
                }}
              >
                <Text
                  h4
                  color="#232043"
                  style={{ margin: theme.sizes.padding }}
                >
                  {"Désolé le nombre maximum de participants a été atteint."}
                </Text>

                <Button
                  style={{ backgroundColor: theme.colors.lightBlue }}
                  onPress={hideMaxUserModal}
                  mode="contained"
                >
                  <Text style={{ padding: "5%" }} bold size={14}>
                    {"J'ai compris"}
                  </Text>
                </Button>
              </View>
            </View>
          </Modal>

          <View>

  
            <View>
          
              <Text
                h4
                style={{
                  marginVertical: "2%",
                  color: "#0f0c29",
                  alignSelf: "center",
                  fontSize:20
                }}
              >
                {holiday.description}
              </Text>
            </View>
            {handleHolidayDetail.updateManager == true ? 
   
          
          
          <TouchableOpacity onPress={goToAddActivity} style={styles.button}>
          <Text style={styles.text}>{"Ajouté une activité"}</Text>
        </TouchableOpacity>
          
          :null

          }
        {activityHoliday.length == 0 ? (
          null
        ) : activityHoliday.length >= 2 ? (
          <View>
            <View
              style={{
                borderRadius: 30,
                marginVertical: "2%",
              }}
            >
            <View
            style={{
              borderBottomColor: 'black',
              borderBottomWidth: StyleSheet.hairlineWidth,
            }}
          />
            </View>

            <View
              style={{
                flexDirection: "row",
              }}
            >
              <Text
                h3
                style={styles.headerTitleBlack}
                
              >
                Les Activités 
              </Text>
           
            </View>
            <View
              style={{
                marginTop: theme.sizes.height * 0.02,
              }}
            >
              <Animated.FlatList
                horizontal
                data={activityHoliday}
                renderItem={renderItemActivityHolidays}
                decelerationRate="fast"
                onScroll={Animated.event(
                  [{ nativeEvent: { contentOffset: { x: scrollX } } }],
                  { useNativeDriver: true }
                )}
                snapToInterval={10}
                keyExtractor={(item, index) => index.toString()}
                showsHorizontalScrollIndicator={false}
              />
            </View>
          </View>
        ) : null}



            
            {holiday.modality !== "" ? (
              <View  style={{
                marginTop: "2%",
                
              }}>
             
                <Text  style={styles.headerTitleBlack}>
                  {"Modalité :"}
                </Text>
                <Text style={{ margin: "2%", color: "#0f0c29" ,fontSize:20,}}>
                  {holiday.modality}
                </Text>
              </View>
            ) : null}

            <View style={{ flexDirection: "row" }}>
              <View style={{ width: "85%" }}>
                <Text  style={styles.headerTitleBlack}>
                  {"Organisé par : "}
                  {creatorDetail.firstnameCreator}{" "}
                  {creatorDetail.lastnameCreator}
                </Text>
              </View>

              <View style={styles.postAuthorWrapper}>
                <View
                  colors={"#2acaea"}
                  style={{
                    alignContent: "flex-end",
                    marginTop: "30%",
                    width: 45,
                    height: 45,
                    borderRadius: 30,
                  }}
                >
                  <View style={styles.imageReferent}>
                    <Image
                      source={{
                        uri:
                          route.params.holiday.idUser == undefined
                            ? route.params.holiday.avatarCreator
                            : creatorDetail.photo,
                      }}
                      resizeMode="cover"
                      style={styles.postProfileImgReferent}
                    />
                  </View>
                </View>
              </View>
            </View>
            <Modal animationType="slide" visible={participationModal}>
              <View style={styles.centeredView}>
                <View
                  style={{
                    flexDirection: "row",
                    marginVertical: "7%",
                    marginHorizontal: "3%",
                    paddingTop: Platform.OS === "ios" ? "8%" : 0,
                  }}
                >
                  <View style={{ width: "30%" }}>
                    <Ionicons
                      onPress={hideListParticipation}
                      style={{ marginLeft: 10 }}
                      name="arrow-back-circle-sharp"
                      color={theme.colors.lightBlue}
                      size={30}
                    />
                  </View>

                  <View style={{ width: "70%" }}>
                    <Text h3 color="black">
                      {" "}
                      Liste des Participants
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    height: 1,
                    width: "100%",
                    backgroundColor: "#CED0CE",
                    marginBottom: "5%",
                  }}
                />

                <FlatList
                  data={allParticipation}
                  renderItem={renderItemListParticipation}
                  keyExtractor={(item, index) => index.toString()}
                />
              </View>
            </Modal>
          </View>

          <View>
            <View
              style={{
                height: 100,
                zIndex: -1,
                borderRadius: 20,

                overflow: "hidden",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20,
              }}
            ></View>
          </View>
        </ScrollView>
      </View>
    );
  }

  const chatHandler = () => {
    if (idUser == null || idUser == "undefined" || idUser == "") {
      if (expand == 0) {
        bottomSheetHandle.current.expand();
        setSetExpand(1);
      } else {
        bottomSheetHandle.current.close();
        setSetExpand(0);
      }
    } else {
      if (route.params.holiday.activeChat == true) {
        navigation.navigate("Chat", {
          firstname: firstname,
          lastname: lastname,
          idUser: idUser,
          photoUser: photoUser,
          concept: route.params.holiday.id,
          title: route.params.holiday.title,
          isManager: handleHolidayDetail.updateManager,
        });

        SOCKET.connect();

        SOCKET.emit("joinGroup", {
          firstname: firstname,
          room: route.params.holiday.title,
        });
      }
    }
  };

  const loginHandler = () => {
    if (password.length < 6 || email.length < 4) {
      if (email.length < 4) {
        setMessage("Vérifiez vos informations et réessayez");
      }

      if (password.length < 6) {
        setMessage("Vérifiez vos informations et réessayez");
      }
    } else {
      setButtonLoading(true);

      const fetchResponse = async () => {
        await getData("idC")
          .then((data) => data)
          .then(async (idStructure) => {
            const loginData = {
              password: password,
              email: email,
              idStructure: idStructure,
            };

            const loginResponse = await loginActions(loginData);

            if (loginResponse.message == "login success") {
              closeBottomSheet();

              //const isManager = await doesHeManage(route.params.idCentre);

              const avatar =
                loginResponse.photo == undefined || loginResponse.photo == null
                  ? handleHolidayDetail.setUpdateAvatar(
                      "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageholiday/profileDefault.png"
                    )
                  : handleHolidayDetail.setUpdateAvatar(avatar);

              loginResponse.photo !== "" || loginResponse.photo !== undefined
                ? handleHolidayDetail.setUpdateAvatar(loginResponse.photo)
                : handleHolidayDetail.setUpdateAvatar(
                    "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageholiday/profileDefault.png"
                  );
              loginResponse.firstname !== null
                ? handleHolidayDetail.setFirstname(loginResponse.firstname)
                : handleHolidayDetail.setFirstname("");
              loginResponse.lastname !== null
                ? handleHolidayDetail.setLastname(loginResponse.lastname)
                : handleHolidayDetail.setLastname("");

              const isManager = loginResponse.isManager;
              const isHeManager = loginResponse.isHeManager;
              const isHeSuperAdmin = loginResponse.isHeSuperAdmin;

              isManager == true
                ? handleHolidayDetail.setUpdateManager(true)
                : handleHolidayDetail.setUpdateManager(false);

              isHeManager == true
                ? handleHolidayDetail.setUpdateIsHeManager(true)
                : handleHolidayDetail.setUpdateIsHeManager(false);

              isHeSuperAdmin == true
                ? handleHolidayDetail.setUpdateIsHeSuperAdmin(true)
                : handleHolidayDetail.setUpdateIsHeSuperAdmin(false);

              handleHolidayDetail.setIdUser(loginResponse.idUser);
              handleHolidayDetail.setUserStructure(loginResponse.userStructure);
              handleHolidayDetail.setUserProfil(
                !handleHolidayDetail.userProfil
              );

              setManage(isManager);
              setIdUser(loginResponse.idUser);
              setLastname(loginResponse.lastname);
              setFirstname(loginResponse.firstname);
              setPhotoUser(avatar);

              setButtonLoading(false);
            }

            if (
              loginResponse.message == "Mot de passe incorrect" ||
              loginResponse.message == "Email Inconnu"
            ) {
              setMessage("Email ou Mot de passe non reconnu");

              setButtonLoading(false);
            }
          });
      };

      fetchResponse();
    }
  };

  const userParticipationHandler = () => {
    if (idUser == null || idUser == "undefined" || idUser == "") {
      if (expand == 0) {
        bottomSheetHandle.current.expand(0);
        setSetExpand(1);
      } else {
        bottomSheetHandle.current.close();
        setSetExpand(0);
      }
    } else {
      if (handleHolidayDetail.userStructure == idAllowParticipate) {
        if (userParticipation == false) {
          const maxUser =
            route.params.holiday.maxUser == undefined
              ? 0
              : route.params.holiday.maxUser;
          const price =
            route.params.holiday.price == undefined
              ? 0
              : route.params.holiday.price;

          if (
            allParticipation.length !== 0 &&
            allParticipation.length >= maxUser
          ) {
            setVisibleMaxUser(true);
          } else {
            const formData = {
              idStructure: route.params.holiday.idStructure,
              firstname: firstname,
              lastname: lastname,
              idUser: idUser,
              photoUser: photoUser,
              idConcept: route.params.holiday.id,
              preview: route.params.holiday.preview,
              title: route.params.holiday.title,
              date: route.params.holiday.date,
              description: route.params.holiday.description,
              location: route.params.holiday.location,
              activeChat: route.params.holiday.activeChat,
              idCreator: route.params.holiday.idUser,
              price: price,
              maxUser: maxUser,
              allData: route.params.holiday,
              concept: route.params.concept,
            };

            addUserParticipation(formData);

            setUserParticipation(true);

            handleHolidayDetail.updatePole == "false"
              ? handleHolidayDetail.setUpdatePole("true")
              : handleHolidayDetail.setUpdatePole("false");
          }
        }

        if (isBanned !== true && userParticipation == true) {
          const formData = {
            idUser: idUser,
            idConcept: route.params.holiday.id,
          };

          removeParticipation(formData);

          setUserParticipation(false);

          handleHolidayDetail.updatePole == "false"
            ? handleHolidayDetail.setUpdatePole("true")
            : handleHolidayDetail.setUpdatePole("false");
        }
      }
    }
  };

  const deleteParticipationHandler = (item) => {
    const formData = {
      idUser: item.idUser,
      idConcept: route.params.holiday.id,
    };

    removeParticipation(formData);

    setUpdateParticipation(!updateParticipation);

    handleHolidayDetail.updatePole == "false"
      ? handleHolidayDetail.setUpdatePole("true")
      : handleHolidayDetail.setUpdatePole("false");
  };

  const renderItemListParticipation = ({ item }) => {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            padding: 16,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Image
            source={{ uri: item.photo }}
            resizeMode="cover"
            style={styles.postListProfileImg}
          />

          <Text
            h5
            style={{
              color: "#000",
            }}
          >{`${item.firstname} ${item.lastname}`}</Text>

          <Button
            onPress={() => {
              deleteParticipationHandler(item);
            }}
            loading={loading}
            style={{ marginHorizontal: "10%", width: "20%" }}
          >
            retirer
          </Button>
        </View>

        <View
          style={{
            height: 1,
            width: "86%",
            backgroundColor: "#CED0CE",
            marginLeft: "5%",
          }}
        />
      </View>
    );
  };

  const goBack = () => {
    StatusBar.setHidden(false);

    Platform.OS == "android" ? StatusBar.setBackgroundColor("#FF573300") : null;

    navigation.goBack();
  };

  return jsx();
};







const styles = StyleSheet.create({
  itemContainer: {
    width: theme.sizes.ITEM_WIDTH * 0.3 ,
    height: theme.sizes.ITEM_HEIGHT *0.2,
    margin: theme.sizes.SPACING
  },

  headerTitle: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "800",
    width: theme.sizes.ITEM_WIDTH * 0.8,
    textTransform: "uppercase",
    position: "absolute",
    top: theme.sizes.SPACING + 90,
    left: theme.sizes.SPACING + 20,
  },
  
  header: {
    fontSize: 22,
    color: "#fff",
    fontWeight: "800",
   
    textTransform: "uppercase",
    position: "absolute",
    top: theme.sizes.SPACING + 120,
    
    width: theme.sizes.ITEM_WIDTH * 0.8,
  },

  headerAge: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "800",
    width: theme.sizes.ITEM_WIDTH * 0.8,
    textTransform: "uppercase",
    position: "absolute",
    top: theme.sizes.SPACING + 150,
    
  },

  
  headerTitleDate: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "800",

    textTransform: "uppercase",
   
  
  },
  headerTitleBlack: {
    fontSize: 18,
    color: "black",
    fontWeight: "800",
   
    textTransform: "uppercase",
    position: "absolute",
   marginLeft:"2%"
   
  },
  headerDate: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "800",
    width: theme.sizes.ITEM_WIDTH * 0.8,
    textTransform: "uppercase",
    position: "absolute",
    top: theme.sizes.SPACING + 120,
    left: theme.sizes.SPACING + 20,
  },


  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  centeredView: {
    justifyContent: "center",
    flex: 1,
  },
  socialConnect: {
    backgroundColor: theme.colors.WHITE,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#8898AA",
    padding: 50,
  },
  socialTextButtons: {
    color: theme.colors.DEFAULT,
    fontWeight: "800",
    fontSize: 14,
  },
  postContainer: {
    backgroundColor: "transparent",
    opacity: 0.7,
    width: 75,
    marginVertical: 20,
    borderRadius: 18,
  },
  postTopWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  postAuthorWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },

  imageReferent: {
    borderColor: "#409ddb",
    borderWidth: 2,

    borderRadius: 30,
    width: 50.5,
    height: 50.5,
  },

  imageNb: {
    borderRadius: 30,
    width: 50.5,
    height: 50.5,
    backgroundColor: theme.colors.lightBlue,
    opacity: 0.8,
    position: "absolute",
    left: -17,
    zIndex: 100,
  },

  postProfileImgReferent: {
    width: 40.5,
    height: 40.5,
    borderRadius: 30,
    margin: 3,
  },

  image: {
    borderColor: "#8741bb",
    borderWidth: 2,
    borderRadius: 30,
    width: 61.5,
    height: 61.5,
  },

  postProfileImg: {
    width: 55.5,
    height: 55.5,
    borderRadius: 30,
    margin: 3,
  },
  postImg: {
    width: 80,
    height: 40,
    borderRadius: 10,
    alignSelf: "center",
  },

  postListProfileImg: {
    width: 55.5,
    height: 55.5,
    borderRadius: 30,
    margin: 15,
  },
  postUsername: {
    fontWeight: "600",
  },
  postMoreIcon: {
    transform: [{ rotate: "90deg" }],
    marginRight: 8,
  },

  postBottomWrapper: {
    flexDirection: "row",
    alignSelf: "stretch",
    justifyContent: "space-between",
    padding: 0,
  },
  postBottomLeftWrapper: {
    flexDirection: "row",
  },
  postDetailContainers: {
    marginRight: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  postDetailIcon: {
    marginRight: 0,
  },
  postDetailNumbers: {
    fontSize: 12,
  },
  button: {
    backgroundColor: theme.colors.lightBlue,
    padding: 12,
    borderRadius: 5,
    width:"30%",
    alignSelf:"center"
  },
  text: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default HolidayDetail;

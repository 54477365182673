
export const fetchListCentre = (postalCode) => {


   return fetch('https://mcsdatamanagesocial.osc-fr1.scalingo.io/centers/listStructure', {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
         body: JSON.stringify({postalCode})
    }).then((response) => response.json())
    .then((json) => {
      
      return json;
    }).catch((err) => {
        console.log("fetchListStructure.js, fetchListStructure.js Request Error: ", err.message);
     
    });

}






 
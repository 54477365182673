import { getData } from "../storage/manageStorage";


export const addPolePredefinedImage = async (formData) => {

  const token = await getData("authToken")

 return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/poles/addPolePredefinedImage", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          'Authorization':'Bearer'+token,
          
      },
       body: JSON.stringify({
        formData
      
       })
  }).then((response) => response.json())
  .then((json) => {


    return json;
  }).catch((err) => {
    console.log("addPoleSuccess.js, addPole Request Error: ", err.message);
    
});



}









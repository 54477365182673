
export const  DeletePostAction = (formData) => {


    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/posts/deletePost", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
         body: JSON.stringify({
          formData
        
         })
    }).then((response) => response.json())
    .then((json) => {
     
      return json;
    }).catch((err) => {
      console.log("deleteStory Request Error: ", err.message);
      
  });
  
  
  
  }
  
  
  
  
  
  
  
  
  
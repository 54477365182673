import * as ImagePicker from "expo-image-picker";
import React, { useState, useRef, useContext } from "react";
import moment from "moment";
import { ManageContext } from "../provider/manageStateProvider";

import {
  StyleSheet,
  Dimensions,
  StatusBar,
  View,
  TouchableOpacity,
  Modal,
  FlatList,
  Image,
 
  Platform,
  
} from "react-native";

import argonTheme from "../constants/argonTheme";

import { editPermanenceGallery } from "../actions/permanence/EditPermanenceGalleryAction";
import { editPermanencePredefined } from "../actions/permanence/EditPermanencePredefinedAction";
import { deletePermanenceActions } from "../actions/permanence/DeletePermanence";


import { theme } from "../constants";


import {
  Feather,
  MaterialIcons,
  Ionicons,
} from "@expo/vector-icons";


import { DatePickerModal } from 'react-native-paper-dates';

import {Toggle, Spinner,Input,ButtonGroup, Text,TopNavigation,Button,Icon, Layout,TopNavigationAction,Divider,IndexPath, Select, SelectItem } from '@ui-kitten/components';


const { width, height } = Dimensions.get("screen");

const EditPermanence = ({ route, navigation }) => {


const handlePermanence = useContext(ManageContext);

  const [time, setTime] = useState();
  const [image, setImage] = useState(route.params.permanence.preview);
  const [price, setPrice] = route.params.permanence.price !== null ?   useState(route.params.permanence.price.toString()) : useState(route.params.permanence.price);
  const [limitParticipation, setLimitParticipation] =  route.params.permanence.maxUser !== null ?  useState(route.params.permanence.maxUser.toString()) : useState(route.params.permanence.maxUser);

  const [title, setTitle] = useState(route.params.permanence.title);
  const [priceTag, setPriceTag] = route.params.permanence.priceTag == "" ? useState("Ponctuelle"): useState(route.params.permanence.priceTag);
  const [description, setDescription] = useState(route.params.permanence.description);
  const [fileName, setFileName] = useState("");
  const [location, setLocation] = useState(route.params.permanence.location);
  const [mode, setMode] = useState("date");
  const [show, setShow] = useState(false);
  const [deletePoleModal, setDeletePoleModal] = useState(false);
  const [activeChat, setActiveChat] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
 
  const [timeActivity, setTimeActivity] = useState(route.params.permanence.timeActivity);
  const [openAppointementUser, setOpenAppointementUser] = route.params.permanence.openAppointementUser !== undefined ? useState(route.params.permanence.openAppointementUser) :useState(true)
  const [calendarModal, setCalendarModal] = useState(false);
  const [priceLimitModal, setPriceLimitModal] = useState(false);
  const [openConfirmDateModal, setOpenConfirmDateModal] = useState(false);

  const [hourSelected, setHourSelected] = useState(route.params.initialHour);
  const [date, setDate] = useState(new Date(route.params.permanence.dateEnd));

  const [visible, setVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0));

  const [open, setOpen] = React.useState(false);

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );




  const [imagePreset, setImagePreset] = useState([
   
   
    {
      id: 93,
      name: "perma6",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma6.jpeg",
    },
  
    {
      id: 97,
      name: "perma1",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma1.jpeg",
    },
    {
      id: 98,
      name: "perma2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma2.jpeg",
    },

    {
      id: 96,
      name: "perma3",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma3.jpeg",
    },
  
  
    {
      id: 90,
      name: "perma4",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma4.jpeg",
    },
  
    {
      id: 91,
      name: "perma5",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/perma5.jpeg",
    },
  
 

    {
      id: 28,
      name: "sportIllu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/sportIllu.jpg",
    },
    {
      id: 29,
      name: "beachillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/beachillu.png",
    },
    {
      id: 30,
      name: "projectillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu.jpg",
    },
   
    {
      id: 32,
      name: "constructillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/constructillu.png",
    },
    {
      id: 33,
      name: "projecttillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu.jpg",
    },
    {
      id: 34,
      name: "projecttillu2",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/projectillu2.png",
    },

    {
      id: 35,
      name: "pitchtillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/pitchillu.png",
    },
    {
      id: 36,
      name: "contentillu",
      image:
        "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/contentillu.png",
    },

  ]);
  const ArrayDay = [
    {
      day: "Lundi",
    },
    {
      day: "Mardi",
    },
    {
      day: "Mercredi",
    },
    {
      day: "Jeudi",
    },
    {
      day: "Vendredi",
    },
    {
      day: "Samedi",
    },
    {
      day: "Dimanche",
    },
  ];
  const data = [
    {
       "id": 1, 
       "name": "Mensuelle",
    },
    {
       "id": 2, 
       "name": "Ponctuelle",
    },
    {
       "id": 3, 
       "name": "Trimestrielle",
    },
    {
       "id": 4, 
       "name": "Annuelle",
    }
]

const allHours = [
    "9h00",
    "9h30",
    "10h00",
    "10h30",
    "11h00",
    "11h30",
    "13h00",
    "13h30",
    "14h00",
    "14h30",
    "15h00",
    "15h30",
    "16h00",
    "16h30",
    "17h00",
    "17h30",
    "18h00",
    "18h30",
    "19h00",
  ];

  
  const [presetModal, setPresetModal] = useState(false);

  




 

  const showDatepicker = () => {
    setOpen(true)
  };



  const openConfirmDate =() => {
    setCalendarModal(false)
    setOpenConfirmDateModal(true)

 }


 const confirmDate =() => {
    
   setOpenConfirmDateModal(false)
   setCalendarModal(false)

}


  

  
  const publishHandler = async () => {


    setButtonLoading(true)

    if (fileName.length !== 0) {
        
        const formData = new FormData();

        var castedDate = moment(date).toDate();

        const dates = castedDate.toString();


        var hourSelectedStringified = JSON.stringify(hourSelected);

        formData.append("title", title);
        formData.append("description", description);
        formData.append("location", location);
        formData.append("activeChat", activeChat);
        formData.append("initialHour", hourSelectedStringified);
        formData.append("priceTag", priceTag);
        formData.append("openAppointementUser", openAppointementUser);
        formData.append("dateEnd", dates);    
        formData.append("idPermanence", route.params.permanence.id);
        formData.append("previewGallery", {
          name: "image.png",
          type: "image/png",
          uri:
            Platform.OS === "android"
              ? fileName
              : fileName.replace("file://", ""),
        });

     const response = await editPermanenceGallery(formData);


if (response.message =='Good'){
  navigation.navigate("Home",{idCentre:route.params.idCentre})
   
        handlePermanence.updatePermanence == "false"
          ? handlePermanence.setUpdatePermanence("true")
          : handlePermanence.setUpdatePermanence("false");

  setButtonLoading(false)



    } else{

      setVisible(true)
      setButtonLoading(false)



    }

    } else {


      const formData = {
        
         title: title,
          image: image,
          description: description,
          price: price,
          location: location,
          activeChat: activeChat,
          limitParticipation: limitParticipation,
          priceTag: priceTag,
          initialHour:hourSelected,
          openAppointementUser:openAppointementUser,
          dateEnd:date,
          idPermanence: route.params.permanence.id,
      };

   const response = await editPermanencePredefined(formData);

  

    if (response.message == "Good") {

      navigation.navigate("Home",{idCentre:route.params.idCentre})

        handlePermanence.updatePermanence == "false"
          ? handlePermanence.setUpdatePermanence("true")
          : handlePermanence.setUpdatePermanence("false");

        setButtonLoading(false);

        
      } 


    }

  
  };

  const validImage = () => {
    setPresetModal(false);
  };

  const cancelImage = () => {
    setPresetModal(false);

    setImage("");
  };
  const cancelCalendar = () => {
    setCalendarModal(false);

  };

  const onClickItem = (item, index) => {
    setImage(item.image);
    setFileName("");

    const newArrData = imagePreset.map((e, index) => {
      if (item.name == e.name) {
        return {
          ...e,
          selected: true,
        };
      }
      return {
        ...e,
        selected: false,
      };
    });

    setImagePreset(newArrData);
  };

  const handleDateSelected = (day, hour) => {
    const oldData = hourSelected;

    let Data = [];
    const selected = day + hour;

    Data.includes(selected);

    if (oldData.includes(selected) == false) {
      Data.push(selected);

      setHourSelected(Data.concat(oldData));
    } else {
      var filteredArray = oldData.filter(function (e) {
        return e !== selected;
      });

      setHourSelected(filteredArray);
    }
  };

  const renderItemDay = ({ item, index }) => {
    return (
      <View style={styles.card}>
        <Text style={{ color: "#1b3a59", margin: "4%" }} h5>
          {item.day}
        </Text>
        <Divider />
        <View style={{ flex: 1 }}>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {allHours.map((itemHour, i) => (
              <TouchableOpacity
                key={i}
                onPress={() => handleDateSelected(item.day, itemHour)}
              >
                <View
                  style={
                    hourSelected.includes(item.day + itemHour) == true
                      ? styles.hourSelected
                      : styles.hourUnselected
                  }
                >
                  <Text
                    style={[
                      styles.text,
                      hourSelected.includes(item.day + itemHour) == true
                        ? styles.unselected
                        : styles.selected,
                    ]}
                    h6
                  >
                    {itemHour}
                  </Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </View>
    );
  };


  const renderItemPreset = ({ item, index }) => {
    return (
      <TouchableOpacity
        onPress={() => onClickItem(item, index)}
        style={{
          backgroundColor: item.selected ? "#0f0c29" : "white",
          padding: 5,
          borderRadius: 10,
        }}
      >
        <View style={stylesPreset.item}>
          <Image
            style={stylesPreset.image}
            resizeMode='contain'
            source={{ uri: item.image }}
          />
        </View>
      </TouchableOpacity>
    );
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      allowsMultipleSelection: false,
      aspect: [3, 2],
      quality: 0.2
    });

    if (!result.cancelled) {
      setFileName(result.uri);
      setImage("");
    }
  };
  const hideDeletePoleModal = () => {
    setDeletePoleModal(false);
  };
  const showDeletePoleModal = () => {
    setDeletePoleModal(true);
  };

  const titleErrors = () => {
    return  title.length > 18;
  };
  const cancelPriceLimit = () => {
    setPriceLimitModal(false);

    setPrice(null);
    setLimitParticipation(null);
  };
  
  
  const DeletePermanenceHandler =  async  () => {

  

    setButtonLoading(true)

    const deletePermanenceData = {
      idPermanence: route.params.permanence.id,
      urlImage: route.params.permanence.preview,
      
    };

   const response = await deletePermanenceActions(deletePermanenceData);

   if(response.message == 'Good') {

  

        setButtonLoading(false)
        hideDeletePoleModal()
  
  
        navigation.navigate("Home",{idCentre:route.params.idCentre})
  
        handlePermanence.updatePermanence == "false"
        ? handlePermanence.setUpdatePermanence("true")
        : handlePermanence.setUpdatePermanence("false");

     
    

   }
    
  };

  const LoadingIndicator = (props) => (


    buttonLoading == true ?
    <View style={[props.style, styles.indicator]}>
      <Spinner size='small'/>
    </View> :null
  );
  

  return (
    <>
      <View style={styles.Container}>
     

        <Layout style={styles.container} level='1'>
        <TopNavigation
          alignment='center'
          title="Modifier la Permanence"
          
          accessoryLeft={
            
            <TouchableOpacity    onPress={() => navigation.navigate("Home",{idCentre:route.params.idCentre})} > 
            <Ionicons name="arrow-back"
          color={"#4e32a8"}
          size={35} />
  
          </TouchableOpacity>
        }
          accessoryRight={ 
            
            
            <Button
            onPress={publishHandler}
            appearance='outline' 
            accessoryLeft={LoadingIndicator}
            color={"#0f0c29"}
            style={styles.createButton}
          >
            <Text color={argonTheme.COLORS.WHITE}>
              Modifier
            </Text>
          </Button>}
        />
      </Layout>

        <View style={{ paddingTop: "2%" }}>
          <Divider />
        </View>

        <View>
           
        <View style={{alignSelf:"center",width:"50%",}}>
            
  
        <Input
                    placeholder="Titre"      
                   
                    onChangeText={text => setTitle(text.slice(0, 13))}
                    value={title}
                   
                  ></Input>
                
                
      
                  <Input
              multiline={true}
              textStyle={{ minHeight: 64 }}
              placeholder='Description'
              onChangeText={(text) => setDescription(text)}
              value={description}
             
            />
      
                  <Input
                    placeholder="Adresse"
                  
               
                    onChangeText={(text) => setLocation(text)}
                    value={location}
                 
                  ></Input>
                  
      
                  <Layout style={styles.containerInput} level='1'>
      
                  <Input
                    style={styles.input}
                    value={price}
                    placeholder='Prix'
                    keyboardType='numeric'
                    onChangeText={nextValue => setPrice(nextValue.replace(/[^0-9]/, ''))}
                  />
        
      
      
      
                 
       
                <Select
                selectedIndex={selectedIndex}
                status="basic"
                size="large"
                value={data[selectedIndex.row]?.name}
                onSelect={(index) => {
                  setSelectedIndex(index);
                  setPriceTag(data[index.row]?.name)
                }}
              >
                {data.map((item, i) => (
                  <SelectItem title={item.name} />
                ))}
              </Select>
              
                      <Input
                      style={styles.input}
                      value={limitParticipation}
                      placeholder='Maximun Participant'
                      onChangeText={nextValue => setLimitParticipation(nextValue.replace(/[^0-9]/, ''))}
                    />
                    </Layout>
          
                 
          
       

        
        </View>
        </View>

        <Modal animationType="slide" visible={calendarModal}>
        
        <View style={{ padding: "2%" }}>
        
      </View>

          
          <View style={styles.containerPermanence}>
          
            

            <View
            style={{
              flexDirection: "row",
             
            
            }}
          >
            <View style={{ width: "20%" }}>
              <Ionicons
                onPress={cancelCalendar} 
                style={{ marginLeft: 10, marginTop: -5 }}
                name="arrow-back-circle-sharp"
                color={theme.colors.lightBlue}
                size={30}
              />
            </View>

            <View style={{ width: "60%" }}>
              <Text   style={{ fontSize:25,color:theme.colors.lightBlue }}>
                
                {" Définir les Horaires séléctionnables"}
              </Text>
            </View>

           
            <View style={{ width: "10%" }}>
          <TouchableOpacity 
    style={styles.paypalButton}
     onPress={openConfirmDate}
  
  >
    <Text style={styles.paypalButtonText}>Valider</Text>
  </TouchableOpacity>
          </View>
          </View>
          <View
            style={{
              borderBottomColor: "grey",
              borderBottomWidth: 0.5,
              
            }}
          />





            <View style={{height:900}}>
              <FlatList
                data={ArrayDay}
                renderItem={renderItemDay}
                keyExtractor={(item, index) => index.toString()}
                showsHorizontalScrollIndicator={true}
                contentContainerStyle={{ paddingBottom: 350 }}
              />
            </View>
          </View>
        </Modal>

        <Modal animationType="slide" visible={openConfirmDateModal}>

        <Layout  level='1'>
        <TopNavigation
          alignment='center'
          title='Fin de la Permanencee'
          
          accessoryLeft={
            
            <TouchableOpacity   onPress={()=>setOpenConfirmDateModal(false)} > 
            <Ionicons name="arrow-back"
          color={"#4e32a8"}
          size={35} />
  
          </TouchableOpacity>
        }
          accessoryRight={ <Button
          
            onPress={confirmDate}
            
          >
            <Text
              
            >
              Valider
            </Text>
          </Button>}
        />
      </Layout>
      

            <Text
            style={{ alignSelf: "center",marginVertical:"10%" }}
            
            color={theme.colors.black} 
          >
            Choisissez la date de fin de la permanence
          </Text>



          <View style={{alignSelf:"center"}}>
            <View>
            <Button  onPress={showDatepicker} color={theme.colors.lightBlue} style={styles.DateButton}>
                  <Text bold color={argonTheme.COLORS.WHITE}>
                  {moment(date)
            .format('dddd')
            .toLocaleUpperCase()}{' '}
            {moment(date)
              .format('DD')
              .toLocaleUpperCase()}{' '}
             {moment(date)
                  .format('MMMM')
                  .toLocaleUpperCase()}
                  {' '}
             {moment(date)
                  .format('YYYY')
                  .toLocaleUpperCase()}
                  </Text>
                </Button>
            </View>
        
       
          <DatePickerModal
          locale="fr"
          mode="single"
          visible={open}
          onDismiss={onDismissSingle}
          date={date}
          onConfirm={onConfirmSingle}
        />
      
        
        </View>
      

      
      
       
        
        </Modal>
      
        <Modal animationType="slide" visible={presetModal}>
        <View style={styles.centeredView}>
          
          
          <Layout  level='1'>
          <TopNavigation
            alignment='center'
            title='Choisir une Image'
            
            accessoryLeft={
              
              <TouchableOpacity   onPress={cancelImage} > 
              <Ionicons name="arrow-back"
            color={"#4e32a8"}
            size={35} />
    
            </TouchableOpacity>
          }
            accessoryRight={ <Button
            
              onPress={() => {
                setPresetModal(false);
              }}
            >
              <Text
                bold
                onPress={validImage}
                size={14}
               
              >
                Valider
              </Text>
            </Button>}
          />
        </Layout>
    
         <Divider></Divider>
         <View style={{flex:1}}>
         <View style={{height: 1000}}>
          <FlatList
          contentContainerStyle={{flexGrow: 1}}
            numColumns={6}
            extraData={imagePreset}
            data={imagePreset}
            scrollEnabled={true}
            renderItem={renderItemPreset}        
            keyExtractor={(item) => item.id}
          />
          </View>
          </View>
        </View>
      </Modal>

      

        <Modal
          animationType={"slide"}
          transparent={true}
          visible={deletePoleModal}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.8)",
            }}
          >
            <View style={styles.styleModal}>
              <View style={{ margin: 0, padding: 0 }}>
                <Button
                  onlyIcon
                  icon='close-circle-sharp'
                  iconFamily='ionicon'
                  iconSize={22}
                  iconColor={"#0f0c29"}
                  color='transparent'
                  onPress={hideDeletePoleModal}
                />
              </View>

              <View style={styles.modalStyle}>
                <View style={styles.socialTitle}>
                  <Text
                    bold
                    style={{ marginBottom: "10%" }}
                    color='#172B4D'
                    size={18}
                  >
                   Supprimer cette permanence ?
                      
                 
                   
                  </Text>
                  <Text bold color='#172B4D' size={14}>
                    

                     
                      {"Attention si vous supprimer cette permanence toutes les informations qu'elle contient seront égalements supprimés"}
                    
                     
                  </Text>
                </View>
                <View
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Button
                    onPress={DeletePermanenceHandler}
                    loading={buttonLoading} 
                    color='#172B4D'
                    style={styles.createButtonDelete}
                  >
                    <Text bold size={14} color={"#fff"}>
                      Supprimer
                    </Text>
                  </Button>
                </View>
              </View>
            </View>
          </View>
        </Modal>

    

        <View>
      
          
             
            <View style={styles.contentContainer}>

             
            <View> 
            <TouchableOpacity onPress={() => setCalendarModal(true)}>
            <View style={{ flexDirection: "row"}}>
              <View
                style={{
                  width: "12%",
                  alignItems: "flex-start",
                  marginVertical:"1%"
                }}
              >
              <Feather name="calendar" color={"#b57704"} size={22} />
              </View>
              <View
                style={{
                  width: "50%",
                  alignItems: "flex-start",
                  marginVertical:"1%"
                  
                }}
              >
                <Text h4 color='#302b63'>
                Plage Horaires
                </Text>
              </View>


              <View>{ (timeActivity !== "") ? <View
                style={{
                  
                  width:'10%',marginHorizontal:"7%"
                   
                }}>
                <Image
                style={{width: 30,
                  height: 30,
                  resizeMode: 'stretch'}}
                  source={ {uri: "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/approved.png" }}
              />        
              </View>: <View
              style={{
                
                marginTop:"10%",justifyContent:"center",marginRight:"17%"
              }}><Text h6 color="#969595" size={12}>
              ( Optionnel )
              </Text>
              </View>
                }
        
            
            </View>
            </View>
          </TouchableOpacity>
              
          <View style={{ marginTop: "1%" }}>
          <Divider />
        </View> 
        </View>
           

              <View
                style={{
                  flexDirection: "row",
                  paddingTop: "1%",
                  paddingBottom: "1%",
                }}
              >
                <View
                  style={{
                    width: "12%",

                    paddingTop: "1%",
                  }}
                >
                  <View>
                    <Ionicons name='ios-image' color={"#036963"} size={22} />
                  </View>
                </View>

                <View
                  style={{
                    width: "65%",

                    paddingTop: "1%",
                    paddingRight: 15,
                  }}
                >
                  <TouchableOpacity onPress={() => setPresetModal(true)}>
                    <View>
                      <Text h4 color='#302b63' size={12}>
                        Utiliser Image Prédéfinie
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    width: "10%",
                  }}
                >
                  <View>
                    <Image
                    style={{ width: 65, height: 50, resizeMode: "stretch" }}
                    source={image !== "" ? { uri: image } : null}
                  />
                  </View>
                </View>
              </View>

              <Divider />

          
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: "1%",
                  paddingBottom: "1%",
                }}
              >
                <View
                  style={{
                    width: "12%",
                    paddingTop: "1%",
                  }}
                >
                  <Feather
                    name="check-square"
                    color={"#6b0409"}
                    size={22}
                  />
                </View>
                <View
                  style={{
                    width: "60%",
                    paddingTop: "1%",
                  }}
                >
                  <Text h4 color="#302b63">
                   RDV Ouvert aux Usagers
                  </Text>
                </View>

              

                <Toggle
                style={styles.toggle}
                checked={openAppointementUser}
                onChange={(value) => setOpenAppointementUser(value)}>
                Active
              </Toggle>
                  
            

              </View>
              <Text>
              La prise de rendez-vous sera ouverte aux usagers, dans le cas échéant les rendez-vous seront uniquement pris et géré par les administrateurs.
            </Text>

              <View style={{ marginTop: "1%" }}>
                <Divider />
              </View>

              <TouchableOpacity onPress={showDeletePoleModal}>
                <View
                  style={{
                    flexDirection: "row",
                    paddingTop: "1%",
                    paddingBottom: "1%",
                  }}
                >
                  <View
                    style={{
                      width: "12%",
                      paddingTop: "1%",
                    }}
                  >
                    <Feather name='trash' color={"#b57704"} size={22} />
                  </View>
                  <View
                    style={{
                      width: "70%",
                      paddingTop: "1%",
                    }}
                  >
                    <Text h4 color='#302b63'>
                     {"Supprimer la Permanence"}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>

          
              

            
            </View>
            
         
         
        </View>



  
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
    backgroundColor: "white",
  },
  modalStyle: {
    backgroundColor: "#fff",
    height: "50%",
    width: "85%",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 20,
  },
  paypalButton: {
    backgroundColor: '#003087',
    padding: 10,
    alignItems: 'center',
    borderRadius: 5
  },
  paypalButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },

  styleModal: {
    backgroundColor: "#fff",
    height: "50%",
    width: "90%",

    borderRadius: 70,
  },
  scrollView: {
    height: height - 500,
    paddingTop: "2%",
  },
  
  createButtonAddPole: {
    width: width * 0.3,
    marginTop: theme.sizes.width * 0.07,
    alignSelf: "center",
  },
  errorInput: {},
  contentContainer: {
    padding: theme.sizes.width * 0.04,
    fontSize: 15,
    marginBottom: 100,
  },
  contentFont: {
    fontSize: 20,
  },
  Container: {
    
    backgroundColor: "#FFF",
    borderRadius: 4,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 0.1,
    elevation: 1,
    overflow: "hidden",
  },
  socialConnect: {
    backgroundColor: argonTheme.COLORS.WHITE,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#8898AA",
  },

  socialTerme: {
    paddingTop: "5%",
  },
  socialTitle: {
    paddingBottom: "5%",
  },
  socialButtons: {
    width: 120,
    height: 30,
    backgroundColor: "#fff",
    shadowColor: argonTheme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 0.1,
    elevation: 1,
  },
  socialTextButtons: {
    color: "#0f0c29",
    fontWeight: "800",
    fontSize: 14,
  },
  inputIcons: {
    marginRight: 12,
  },

  createButton: {
    width: width * 0.2,
    marginTop: 25,
  },
  createButtonDelete:{
    width: width * 0.4,
    marginTop: 25,

  },
  DateButton: {
    width: theme.sizes.width * 0.6,
    marginTop: theme.sizes.width * 0.04,
    borderRadius:20
  },
  contain: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    
  },
  chip: {
    marginTop: 10,
    height:40
  },
  containerPermanence: {
    backgroundColor: "#f5f5f5",
    flex: 1,
  },
  hourUnselected: {
    borderRadius: 5,
    margin: 10,

    backgroundColor: "#e6eced",
    minWidth: 80,
    minHeight: 45,
    justifyContent: "center",
  },

  hourSelected: {
    borderRadius: 5,
    margin: 10,

    backgroundColor: theme.colors.lightBlue,
    minWidth: 80,
    minHeight: 45,
    justifyContent: "center",
  },

  text: { alignSelf: "center" },
  unselected: { color: "white" },
  selected: { color: theme.colors.lightBlue },

  card: {
    backgroundColor: theme.colors.white,

    flex: 1,
    margin: "5%",
    borderRadius: 10,
  },
});

const stylesPreset = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 20,
    marginBottom: 20,
  },
  item: {
    padding: 1,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    marginTop: 2,
    marginHorizontal: 2,

    elevation: 5,
  },
  image: {
    width: theme.sizes.width * 0.45,
    height: 115,
  },
  text: {
    color: "#0f0c29",
    fontWeight: "bold",
  },
  
  indicator: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});



export default EditPermanence;

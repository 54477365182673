import React, { useState  } from 'react';


const ManageContext = React.createContext();

const ManageProvider = (props) => {

  const [updatePole, setUpdatePole] = useState("false");
  const [updateManageUser, setUpdateManageUser] = useState("false");
  const [updateStory, setUpdateStory] = useState("false");
  const [updateCapture, setUpdateCapture] = useState("false");
  const [updateEvent, setUpdateEvent] = useState("false");
  const [updateInfosStructure, setUpdateInfosStructure] = useState("false");
  const [updateProject, setUpdateProject] = useState("false");
  const [updateAvatar, setUpdateAvatar] = useState("https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/profileDefault.png");
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [updateManager, setUpdateManager] = useState(false);
  const [updateIsHeManager, setUpdateIsHeManager] = useState(false);
  const [updateIsHeSuperAdmin, setUpdateIsHeSuperAdmin] = useState(false);
  const [updateRole, setUpdateRole] = useState(false);
  const [idUser, setIdUser] = useState("0");
  const [idStructure, setIdStructure] = useState("0");
  const [userStructure, setUserStructure] = useState("0");
  const [userProfil, setUserProfil] = useState(false);
  const [updatePermanence, setUpdatePermanence] = useState("false");
  const [updateHoliday, setUpdateHoliday] = useState("false");
  const [updateActivityHoliday, setUpdateActivityHoliday] = useState("false");
  const [updateManageSubscriber, setUpdateManageSubscriber] = useState("false");
  const [expoPushToken, setExpoPushToken] = useState("");
  const [nameCentre, setNameCentre] = useState("");



  return (
    <ManageContext.Provider
      value={{
        updatePole,
        setUpdatePole,
        updateStory,
        setUpdateStory,
        updateCapture,
        setUpdateCapture,
        updateEvent,
        setUpdateEvent,
        updateAvatar,
        setUpdateAvatar,
        updateRole,
        setUpdateRole,
        updateManageUser,
        setUpdateManageUser,
        idUser,
        setIdUser,
        idStructure,
        setIdStructure,
        userStructure,
        setUserStructure,
        updatePermanence, 
        setUpdatePermanence,
        updateHoliday,
        setUpdateHoliday,
        nameCentre, 
        setNameCentre,
        updateActivityHoliday,
        setUpdateActivityHoliday,
        updateManageSubscriber, 
        expoPushToken, 
        setExpoPushToken,
        setUpdateManageSubscriber,
        userProfil, 
        setUserProfil,
        lastname,
        setLastname,
        firstname,
        setFirstname,
        updateInfosStructure,
        setUpdateInfosStructure,
        updateProject,
        setUpdateProject,
        updateManager,
        setUpdateManager,
        updateIsHeManager,
        setUpdateIsHeManager,
        updateIsHeSuperAdmin,
        setUpdateIsHeSuperAdmin,

     
      }}
    >
      {props.children}
    </ManageContext.Provider>
  )
}

export { ManageProvider, ManageContext };
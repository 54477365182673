// local imgs
const Onboarding = require("../assets/images/bg.png");



const RegisterBackground = require("../assets/images/register-bg.png");
const LoginBackground = require("../assets/images/bg.png");



const ProfilePicture = 'https://images.unsplash.com/photo-1492633423870-43d1cd2775eb?fit=crop&w=1650&q=80';



export default {
  Onboarding,
  ProfilePicture,
  RegisterBackground,

  LoginBackground
};


export const fetchListPermanence = idStructure => {

    return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/permanences/listPermanence", {
         method: "POST",
         headers: {
             "Content-Type": "application/json"
         },
          body: JSON.stringify({
             idStructure
         
          })
     }).then((response) => response.json())
     .then((json) => {
       
 
       return json;
     }).catch((err) => {
         console.log("dataProjectActions.js, Request Error: ", err.message);
      
     });
   
 
 }
 
 
 
 
export const fetchListPole = poleData => {


   return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/poles/listPoles", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
         body: JSON.stringify(poleData)
    }).then((response) => response.json())
    .then((json) => {
     
      return json;
    }).catch((err) => {
        console.log("poleActions.js, Signup Request Error: ", err.message);
     
    });
  

}





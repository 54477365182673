import React, { useState, useEffect, useContext } from "react";
import { useFocusEffect } from '@react-navigation/native';
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  TextInput,
  Platform,
  StatusBar,
  Text,

  Modal,
  Button
  

} from "react-native";


import { theme } from "../constants";
import { fetchListCentre } from "../actions/ListCenterAction";
import { storeData } from "../storage/manageStorage";
import { getData } from "../storage/manageStorage";

import * as Location from "expo-location";

import { ManageContext } from "../provider/manageStateProvider";

import { FlatList } from "react-native-gesture-handler";


import allStructure from '../constants/structures'

import dataLocalisation from '../constants/structures'

export default function Welcome({ route, navigation }) {


  const ManageUser = useContext(ManageContext);
  const [data, setData] = useState();
  const [query, setQuery] = useState("");
  const [bookedStructure, setBookedStructure] = useState("");
  const [fullData, setFullData] = useState();
  const [cp, setCp] = useState();
  const [statusCp, setStatusCp] = useState(1);
  const [updateCpStatus, setUpdateCpStatus] = useState(false);
  const [bookmarkModal, setBookmarkModal] = useState(false);
  const [statusGeocalisation, setStatusGeocalisation] = useState();
  const [bookmarkData, setBookmarkData] = useState([]);
  const [town, setTown] = useState("")


  useEffect(() => {



 
    (async () => {

 

      const booked = await getData('bookedStructure')

      const idFav = await getData('idBookedStructure')


      setBookedStructure(idFav)


      setStatusGeocalisation(false)
    

        const fetchList = async () => {

   
          if(booked !== undefined ){
            const allStructureSaved = await getData('allStructure')

            const ListCentre =  await fetchListCentre(93170);

           // const ListCentre = allStructureSaved !== undefined ? await JSON.parse(allStructureSaved) : allStructure.allStructure;

            if(ListCentre[0].town !== undefined){
              setTown(ListCentre[0].town)
            }else{
              setTown("la ville")
      
            } 

            setFullData(allStructure.allStructure);
            setData(dataLocalisation.dataLocalisation);

            storeData("allStructure",JSON.stringify(ListCentre))

          }else{
            
            
            const ListCentre =  await fetchListCentre(93170);

            //console.log(await fetchListCentre(93170))

        /*      if(ListCentre.dataLocalisation[0].town !== undefined){
              setTown(dataLocalisation[0].town)
            }else{
              setTown("la ville")
      
            } 
             */
     
            setFullData(ListCentre.allStructure);
            setData(ListCentre.dataLocalisation);

            storeData("allStructure",JSON.stringify(allStructure))

           
          }
         
  

       
        };
  
   
        fetchList();


/* if(Platform.OS=="web"){ 



      let { status } = await Location.requestForegroundPermissionsAsync();

      
      if (status !== "granted") {
        setStatusGeocalisation(false)
    

        const fetchList = async () => {

       
  
          if(booked !== undefined ){
            const allStructure = await getData('allStructure')

         
            
            const ListCentre = allStructure !== undefined ? await JSON.parse(allStructure) : await fetchListCentre(93170);

            if(ListCentre.dataLocalisation[0].town !== undefined){
              setTown(ListCentre.dataLocalisation[0].town)
            }else{
              setTown("la ville")
      
            }
    
            console.log(ListCentre.dataLocalisation)
            setFullData(ListCentre.allStructure);
            setData(ListCentre.dataLocalisation);
            storeData("allStructure",JSON.stringify(ListCentre))
          }else{

            console.log(ListCentre.dataLocalisation)

            const ListCentre = await fetchListCentre(93170);
            if(ListCentre.dataLocalisation[0].town !== undefined){
              setTown(ListCentre.dataLocalisation[0].town)
            }else{
              setTown("la ville")
      
            }
    
           
            setFullData(ListCentre.allStructure);
            setData(ListCentre.dataLocalisation);

            storeData("allStructure",JSON.stringify(ListCentre))
          }
         
  


       
        };
  
   
          fetchList();
           
      }else{  

        
        setStatusGeocalisation(true)
      
      


      
       await Location.getCurrentPositionAsync({
        accuracy: Location.Accuracy.low,
        maximumAge: 10000,
        timeout: 4000
      })
      .then(async (res) => {
       
        let dataLocation = await Location.reverseGeocodeAsync({
          latitude: res.coords.latitude,
          longitude: res.coords.longitude,
        });

        const fetchList = async () => {

       
          setCp(dataLocation[0].postalCode)
      
  
           // on stoke les donnée pour eviter de les reload lorsque que l'on a un favori patch a corrigé !
  
          if(booked !== undefined ){
            const allStructure = await getData('allStructure')
  
         
            const ListCentre = allStructure !== undefined ? await JSON.parse(allStructure) : await fetchListCentre(93170);
  
  
            if(ListCentre.dataLocalisation[0].town !== undefined){
              setTown(ListCentre.dataLocalisation[0].town)
            }else{
              setTown("la ville")
      
            }
    
            console.log(ListCentre.dataLocalisation)
            setFullData(ListCentre.allStructure);
            setData(ListCentre.dataLocalisation);
            if (booked !== undefined){
       
  
              storeData("allStructure",JSON.stringify(ListCentre))
              
            }
          }else{
  
            const ListCentre = await fetchListCentre(dataLocation[0].postalCode);
            if(ListCentre.dataLocalisation[0].town !== undefined){
              setTown(ListCentre.dataLocalisation[0].town)
            }else{
              setTown("la ville")
      
            }
            console.log(ListCentre.dataLocalisation,"242")
           
            setFullData(ListCentre.allStructure);
            setData(ListCentre.dataLocalisation);
            if (booked !== undefined){
       
  
              storeData("allStructure",JSON.stringify(ListCentre))
              
            }
          } 
         
  
        };
  
   
          fetchList();
        

        
       } )
      .catch(async (e) => {

    
        
        const fetchList = async () => {
    
    
          // on stoke les donnée pour eviter de les reload lorsque que l'on a un favori patch a corrigé !
     
     
         
          if(booked !== undefined ){
           const allStructure = await getData('allStructure')
     
     
           
           const ListCentre = allStructure !== undefined ? await JSON.parse(allStructure) : await fetchListCentre(93170);
     
           if(ListCentre.dataLocalisation[0].town !== undefined){
             setTown(ListCentre.dataLocalisation[0].town)
           }else{
             setTown("la ville")
     
           }
     
         
           setFullData(ListCentre.allStructure);
           setData(ListCentre.dataLocalisation);
           storeData("allStructure",JSON.stringify(ListCentre))
         }else{
     
           const ListCentre = await fetchListCentre(93170);
           if(ListCentre.dataLocalisation[0].town !== undefined){
             setTown(ListCentre.dataLocalisation[0].town)
           }else{
             setTown("la ville")
     
           }
     
           console.log(ListCentre.dataLocalisation,"302")
           setFullData(ListCentre.allStructure);
           setData(ListCentre.dataLocalisation);
     
           storeData("allStructure",JSON.stringify(ListCentre))
         }
        
     
     
     
     
     
     
               };
         
          
                 fetchList();




      })



      
      
        }

      
      
      }
 */

    

     


     
  
    
    })();

  }, []);


 


  const listDefault = async()=>{
    
    const booked = await getData('bookedStructure')
        const fetchList = async () => {
  
          if(booked !== undefined ){
            const allStructure = await getData('allStructure')

           
            
            const ListCentre = allStructure !== undefined ? await JSON.parse(allStructure) : await fetchListCentre(93170);

            if(ListCentre.dataLocalisation[0].town !== undefined){
              setTown(ListCentre.dataLocalisation[0].town)
            }else{
              setTown("la ville")
      
            }
    
           
            setFullData(ListCentre.allStructure);
            setData(ListCentre.dataLocalisation);
            storeData("allStructure",JSON.stringify(ListCentre))
          }else{

            const ListCentre = await fetchListCentre(93170);
            if(ListCentre.dataLocalisation[0].town !== undefined){
              setTown(ListCentre.dataLocalisation[0].town)
            }else{
              setTown("la ville")
      
            }
    
           
            setFullData(ListCentre.allStructure);
            setData(ListCentre.dataLocalisation);

            storeData("allStructure",JSON.stringify(ListCentre))
          }
         
  

       
       
        };
  
   
          fetchList();
  }
 



  const geocalizeMe = async () =>{

    setData(undefined);
 
   if(Platform.OS=="web"){ 
     
    const booked = await getData('bookedStructure')
    
let { status } = await Location.requestForegroundPermissionsAsync();
        
if (status !== "granted") { }
else{  

            setStatusGeocalisation(true)
   
            const location = await Location.getCurrentPositionAsync({ accuracy: Platform.os == "web" ? Location.Accuracy.Low : Location.Accuracy.Lowest, })
    
          let dataLocation = await Location.reverseGeocodeAsync({
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
          });

          const fetchList = async () => {
    
            setCp(dataLocation[0].postalCode)
    
            const ListCentre = await fetchListCentre(dataLocation[0].postalCode);
              if(ListCentre.dataLocalisation[0].town !== undefined){
                setTown(ListCentre.dataLocalisation[0].town)
              }else{
                setTown("la ville")
        
              }
                 
              setFullData(ListCentre.allStructure);
              setData(ListCentre.dataLocalisation);
              if (booked !== undefined){
             
                storeData("allStructure",JSON.stringify(ListCentre))
                
              }
            }
    
            fetchList();
          
          }
  
          }
  }




  const handleSearch = (text) => {
    if(fullData !== undefined){
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the Data
      // Update FullData
      const newData = fullData.filter(
        function (item) {
          const itemData = item.title 
            ? item.titleHeader.toUpperCase() + item.town.toUpperCase() 
            : ''.toUpperCase();
          const textData = text.toUpperCase()
          return itemData.indexOf(textData) > -1;
      });
      setData(newData);
      setQuery(text);
    } else {
      // Inserted text is blank
      // Update Data with FullData
      setData(data.slice(0, 10));
      setQuery(text);
      
    }
  }
  };




  const goHome = async (
    publish,
    town,
    idCentre,
    nameCentre,
    socialProjectLink,
    cp
    
  ) => {


 

    await storeData("idC", idCentre);
   
    const lastname = await getData("lastname");
    const firstname = await getData("firstname");
    const avatar = await getData("photoUser");

    await storeData("nameCentre",nameCentre)

    avatar == undefined || avatar == null
      ? ManageUser.setUpdateAvatar("https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/profileDefault.png")
      : ManageUser.setUpdateAvatar(avatar);
    firstname !== null
      ? ManageUser.setFirstname(firstname)
      : ManageUser.setFirstname("");
    lastname !== null
      ? ManageUser.setLastname(lastname)
      : ManageUser.setLastname("");


     ManageUser.setUpdateManager(false);

     ManageUser.setNameCentre(nameCentre);
    


    navigation.navigate("Home", {
      
      
        publish: publish,
        town: town,
        idCentre: idCentre,
        nameCentre: nameCentre,
        socialProjectLink: socialProjectLink,
        postalCode: cp,
        
        
      
    });





  };




  const addToBookmark = ()=>{



    storeData("idBookedStructure",bookmarkData._id)
    
    setBookedStructure(bookmarkData._id)
    setBookmarkModal(false)
  

    storeData("bookedStructure",JSON.stringify(bookmarkData))

  }


  const removeToBookmark = ()=>{


// l'un des deux doit disparaitre storeData("bookedStructure","")   storeData("idBookedStructure","")
    
    storeData("idBookedStructure","")
    storeData("bookedStructure","")
    
    setBookedStructure("")
    setBookmarkModal(false)



  }




  const renderItemCenter = ({ item }) => {



    return (
      <View >
        <TouchableOpacity
          onPress={() =>
            goHome(
              item.publish,
              item.town,
              item._id,
              item.titleHeader,
              item.socialProjectLink,
              item.cp

            )
          }
        >
          <View style={stylesBar.listItem}>
            <View style={stylesBar.BorderDouble}>
              <Image
                source={{ uri: item.poster }}
                style={stylesBar.coverImage}
              />
            </View>
            <View style={stylesBar.metaInfo}>
              <Text>
                {item.titleHeader}
              </Text>
              <View style={{ flexDirection: "row" }}>
                <View style={{ marginVertical: "2%", width: "75%" }}>
                  <Text    style={{ color:"#C5CCD6"}}>
                    {item.town} {" "} {item.cp}
                  </Text>
                </View>

             {

              item._id == bookedStructure ? 
            
              <TouchableOpacity  onPress={() =>{
                setBookmarkModal(true),setBookmarkData(item)}
              }  style={{ top:-10,marginVertical: "2%", width: "35%" }}>
       
              <Image
              style={{ 
                width: theme.sizes.width * 0.07,
                height: theme.sizes.width * 0.07,
                borderRadius: 8,
                borderColor: "transparent",
                borderWidth: 8,
                margin: theme.sizes.width * 0.006,}}
              source={require('../assets/images/booked.png')}
            
            />
        
              </TouchableOpacity>:
         
              <TouchableOpacity  onPress={() =>{
                setBookmarkModal(true);setBookmarkData(item)}
              }  style={{ top:-10,marginVertical: "2%", width: "35%" }}>
       
              <Image
              style={{ 
                width: theme.sizes.width * 0.03,
                height: theme.sizes.width * 0.03,
                borderRadius: 8,
                borderColor: "transparent",
                borderWidth: 8,
                margin: theme.sizes.width * 0.006,}}
              source={require('../assets/images/bookmark-outlined.png')}
            
            />
        
              </TouchableOpacity>
             }
                
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  };





  return ( 
    <View style={{backgroundColor:"white",height:1200}}>



   <View style={stylesBar.container}>
    {Platform.OS == "web" ? (
      <StatusBar
        barStyle="dark-content"
        translucent={false}
        animation={true}
        backgroundColor="white"
      />
    ) : null}
      <View>
        <Text  style={{fontSize:40,marginTop:40,alignSelf:"center",  color:"black" }}>
          Mon Centre
          <Text   style={{ color:"#00BCFF" }}>
            {" "}
            Social.
          </Text>
        </Text>
        <Text
        
          
          style={{ fontSize:20,alignSelf:"center", color:"#C5CCD6",marginTop: theme.sizes.padding / 2.5 }}
        >
          De nombreuses activités vous attendent.
        </Text>
   

  
  
      </View>



      <View
        style={{
          padding: theme.sizes.width * 0.01,
          marginVertical: theme.sizes.width * 0.003,
          borderRadius: 20,
        }}
      >
        <TextInput
          status="info"
          autoCapitalize="none"
          autoCorrect={false}
          clearButtonMode="always"
          value={query}
          onChangeText={handleSearch}
          placeholder={" Taper le nom de la ville ou le nom de la structure"}
          placeholderTextColor="#d9d2d2"
         
          style={{
            borderRadius: 25,
            borderColor: "#333",
            backgroundColor: "#f5f5f5",
            padding: theme.sizes.width * 0.005,
            paddingLeft: theme.sizes.width * 0.04,
            width:"30%",
            fontSize:20,alignSelf:"center", 
            color: "#454141",
          }}
          textStyle={{ color: "#FFF" }}
        />
      </View>


      <View>
      <Modal
animationType="slide"
transparent={true}
visible={bookmarkModal}
>
<View
  style={{
    width: "80%",
    height: "60%",
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 20,
    borderStyle: "solid",
    backgroundColor: "white",
    elevation: 50,
    marginVertical: "40%",
    marginLeft: "10%",
    borderRadius: 4,
    justifyContent: "center",
  }}
>
{ bookedStructure == bookmarkData._id ?

  <View style={{ margin: theme.sizes.padding / 1 }}>
    <Text  style={{    color:"#232043", margin: theme.sizes.padding / 2.5 }}>
    Retirer un favori
     
    </Text>

   
    <Text
      
      
      style={{     color:"#232043", margin: theme.sizes.padding / 2.5 }}
     
    >
    {"La stucture "}
    
    {bookmarkData.titleHeader}
    
    {" ne sera plus votre stucture par défault, lors de votre prochaine connexion vous serez renvoyés au menu listant l'ensemble des structures."}
    
    </Text>

   

    <Button style={{backgroundColor:theme.colors.lightBlue,marginVertical:"5%"}} onPress={()=>removeToBookmark()}  mode="contained">
    <Text
    style={{ padding: "5%" }}
    
    
  >
    Retirer
  </Text>
  </Button>
  <Button style={{backgroundColor:theme.colors.BLACK}} onPress={()=>setBookmarkModal(false)}  mode="contained">
    <Text
    style={{ padding: "5%" }}
  
    
  >
    Annuler
  </Text>
  </Button>
  </View>:
  <View  style={{ margin: theme.sizes.padding / 1 }}>
    <Text  style={{ color:"#232043",margin: theme.sizes.padding / 2.5 }}>
    Ajouter un favori
     
    </Text>

   
    <Text
     
      
      style={{     color:"#232043",margin: theme.sizes.padding / 2.5 }}
     
    >
    {"En cliquant sur Ajouter, vous selectionnerez cette structure par défaut et y accéderez directement lors de votre prochaine connexion. Il vous sera possible de revenir au menu listant l'ensemble des structures en passant par Mon Compte / Changer de structure."}
    
    </Text>

   

    <Button style={{backgroundColor:theme.colors.lightBlue,marginVertical:"5%"}} onPress={()=>addToBookmark()}  mode="contained">
    <Text
    style={{ padding: "5%" }}
    
    
  >
    Ajouter
  </Text>
  </Button>
  <Button style={{backgroundColor:theme.colors.BLACK}} onPress={()=>setBookmarkModal(false)}  mode="contained">
    <Text
    style={{ padding: "5%" }}
  
    
  >
    Annuler
  </Text>
  </Button>
  </View>

}
  
</View>
</Modal>
</View>
  
      {
        statusCp == 0  ? <Text
        
        style={{ color:"black",margin: theme.sizes.padding / 1 }}>{"Veuillez activer la localisation dans les paramètres de votre appareil puis relancer l'application "}
      </Text> : data ==  undefined  ?
 <View><View>
     
    </View>
    
    <View>
 
    </View>
    
    
    </View>
    
    
    : <FlatList
        style={stylesBar.modal}
        data={data}
        keyExtractor={(item) => item._id}
        renderItem={renderItemCenter}
      /> 

      }
   
    </View> </View>
   
  );
}

const stylesBar = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    height: "100%",
  },

  buttonTermes: {
    alignItems: "center",
    backgroundColor: "transparent",
  },
  termes: {
    fontSize: theme.sizes.width * 0.03,
    color: "#dcd7e0",
    backgroundColor: "transparent",
  },

  text: {
    fontSize: theme.sizes.width * 0.06,
    marginTop: theme.sizes.width * 0.17,
    fontWeight: "700",
  },
  listItem: {
    marginTop: theme.sizes.width * 0.004,
    marginHorizontal:"35%",

    backgroundColor: "#ffffff",
    flexDirection: "row",
   
  },
  coverImage: {
    width: theme.sizes.width * 0.05,
    height: theme.sizes.width * 0.05,
    justifyContent:"center",
    borderRadius: 8,
    borderColor: "transparent",
   
    
    resizeMode: 'cover',
  },
  BorderDouble: {
    borderRadius: 10,
    borderColor: "rgba(49, 27, 146, 0.2)",
    borderWidth: 3,
    width: theme.sizes.width * 0.053,
    height: theme.sizes.width * 0.053,
  },
  metaInfo: {
    justifyContent: "center",
    marginHorizontal: "5%",
  },
  title: {
    fontSize: 40,
    width: 200,
    padding: 10,
    color: "#0e1538",
    marginVertical: theme.sizes.width * 0.055,
  },
  modal: {
    backgroundColor: "#ffffff",
    flex: 1,
    paddingHorizontal: theme.sizes.width * 0.055,
  },
});

import React, {
    useState,
    useEffect,
    useRef,
    useContext,
  
  } from "react";
  import { useFocusEffect } from "@react-navigation/native";
  import {
    Dimensions,
    StyleSheet,
    TouchableOpacity,
    View,
    ImageBackground,
    Platform,
    SafeAreaView,
    Image,
    StatusBar,
    Modal,
    Animated,
    BackHandler,
    
    Text,
    FlatList
    
  } from "react-native";
  
  import Constants from 'expo-constants';
  
  import moment from "moment";
  import * as ImagePicker from "expo-image-picker";
  import { LinearGradient } from "expo-linear-gradient";
  import { ManageContext } from "../provider/manageStateProvider";
  import { MaterialIcons } from "@expo/vector-icons";
  import Octicons from "react-native-vector-icons/Octicons";

  import { theme } from "../constants";
  import { fetchListPost } from "../actions/post/ListPostAction";
  import { fetchRoleManager } from "../actions/authentification/CheckManagerAction";
  import { checkVersion } from "../actions/authentification/CheckVersionAction";
  import { fetchListStory } from "../actions/story/ListStory";
  import { AddContentStory } from "../actions/story/AddStory";
  import { DeleteContentStory } from "../actions/story/DeleteStory";
  import { DeletePostAction } from "../actions/post/DeletePostAction";
  import { fetchListPole } from "../actions/ListPoleAction";
  import { fetchListEvent } from "../actions/ListEventAction";
  import { fetchListPermanence } from "../actions/permanence/ListPermanenceAction";
  import { fetchListHoliday } from "../actions/holiday/ListHolidayAction";
  import { getData, storeData } from "../storage/manageStorage";

  import { Layout,Card,Button,TopNavigation,Divider  } from '@ui-kitten/components';
  
  import { TopNavigationAccessoriesShowcase } from "../components/Home/TopNavigator"; 

 

  import { Feather,Ionicons } from "@expo/vector-icons";

  
  const { width } = Dimensions.get("window");
  
  const SPACING = 12;
  
  const FULL_SIZE = theme.sizes.width * 0.42;
  
  export default function Home ({ route, navigation }) {
  
    const [sheetHandleIsOpen, setSheetHandleIsOpen] = useState(false);
  
    const handleHome = useContext(ManageContext);
  
    const [selectedIndex, setSelectedIndex] = React.useState(0);
  
    const flatListRef = useRef();
  
    const scrollX = useRef(new Animated.Value(0)).current;
  
    const idCentre = route.params.idCentre;
  
    const [listPost, setListPost] = useState([]);
    const [listPermanence, setListPermanence] = useState([]);
    const [listHoliday, setListHoliday] = useState([]);
    const [listPole, setListPole] = useState([]);
    const [listEvent, setListEvent] = useState([]);
    const [listStory, setListStory] = useState([]);
    const [listActivity, setListActivity] = useState([]);
    const [flatlistActivity, setFlatlistActivity] = useState([]);
    const [tabPole, setTabPole] = useState([]);
    const [selectedTab, setSelectedTab] = useState([]);
    const [index, setIndex] = useState(0);
    const [idPole, setIdPole] = useState("");
    const [welcomeModalAdmin, setWelcomeModalAdmin] = useState(false);
    const [storyModal, setStoryModal] = useState(false);
    const [deleteStoryModal, setDeleteStoryModal] = useState(false);
    const [deletePostModal, setDeletePostModal] = useState(false);
    const [storyContent, setStoryContent] = useState("");
    const [createStoryModal, setCreateStoryModal] = useState(false);
    const [fileName, setFileName] = useState(
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/640px-A_black_image.jpg"
    );
    const [buttonLoadingStory, setButtonLoadingStory] = useState(false);
    const [buttonLoadingDeleteStory, setButtonLoadingDeleteStory] =
      useState(false);
    const [buttonLoadingDeletePost, setButtonLoadingDeletePost] = useState(false);
    const [visibleWelcome, setVisibleWelcome] = useState(false);
    const [visibleWelcomeUser, setVisibleWelcomeUser] = useState(false);
    const [idPost, setIdPost] = useState("");
    const [preview, setPreview] = useState("");
  
    const [visible, setVisible] = useState(false);
    const [permaData, setPermaData] = useState(false);
    const [openHandlePole, setOpenHandlePole] = useState(false);

    const [deletePoleModal, setDeletePoleModal] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
   

    const flatListRefEvent = useRef(null);
    const [scrollIndexEvent, setScrollIndexEvent] = React.useState(0);
  
    const scrollToIndexEvent = (index) => {
      setScrollIndexEvent(index);
      flatListRefEvent.current.scrollToIndex({ animated: true, index });
    };
  
    const flatListRefHoliday = useRef(null);
    const [scrollIndexHoliday, setScrollIndexHoliday] = React.useState(0);
  
    const scrollToIndexHoliday = (index) => {
      setScrollIndexHoliday(index);
      flatListRefHoliday.current.scrollToIndex({ animated: true, index });
    };
    const flatListRefPermanence = useRef(null);
    const [scrollIndexPermanence, setScrollIndexPermanence] = React.useState(0);
  
    const scrollToIndexPermanence = (index) => {
      setScrollIndexPermanence(index);
      flatListRefPermanence.current.scrollToIndex({ animated: true, index });
    };

    const [scrollIndexActivity, setScrollIndexActivity] = React.useState(0);
  
    const scrollToIndexActivity = (index) => {
      setScrollIndexActivity(index);
      flatListRef.current.scrollToIndex({ animated: true, index });
    };
  
    const handleNextButtonPress = () => {
      const nextIndex = currentImageIndex + 1;
      if (nextIndex >= listPost.length) {
        setCurrentImageIndex(0);
      } else {
        setCurrentImageIndex(nextIndex);
      }
    };
    const goToAddPole = () =>{

      setOpenHandlePole(false)

      navigation.navigate("AddPole",{idCentre:idCentre})
       
    }
  
    const nextImg = async () => {
  
      var index = listStory.indexOf(storyContent);
     
     
  const length = listStory.length
  
  
  
  if(index >= length -1){
  
  setStoryContent(listStory[0])
  
  }else{
    setStoryContent(listStory[index+1])
  }
   
  };
  
  
    useFocusEffect(
      React.useCallback(() => {
        const onBackPress = () => {
          return true;
        };
  
        BackHandler.addEventListener("hardwareBackPress", onBackPress);
  
        return () =>
          BackHandler.removeEventListener("hardwareBackPress", onBackPress);
      }, [])
    );
  
    useEffect(() => {
      //Put your Data loading function here instead of my loadData()
      const fetchList = async () => {
        await getData("authToken")
          .then((data) => data)
          .then(async (value) => {
            const idUser = await getData("idUser");
            const expoNotificationToken = await getData("ExponentPushToken") !== undefined ?  await getData("ExponentPushToken") :"0"
  
          
            const tokenData = {
              authToken: value,
              idStructure: idCentre,
              expoNotificationToken:expoNotificationToken,
              idUser: idUser !== undefined ? idUser : 0,
             
            };
  
            
  
            const RoleManager = await fetchRoleManager(tokenData);
    
  
            handleHome.setIdStructure(idCentre);
  
  
  
            if (RoleManager.message == "checkIfmanage success") {
              const isManager = RoleManager.isManager;
  
              const isHeManager = RoleManager.isHeManager;
              const isHeSuperAdmin = RoleManager.isHeSuperAdmin;
  
              isManager == true
                ? handleHome.setUpdateManager(true)
                : handleHome.setUpdateManager(false);
  
              isHeManager == true
                ? handleHome.setUpdateIsHeManager(true)
                : handleHome.setUpdateIsHeManager(false);
  
              isHeSuperAdmin == true
                ? handleHome.setUpdateIsHeSuperAdmin(true)
                : handleHome.setUpdateIsHeSuperAdmin(false);
  
              handleHome.setIdUser(RoleManager.idUser);
              handleHome.setUserStructure(RoleManager.userStructure);
            } else {
              if (RoleManager.disconnectedUser == true) {
                storeData("isManager", "false");
  
                storeData("idUser", "");
  
                storeData("authToken", "");
                storeData("lastname", "");
                storeData("firstname", "");
                storeData("phoneNumber", "");
                storeData(
                  "photoUser",
                  "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/profileDefault.png"
                );
  
                handleHome.setFirstname("");
                handleHome.setLastname("");
                handleHome.setUpdateManager(false);
                handleHome.setIdUser("");
                handleHome.setUpdateAvatar(
                  "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/profileDefault.png"
                );
              }
  
              handleHome.setUpdateManager(false);
              handleHome.setUpdateIsHeManager(false);
              handleHome.setUpdateIsHeSuperAdmin(false);
            }
          });
      };
  
      fetchList();
    }, [handleHome.updateRole]);
  
  
    useEffect(() => {
      //Put your Data loading function here instead of my loadData()
      const fetchList = async () => {
    
  
            const updateApplication= await checkVersion(Constants.manifest.version);
            if(updateApplication.shouldUpdate == true){
  
  navigation.navigate("UpdateApp")
            }
  
            
          }
  
  
      fetchList();
    }, []);
  
  
    
    useEffect(() => {
      //Put your Data loading function here instead of my loadData()
      const fetchList = async () => {
        const poleData = {
          idCentre: idCentre,
        };
  
        const ListActivity = await fetchListPole(poleData);
  
        const ListPole = await fetchListPole(poleData);
        setListPole(ListPole.docs);
  
        if (ListActivity.docs.length !== 0) {
          setTabPole(ListActivity.tabPole);
  
          setListActivity(ListActivity.docs);
          setSelectedTab(ListActivity.tabPole[0]);
          setFlatlistActivity(ListActivity.docs[0].activity);
  
          setIdPole(ListActivity.docs[0]._id);
        } else {
          setTabPole([]);
  
          setListActivity([]);
          setSelectedTab([]);
          setFlatlistActivity([]);
  
          setIdPole([]);
  
        // setVisibleWelcome(true);
  
          if (handleHome.updateManager == false) {
           // setVisibleWelcomeUser(true);
          }
        }
      };
  
      fetchList();
    }, [handleHome.updatePole]);
  
    useEffect(() => {
      const fetchList = async () => {
        const structureData = {
          idCentre: idCentre,
          postalCode: '93100',
        };
  
        const listEvents = await fetchListEvent(structureData);
  
        if (listEvents.allEvent.length !== 0) {
          setListEvent(listEvents.allEvent);
        }
    
      };
  
      fetchList();
    }, [handleHome.updateEvent]);
  
    useEffect(() => {
      //Put your Data loading function here instead of my loadData()
      const fetchList = async () => {setStoryContent
        const ListPost = await fetchListPost(idCentre);
 
        setListPost(ListPost.docs.reverse());
      };
  
      fetchList();
    }, [handleHome.updateCapture]);
  
    useEffect(() => {
      //Put your Data loading function here instead of my loadData()
      const fetchList = async () => {
        const ListPermanence = await fetchListPermanence(
          idCentre
        );
  
        setListPermanence(ListPermanence.docs);
  
        setPermaData(true);
      };
  
      fetchList();
    }, [handleHome.updatePermanence]);
  

  
  
    useEffect(() => {
      //Put your Data loading function here instead of my loadData()
      const fetchList = async () => {
        const ListHoliday = await fetchListHoliday(
          idCentre
        );
  
        //setListHoliday(ListHoliday.docs);
  
  
        setListHoliday([ ...ListHoliday.docs, { key: 'empty-right' }]);
  
  
  
      };
  
      fetchList();
    }, [handleHome.updateHoliday]);
  
    useEffect(() => {
      //Put your Data loading function here instead of my loadData()
      const fetchList = async () => {
        const ListStory = await fetchListStory(
          idCentre
        );
  
        setListStory(ListStory.response.reverse());
      };
  
      fetchList();
    }, [handleHome.updateStory]);
  
    const switchTab = (tab) => {

      setScrollIndexActivity(0);
      setSelectedTab(tab);
  
      setIndex(index + 1);
  
      const index = tabPole.indexOf(tab);
  
     
      setIdPole(listActivity[index]._id);
  
      setFlatlistActivity(listActivity[index].activity);
     
  
      flatListRef.current.scrollToOffset({ animated: false, offset: 0 });
    };
  
    const gotoPoleActivity = (tab) => {
      navigation.navigate("PoleActivity", {
        activity: flatlistActivity,
        poleName: tab,
        idPole: idPole,
      });
     
    };
  
    const hideAdminModal = () => {
      // setWelcomeModalAdmin(false);
      setVisibleWelcome(false);
      bottomSheetHandle.current.expand();
      setSheetHandleIsOpen(true);
    };
  
    const hideUserModal = () => {
      setVisibleWelcomeUser(false);
    };
  
    const publishStory = async () => {
      setButtonLoadingStory(true);
  
      if (fileName.length !== 0) {
        const formData = new FormData();
  
        formData.append("idStructure", idCentre);
        formData.append("previewGallery", {
          name: "image.jpeg",
          type: "image/jpeg",
          uri: fileName
              
        });
  
        const response = await AddContentStory(formData);

     
  
        if (response.message == "Good") {
          handleHome.updateStory == "false"
            ? handleHome.setUpdateStory("true")
            : handleHome.setUpdateStory("false");
  
          setButtonLoadingStory(false);
          setCreateStoryModal(false);
        } else {
          setVisible(true);
          setButtonLoadingStory(false);
        }
      }
    };


    const handleActivityPosition =() =>{
      
      flatlistActivity.length > scrollIndexActivity + 2 ? scrollToIndexActivity(scrollIndexActivity+2) : scrollToIndexActivity(flatlistActivity.length -1)
    }
  
    const deleteStoryHandler = () => {
      setButtonLoadingDeleteStory(true);
  
      const formData = {
        idStructure: route.params.idCentre,
        imgUrl: storyContent,
      };
  
      DeleteContentStory(formData);
  
      setTimeout(() => {
        handleHome.updateStory == "false"
          ? handleHome.setUpdateStory("true")
          : handleHome.setUpdateStory("false");
  
        setDeleteStoryModal(false);
  
        setButtonLoadingDeleteStory(false);
  
        setStoryModal(false);
      }, 1000);
    };
  
    const deletePostHandler = async () => {
      setButtonLoadingDeletePost(true);
  
      const formData = {
        preview: preview,
        idPost: idPost,
      };
  
      const response = await DeletePostAction(formData);
  
      if (response.message == "Good") {
        handleHome.updateCapture == "false"
          ? handleHome.setUpdateCapture("true")
          : handleHome.setUpdateCapture("false");
  
        setDeletePostModal(false);
  
        setButtonLoadingDeletePost(false);
      } else {
        setVisible(true);
        setButtonLoadingDeletePost(false);
      }
    };
  
    const openCreateStoryModal = async () => {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        base64: true, 
      });

      //const base64ToUpload = `data:image/jpeg;base64,${base64}`;
  
      if (!result.cancelled) {
        setFileName(result.assets[0].uri);
      }

      
    };
  
    const hideCreateStoryModal = () => {
      setFileName(
        "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/640px-A_black_image.jpg"
      );
  
      setCreateStoryModal(false);
    };
  
  
    const showCreateStoryModal = () => {
      
      setSheetHandleIsOpen(false);
  
      setCreateStoryModal(true);
  
      setFileName(
        "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/640px-A_black_image.jpg"
      );
  
      setTimeout(
        () => {
          openCreateStoryModal();
          setSheetHandleIsOpen(false);
        },
  
        400
      );
    };
  
    const renderTab = (tab) => {
      return (
        <TouchableOpacity
          onLongPress={() => {
            gotoPoleActivity(tab);
          }}
          onPress={() => {
            switchTab(tab);
          }}
        >
          <View
            style={[
              styles.pill,
              { backgroundColor: selectedTab === tab ? "#00BCFF" : "#e3e3e3" },
            ]}
          >
            <Text
              style={[
                styles.pillText,
                { color: selectedTab === tab ? "white" : "#898f8c" },
              ]}
            >
              {tab}
            </Text>
          </View>
        </TouchableOpacity>
      );
    };

    const renderListTab = (tab) => {
      
      return (
        <TouchableOpacity
          onLongPress={() => {
            gotoPoleActivity(tab);
          }}
          onPress={() => {
            switchTab(tab);
          }}
        >
          <View
            style={[
              styles.pill,
              { backgroundColor: selectedTab === tab ? "#00BCFF" : "#e3e3e3" },
            ]}
          >
            <Text
              style={[
                styles.pillText,
                { color: selectedTab === tab ? "white" : "#898f8c" },
              ]}
            >
              {tab}
            </Text>
          </View>
        </TouchableOpacity>
      );
    };



const goToAddActivity =()=>{

  navigation.navigate("AddActivity",{
    idPole:idPole,
    poleName:selectedTab
  })
setOpenHandlePole(false)


}
  
    const renderItemEvent = ({ item, index }) => {
      return (
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("ActivityDetail", {
              activity: item,
              concept: "event",
              idCentre:idCentre,
              idEvent: item.id,
            })
          }
          activeOpacity={0.8}
        >
          <View
            style={{
              marginLeft: index === 0 ? 30 : 20,
              marginRight: index === listEvent.length - 1 ? 30 : 0,
              
            }}
          >
          
            <ImageBackground
              source={{ uri: item.preview, cache: "force-cache" }}
              resizeMode="cover"
              
              imageStyle={{ borderRadius: 15}}
              style={{
                width: theme.sizes.width / 7 + 40,
                height: theme.sizes.width / 7 + 30,
                justifyContent: "space-between",
               
              }}
            >
              <View
                style={{
                  alignItems: "flex-end",
                  marginHorizontal: 15,
                  marginVertical: 15,
                }}
              >
                <View style={
                    {
                    width: 45,
                    height: 50,
                    borderRadius: 15,
                    color: "white",
                    backgroundColor:"#00BCFF",
                    justifyContent: "center",
                    alignItems: "center",}}>
                  <Text style={{ fontSize: 12 ,color:theme.colors.white}}>
                    {moment(item.date).format("DD").toLocaleUpperCase()}
                  </Text>
                  <Text style={{ fontSize: 12 ,color:theme.colors.white}} >
                    {moment(item.date).format("MMM").toLocaleUpperCase()}{" "}
                  </Text>
                </View>
              </View>
              <View style={{ marginLeft: 20, marginBottom: 25, color:"white" }}>
                {item.idStructure !== "60c18fee074ae9fe19bf80b1" ? (
                  <Text style={{ marginLeft: 20, marginBottom: 25, color:"white" }}>Proche de chez vous</Text>
                ) : null}
  
                <Text style={{ fontSize:30, marginLeft: 20, marginBottom: 25, color:"white" }}>{item.title}</Text>
              </View>
            </ImageBackground>
          </View>
        </TouchableOpacity>
      );
    };
  
    const renderPoleTitle = ({ item, index }) => {
      return (
        <View style={{ flexDirection: "row", marginBottom: "7%" }}>
          <TouchableOpacity
            style={{
              alignItems: "flex-start",
              marginLeft: "12%",
              width: "15%",
            }}
          >
            <View>
              <MaterialIcons name="format-align-left" size={35} color="white" />
            </View>
          </TouchableOpacity>
  
          <TouchableOpacity
            onPress={() => {
              gotoPoleActivityItem(item.activity, item.editedTitle, item._id);
            }}
            style={{ width: "80%", alignItems: "flex-start" }}
          >
            <Text style={{ color: "white", marginTop: "1%" }} >
              {item.editedTitle}
            </Text>
          </TouchableOpacity>
        </View>
      );
    };
  
    const renderItemPermanence = ({ item, index }) => {
      return (
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("PermanenceDetail", {
              permanence: item,
              concept: "permanence",
              idCentre:idCentre
            })
          }
          activeOpacity={0.8}
          style={styles.cardPermanence}
        >
          <View style={{ flex: 1 }}>
            <View>
              <ImageBackground
                source={{ uri: item.preview }}
                style={styles.imagePermanence}
                resizeMode="cover"
                borderTopRightRadius={10}
                borderTopLeftRadius={10}
              />
            </View>
  
            <View>
              <Text
                
                style={{ marginTop: "10%", marginLeft: "9%" }}
                color="#0f0c29"
              >
                {item.title}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      );
    };
  
    const renderItemHolidays = ({ item, index }) => {
  
  

  
      return (
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("HolidayBridge", {item})
          }
          style={styles.itemContainer}
          activeOpacity={1.0}
        >
      
          <View 
            style={[
              StyleSheet.absoluteFillObject,
              { overflow: "hidden", borderRadius: theme.sizes.RADIUS },
            ]}
          >
            <Image
              source={{ uri: item.image }}
              style={[
                StyleSheet.absoluteFillObject,
                { resizeMode: "cover",  },
              ]}
            />
          </View>
          
          <Text
            style={[styles.headerTitle]}
          >
            {item.header}
          </Text>
  
        </TouchableOpacity>
      );
    };
  
    const gotoPoleActivityItem = (activity, editedTitle, id) => {
      bottomSheetHandle.current.close();
      setSheetHandleIsOpen(false);
  
      navigation.navigate("PoleActivity", {
        activity: activity,
        poleName: editedTitle,
        idPole: id,
      });
      bottomSheetHandle.current.close();
      setSheetHandleIsOpen(false);
    };
  
    const addCapture = () => {
      navigation.navigate("AddCapture", {
        idCentre: idCentre,
      });
  
      bottomSheetHandle.current.close();
      setSheetHandleIsOpen(false);
    };
  
    const addPermanence = () => {
      navigation.navigate("AddPermanence", {
        idCentre: idCentre,
      });
  
      
    };
  
    const addPole = () => {
      navigation.navigate("AddPole", {
        idCentre: idCentre,
      });
  
      
    };
  
    const addEvent = () => {
      navigation.navigate("AddEvent", {
        idCentre: idCentre,
      });
     
    };
  
    const addHoliday = () => {
      navigation.navigate("AddHoliday", {
        idCentre: idCentre,
      });
      
    };
  
    const openDeleteStoryModal = () => {
      setDeleteStoryModal(true);
    };
  
    const hideDeleteStoryModal = () => {
      setDeleteStoryModal(false);
    };
  
    const openDeleteCaptureModal = (idPost, preview) => {
      setIdPost(idPost);
      setPreview(preview);
      setDeletePostModal(true);
    };
  
    const hideDeletePostModal = () => {
      setDeletePostModal(false);
    };
  
    const renderActivity = (item) => {
      const data = item.item;
  
      const days = data.dayActivity;
  
      let listDay = days.map((item, i) => {
        return (
          <TouchableOpacity key={i}>
            {days.length > 1 && days.length < 7 ? (
              <View
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  backgroundColor: theme.colors.lightBlue,
                  justifyContent: "center",
                  marginHorizontal: 3,
                }}
              >
                <Text h8 style={{ letterSpacing: 1, alignSelf: "center",color:"white" }}>
                  {item.substring(0, 3)}
                </Text>
              </View>
            ) : days.length == 1 ? (
              <View style={styles.pillDaySingle}>
                <Text style={[styles.pillTextDaySingle, { color: "black" }]}>
                  {"Tous les"} {days[0]}
                  {"s"}
                </Text>
              </View>
            ) : null}
          </TouchableOpacity>
        );
      });
  
      return (

        <TouchableOpacity
          onPress={() =>
            navigation.navigate("ActivityDetail", {
              activity: data,
              concept: "activity",
              idCentre: idCentre,
              idPole: idPole,
            })
          }
          activeOpacity={0.8}
          style={styles.card}
        >
          <View style={{ flex: 1 }}>
            {days.length == 7 ? (
              <View
                style={{
                  flexDirection: "row",
                  marginVertical: "7%",
                  marginLeft: "5%",
                }}
              >
                <View style={styles.pillDaySingle}>
                  <Text
                    
                    style={[styles.pillTextDaySingle, { color: "black" }]}
                  >
                    {"Tous les Jours"}
                  </Text>
                </View>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  marginVertical: "7%",
                  marginLeft: "5%",
                }}
              >
                {listDay}
              </View>
            )}
  
            <View>
              <ImageBackground
                source={{ uri: data.preview }}
                style={styles.image}
                resizeMode="cover"
                imageStyle={{ borderRadius: 15}}
              />
            </View>
  
            <View>
              <Text
                
                style={{ marginTop: "10%", marginLeft: "9%" }}
              
              >
                {data.title}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      );
    };
  
    const renderStory = (docs) => {
      return (
        <TouchableOpacity
          onPress={() => {
            openModal(docs.item);
          }}
        >
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View>
              <ImageBackground
                source={{ uri: docs.item }}
                style={styles.imageStory}
                resizeMode="cover"
                imageStyle={{ borderRadius: 15}}
              />
            </View>
          </View>
        </TouchableOpacity>
      );
    };
  
    const openModal = (docs) => {
      setStoryModal(true);
      setStoryContent(docs);
    };
  
    const closeStoryModal = () => {
      setStoryModal(false);
    };

    const DeletePoleHandler = async () => {


      setButtonLoading(true)
      
            const deletePoleData = {        
             
              idPole:route.params.idPole
            
          }
               
               
     const response = await deletePoleActions(deletePoleData)

   

      if(response.message == "Good"){

        setButtonLoading(false)
          
        handlePole.updatePole == "false"
        ? handlePole.setUpdatePole("true")
        : handlePole.setUpdatePole("false");

    

        setDeletePoleModal(false)
             
        navigation.navigate("Home")

      }else{


        setButtonLoading(false)
          
        handlePole.updatePole == "false"
        ? handlePole.setUpdatePole("true")
        : handlePole.setUpdatePole("false");

    

        setDeletePoleModal(false)
             
        navigation.navigate("Home")


      }

                   
                             
             
            }
  
    function jsx() {
      return (
        <SafeAreaView>



          <View  style={{ flex: 1 }}>
       
          <View style={{backgroundColor:"white"}}>
          <TopNavigationAccessoriesShowcase dataFromParent={{idCentre:idCentre,nameCentre:handleHome.nameCentre}}/>
          
          <Divider/>
            <FlatList
          
            contentContainerStyle={{ flexGrow: 1, paddingBottom: 5 }}
              ListHeaderComponent={
                <View  style={{ flex: 1 }}>



                <Modal animationType="slide" transparent={false} visible={openHandlePole}>

                <Layout style={styles.container} level='1'>
                <TopNavigation
                  alignment='center'
                  title='Gestion des Pôles'
                  
                  accessoryLeft={
                    
                    <TouchableOpacity   onPress={()=>setOpenHandlePole(false)} > 
                    <Ionicons name="arrow-back"
                  color={"#4e32a8"}
                  size={35} />
          
                  </TouchableOpacity>
                }
                  
                />
              </Layout>
          
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>

                

                <TouchableOpacity onPress={goToAddPole} style={styles.buttonContainerAddPole}>
                  
                <Text style={styles.paypalButtonText}>Ajouter un Pôle</Text>
              </TouchableOpacity>
              
              <View style={{margin:"2%"}}>
                <FlatList
                  data={tabPole}
                  keyExtractor={(item, index) => `${item}-${index}`}
                  horizontal
                  
                  
                  renderItem={({ item }) => renderListTab(item)}
                />

       

                </View>   

             

                <View style={{margin:"2%"}}>
                <Card style={styles.cardTab} footer={<View style={styles.footerContainer}>
                <Button
                onPress={goToAddActivity}
                style={styles.footerControl}
                size='small'>
                Ajouter une activité
              </Button>
                <Button
                onPress={() => setDeletePoleModal(true)}
                  style={styles.footerControl}
                  size='small'
                  status='basic'>
                  Supprimer le pôle
                </Button>
               
              </View>}>
                <Text>{selectedTab}</Text>
              </Card>
                    </View>
                </View>
   <Modal
      animationType={"slide"}
      transparent={true}
      visible={deletePoleModal}>
  <View style={{ flex: 1, justifyContent: "center", alignItems: "center",backgroundColor:"rgba(0,0,0,0.8)" }}>
  
  <View  style={styles.styleModal}>
  <View style={{ margin:0,padding:0 }}>
  <Button 
  onlyIcon
  icon="close-circle-sharp"
  iconFamily="ionicon"
  iconSize={22}
  iconColor={"#0f0c29"}
  color="transparent"
  onPress={() => setDeletePoleModal(false)}
  />
  </View>  
  
  <View style={styles.modalStyle}>  
  
  
  <View style={styles.socialTitle}>
  <Text bold  style={{marginBottom:"10%"}} color="#172B4D" size={18}>Supprimer ce Pole ?</Text>
  <Text bold color="#172B4D" size={14}>Attention si vous supprimer ce pole toutes les activités qu&rsquo;elle contient seront égalements supprimés</Text>
  </View>         
  <View  style={{ justifyContent: "center", alignItems: "center"}}>
  

  <Button
  loading={buttonLoading}
  onPress={() => setDeletePoleModal(true)}
    style={styles.footerControl}
    size='small'
    status='basic'>
    Supprimer 
  </Button>
  
  </View>
  </View>
  </View>
  </View>
  </Modal>
                
            </Modal>
                <View
                style={{
                
                  justifyContent: "center",
                }}
              >
              {
                listPost.length !== 0 ?  <View style={styles.containerPost}>
                <Image source={listPost[currentImageIndex].preview} style={styles.imagePost} />
               
               
              </View> :null
              }
             
              {handleHome.updateManager == true ? (
                <TouchableOpacity
                  onPress={() => {
                    openDeleteCaptureModal(listPost[currentImageIndex].preview._id, listPost[currentImageIndex].preview);
                  }}
                  style={{
                    width: 50,
                    position: 'relative',
              
                    bottom: 400,
                    left: 500,
                  }}
                  activeOpacity={0.8}
                >
                  {handleHome.updateManager == true &&
                    listPost[currentImageIndex].idCentre !== "advertising" ? (
                    <View
                      style={{
                        width: 35,
                        height: 30,
                        backgroundColor: "rgba(0,0,0,0.2)",
                        borderRadius: 10,
                        justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          alignSelf: "center",
                        }}
                      >
                        <Feather
                          name="trash"
                          size={theme.sizesPhotos.font * 1.2}
                          color={"#e0e0e0"}
                        />
                      </View>
                    </View>
                  ) : null}
                </TouchableOpacity>
              ) : null}
              <Button style={styles.buttonPost} onPress={handleNextButtonPress} status='primary'>
              <MaterialIcons name="navigate-next" size={35} color="white" />
             </Button>
                  
                  </View>
                  <Modal
                    animationType="slide"
                    transparent={true}
                    visible={
                      visibleWelcome == true &&
                      listActivity.length == 0 &&
                      handleHome.updateManager == true
                    }
                  >
                    <View
                      style={{
                        width: "90%",
                        height: "50%",
                        borderColor: "#ccc",
                        borderWidth: 1,
                        borderRadius: 20,
                        borderStyle: "solid",
                        backgroundColor: "white",
                        elevation: 50,
                        marginVertical: "2%",
                        marginLeft: "5%",
                        borderRadius: 4,
                        justifyContent: "center",
                      }}
                    >
                      <View>
                        <Text>
                          Bienvenue
                          <Text>
                            {" "}
                            {handleHome.firstname}
                          </Text>
                        </Text>
  
                        <Text
                         
                          style={{
                            marginHorizontal: theme.sizes.padding / 3,
                            marginVertical: theme.sizes.padding / 1,
                          }}
                        >
                          Vous êtes administrateur du compte{" "}
                          {"Centre Vitrine"}
                        </Text>
  
                        <Text
                        
                          style={{ margin: theme.sizes.padding / 3 }}
                        >
                          Vous pouvez créer de nouveaux pôles d’activités, de
                          nouveaux événements, des contenus pour faire participer
                          les habitants ou personnaliser les informations de{" "}
                          {"Centre Vitrine"}
                        </Text>
  
                        <Button
                          style={{ backgroundColor: theme.colors.lightBlue }}
                          onPress={hideAdminModal}
                          
                        >
                          <Text style={{ padding: "5%" }} >
                            Commencer
                          </Text>
                        </Button>
                      </View>
                    </View>
                  </Modal>
  
                  <Modal
                    animationType="slide"
                    transparent={true}
                    visible={
                      visibleWelcomeUser == true &&
                      listActivity.length == 0 &&
                      handleHome.updateManager == false
                    }
                  >
                    <View
                      style={{
                        width: "90%",
                        height: "40%",
                        borderColor: "#ccc",
                        borderWidth: 1,
                        borderRadius: 20,
                        borderStyle: "solid",
                        backgroundColor: "white",
                        elevation: 50,
                        marginVertical: "50%",
                        marginLeft: "5%",
                        borderRadius: 4,
                        justifyContent: "center",
                      }}
                    >
                      <View>
                        <Text>
                          Bienvenue
                        </Text>
  
                        <Text
                          style={{ margin: theme.sizes.padding }}
                        >
                          {
                            "Cette structure n'utilise pas encore MonCentreSocial@. N'hésitez pas à leur faire connaître !"
                          }
                        </Text>
  
                        <Button
                          style={{ backgroundColor: theme.colors.lightBlue }}
                          onPress={hideUserModal}
                          mode="contained"
                        >
                          <Text style={{ padding: "5%" }} >
                            {"J'ai Compris"}
                          </Text>
                        </Button>
                      </View>
                    </View>
                  </Modal>
  
                
                </View>
              }
              ListFooterComponent={
                <View>
                  {listStory.length == 0 ? null : (
                    <View>
                      <View
                        style={{
                          flexDirection: "row",
                          marginBottom: 25,
                          marginTop: 25,
                        }}
                      >
                        <Text
                          
                          style={styles.titleHeader}
                          
                        >
                          Stories
                        </Text>
                        <Text
                         
                          
                        >
                          {" "}
                          {"."}
                        </Text>
                      </View>
                      <View
                      style={{flex: 1}}
                    >
                      <FlatList
                        data={listStory}
                        renderToHardwareTextureAndroid
                        snapToAlignment="start"
                        snapToInterval={FULL_SIZE}
                        contentContainerStyle={{ alignItems: "center" }}
                        keyExtractor={(item, index) => `${item}-${index}`}
                        horizontal
                      
                      
                        decelerationRate="fast"
                        renderItem={renderStory}
                      />

                      </View>
                      <View
                        style={{
                          borderRadius: 30,
                          marginTop: "5%",
                        }}
                      >
                        
                      </View>
  
                      <Modal
                        animationType="fade"
                        transparent={true}
                        visible={deletePostModal}
                      >
                        <View
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "black",
                       
  
                            borderRadius: 10,
                            flexDirection: "row",
                          }}
                        >
                          <View
                            style={{
                              width: "70%",
                              height: "50%",
                              marginHorizontal: "15%",
                              marginVertical: "4%",
                              backgroundColor: "white",
                  
                              borderRadius: 10,
                              flexDirection: "row",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                          >
                            <View style={{
                             
                              alignContent: "center",
                              justifyContent: "center",
                            }}>
                              <Text>
                                Supprimer ce contenu
                                <Text>
                                  {"  "}
                                  {"?"}
                                </Text>
                              </Text>
  
                              <Button
                                loading={buttonLoadingDeletePost}
                                onPress={deletePostHandler}
                                style={{
                                  backgroundColor: theme.colors.lightBlue,
                                  marginTop: "20%",
                                  marginBottom: "10%",
                                }}
                                
                              >
                                <Text style={{ padding: "5%" }} >
                                  Supprimer
                                </Text>
                              </Button>
  
                              <Button
                                style={{ backgroundColor: "#0f0f0f" }}
                                onPress={hideDeletePostModal}
                                
                              >
                                <Text style={{ padding: "5%" }}>
                                  Annuler
                                </Text>
                              </Button>
                            </View>
                          </View>
                        </View>
                      </Modal>
  
                      <Modal
                        animationType="fade"
                        transparent={true}
                        visible={storyModal}
                      >

                      <Layout style={styles.container} level='1'>
                      <TopNavigation
                        alignment='center'
                        title=''
                        
                        accessoryLeft={ 
                          <View>
                          <TouchableOpacity
                                  onPress={closeStoryModal}
                                  style={{
                                    width: 100,
                                    height: 50,
                                    backgroundColor: "rgba(0,0,0,0.2)",
                                    justifyContent: "center",
                                    borderRadius: 10,
                                    alignItems: "center",
                                  }}
                                >
                                <Ionicons name="arrow-back"
                                color={"#4e32a8"}
                                size={35} />
                                </TouchableOpacity>
  
                          </View>
                        
                        }
                        accessoryRight={
                          <View style={{ flexDirection: "row" }}>

                          {handleHome.updateManager == true ? (
                            <View
                              style={{
                                width: 45,
                                height: 50,
                                backgroundColor: "rgba(0,0,0,0.2)",
                                justifyContent: "space-between",
                                borderRadius: 10,
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <TouchableOpacity
                                onPress={openDeleteStoryModal}
                              >
                                <View
                                  style={{
                                    margin: 12,
                                  }}
                                >
                                  <Feather
                                    name="trash"
                                    size={theme.sizesPhotos.font * 1.2}
                                    color={"#e0e0e0"}
                                  />
                                </View>
                              </TouchableOpacity>
                            </View>
                          ) : null}

                          <View style={{ width: "50%",marginHorizontal:20 }}>
                          <View>
                          <TouchableOpacity
                                  onPress={nextImg}
                                  style={{
                                    width: 100,
                                    height: 50,
                                    backgroundColor: "rgba(0,0,0,0.2)",
                                    justifyContent: "center",
                                    borderRadius: 10,
                                    alignItems: "center",
                                  }}
                                >
                                <Ionicons name="arrow-forward"
                                color={"#4e32a8"}
                                size={35} />
                                </TouchableOpacity>
  
                          </View>
                      
                          </View>
                          <View style={{ width: "50%" }}>

                         
                          </View>
                        </View>
                      
                      
                      }
                      />
          
                    </Layout>
               
                        <View
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "black",
                            justifyContent: "center",
  
                            alignItems: "center",
                          }}
                        >
                      
                          <ImageBackground
                            source={{ uri: storyContent }}
                            style={styles.imageContent}
                            resizeMode="contain"
                            imageStyle={{ borderRadius: 15}}
                          >
                          <TouchableOpacity onPress={nextImg}>
                            <View style={{ flex: 1 }}>
                            <View style={{  
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: 20,
                                marginLeft: 30,
                                marginRight: 30,
                                zIndex: 1}} >
                                <TouchableOpacity
                                  onPress={closeStoryModal}
                                  style={{
                                    width: 56,
                                    height: 40,
                                    backgroundColor: "rgba(0,0,0,0.2)",
                                    justifyContent: "center",
                                    borderRadius: 10,
                                    alignItems: "center",
                                  }}
                                >
                                  <Image
                                    size={20}
                                    source={require("../assets/icons/back_arrow2.png")}
                                  />
                                </TouchableOpacity>
  
                                         
                            
                              </View>
  
                             
                                <View
                                  style={{
                                    width: "100%",
                                    height: "80%",
                                    marginTop: "10%",
                                  
                                    
                                  }}
                                >
                                  <TouchableOpacity
                                    onPress={nextImg}
                                  >
                                  <View
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                   
                                  }}
                                >
                                  
                                </View>
                                  </TouchableOpacity>
                                </View>
                        
                            </View>
                            </TouchableOpacity>
                          </ImageBackground>
                         
                        </View>
                       
                        <Modal
                        animationType="fade"
                        transparent={true}
                        visible={createStoryModal}
                      >
                        <View>
                          <ImageBackground
                            source={{ uri: fileName }}
                            style={styles.imageContent}
                            resizeMode="contain"
                            imageStyle={{ borderRadius: 15}}
                          >
                            <View style={{ flex: 1 }}>
                              <View style={{  
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: 20,
                                marginLeft: 30,
                                marginRight: 30,
                                zIndex: 1}} >
                                <TouchableOpacity
                                  onPress={hideCreateStoryModal}
                                  style={{
                                    width: 46,
                                    height: 35,
                                    backgroundColor: "rgba(0,0,0,0.2)",
                                    justifyContent: "center",
                                    borderRadius: 10,
                                    alignItems: "center",
                                  }}
                                >
                                  <Feather
                                    name="x"
                                    size={theme.sizesPhotos.font * 1.2}
                                    color={"#e0e0e0"}
                                  />
                                </TouchableOpacity>
  
                                <View
                                  style={{
                                    width: 150,
                                    height: 40,
  
                                    justifyContent: "flex-end",
                                    borderRadius: 10,
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  {handleHome.updateManager == true ? (
                                    <Button
                                      onPress={publishStory}
                                      loading={buttonLoadingStory}
                                      style={{
                                        backgroundColor:
                                          theme.colors.lightBlue,
                                      }}
                                      mode="contained"
                                    >
                                      <Text
                                        style={{ padding: "10%" }}
                                        
                                      >
                                        Envoyer{" "}
                                      </Text>
  
                                      <Feather
                                        name="chevrons-right"
                                        size={
                                          theme.sizesPhotos.font * 1.2
                                        }
                                        color={"#e0e0e0"}
                                      />
                                    </Button>
                                  ) : null}
                                </View>
                              </View>
                            </View>
                          </ImageBackground>
                        </View>
                      </Modal>
  
                      <Modal
                        animationType="fade"
                        transparent={true}
                        visible={deleteStoryModal}
                      >
                        <View
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "black",
                     

                          borderRadius: 10,
                          flexDirection: "row",
                        }}
                      >
                        <View
                          style={{
                            width: "70%",
                            height: "50%",
                            marginHorizontal: "15%",
                            marginVertical: "4%",
                            backgroundColor: "white",
                
                            borderRadius: 10,
                            flexDirection: "row",
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{
                           
                            alignContent: "center",
                            justifyContent: "center",
                          }}>
                            <Text>
                                Supprimer la Storie
                                <Text
                                 
                                >
                                  {"  "}
                                  {"?"}
                                </Text>
                              </Text>
  
                              <Button
                                loading={buttonLoadingDeleteStory}
                                onPress={deleteStoryHandler}
                                style={{
                                  backgroundColor:
                                    theme.colors.lightBlue,
                                  marginTop: "20%",
                                  marginBottom: "10%",
                                }}
                                mode="contained"
                              >
                                <Text
                                  style={{ padding: "5%" }}
                                 
                                >
                                  Supprimer
                                </Text>
                              </Button>
  
                              <Button
                                style={{ backgroundColor: "#0f0f0f" }}
                                onPress={hideDeleteStoryModal}
                                mode="contained"
                              >
                                <Text
                                  style={{ padding: "5%" }}
                                 
                                >
                                  Annuler
                                </Text>
                              </Button>
                            </View>
                          </View>
                        </View>
                      </Modal>
                      </Modal>
                    </View>
                  )}
  
                  {flatlistActivity.length == 0 ? null : (
                    <View style={{flex: 1}}>
                      <FlatList
                        data={tabPole}
                        keyExtractor={(item, index) => `${item}-${index}`}
                        horizontal
                        
                        style={{ flexGrow: 0 }}
                        contentContainerStyle={{ padding: SPACING }}
                        renderItem={({ item }) => renderTab(item)}
                      />
  
                      <View
                        style={{
                          marginBottom: "2%",
                          
                        }}
                      >
                      
                      </View>
  
                      <Animated.FlatList
                      showsHorizontalScrollIndicator={false}
                      viewabilityConfig={{ viewAreaCoveragePercentThreshold: 50 }}
                      getItemLayout={(data, index) => ({ length: 200, offset: 200 * index, index })}
                      initialScrollIndex={scrollIndexActivity}
                        extraData={index}
                        data={flatlistActivity}
                       
                        ref={flatListRef}
                        keyExtractor={(item, index) => `${item}-${index}`}
                        horizontal
                        
                        renderItem={renderActivity}
                      />
                      {
                        flatlistActivity.length > 5 ?
                        <View style={{flexDirection:"row"}}> 
                      <View style={{width:"50%"}}> 
                      <Button style={styles.buttonPrevious} onPress={() => scrollIndexActivity > 2 ? scrollToIndexActivity(scrollIndexActivity-2):scrollToIndexActivity(0)} status='primary'>
                      <MaterialIcons name="navigate-before" size={35} color="white" />
                     </Button>
                     </View>
                     <View style={{width:"50%"}}> 
                      <Button style={styles.buttonPost} onPress={handleActivityPosition} status='primary'>
                      <MaterialIcons name="navigate-next" size={35} color="white" />
                     </Button>
                     </View>
                     </View>:null
                      }
                      
                      {listPermanence.length == 0 && permaData == false ? (
                        null
                      ) : listPermanence.length !== 0 ? (
                        <View>
                          <View
                            style={{
                              borderRadius: 30,
                              marginVertical: "2%",
                            }}
                          >
                            
                          </View>
  
                          <View
                            style={{
                              flexDirection: "row",
                            }}
                          >
                            <Text style={styles.titleHeader}>
                              Permanences
                            </Text>
                           
                          </View>
                          <View
                            style={{
                              marginTop: theme.sizes.height * 0.02,
                              flex: 1
                            }}
                          >
                            <FlatList
                            ref={flatListRefPermanence}
                          showsHorizontalScrollIndicator={false}
                          viewabilityConfig={{ viewAreaCoveragePercentThreshold: 50 }}
                          getItemLayout={(data, index) => ({ length: 200, offset: 200 * index, index })}
                          initialScrollIndex={scrollIndexPermanence}
                              horizontal
                              data={listPermanence}
                              renderItem={renderItemPermanence}
                              keyExtractor={(item, index) => index.toString()}
                              
                            />
                            {
                              listPermanence.length > 5 ?
                            <View style={{flexDirection:"row"}}> 
                            <View style={{width:"50%"}}> 
                            <Button style={styles.buttonPrevious} onPress={() => scrollIndexPermanence > 2 ? scrollToIndexPermanence(scrollIndexPermanence-2):scrollToIndexPermanence(0)} status='primary'>
                            <MaterialIcons name="navigate-before" size={35} color="white" />
                           </Button>
                           </View>
                           <View style={{width:"50%"}}> 
                            <Button style={styles.buttonPost} onPress={() => listPermanence.length > scrollIndexPermanence + 2 ? scrollToIndexPermanence(scrollIndexPermanence+2) : scrollToIndexPermanence(listPermanence.length -1)} status='primary'>
                            <MaterialIcons name="navigate-next" size={35} color="white" />
                           </Button>
                           </View>
                           </View>:null}
                          </View>
                        </View>
                      ) : null}
                    </View>
                  )}
  
                  {listHoliday.length == 0 ? (
                   null
                  ) : listHoliday.length > 1 ? (
                    <View>
                      <View
                        style={{
                          borderRadius: 30,
                          marginVertical: "2%",
                        }}
                      >
                        
                      </View>
  
                      <View
                        style={{
                          flexDirection: "row",
                        }}
                      >
                        <Text
                      
                          style={styles.titleHeader}
                     
                        >
                          Vacances Scolaires
                        </Text>
                        <Text
                          style={{ marginTop: -14 ,color :theme.colors.lightBlue}}
                       
                        >
                          {" "}
                          {"."}
                        </Text>
                      </View>
                      <View
                        style={{
                          marginTop: theme.sizes.height * 0.02,
                        }}
                      >
                        <Animated.FlatList
                          horizontal
                          data={listHoliday}
                          ref={flatListRefHoliday}
                          showsHorizontalScrollIndicator={false}
                          viewabilityConfig={{ viewAreaCoveragePercentThreshold: 50 }}
                          getItemLayout={(data, index) => ({ length: 200, offset: 200 * index, index })}
                          initialScrollIndex={scrollIndexHoliday}
                          renderItem={renderItemHolidays}
                          decelerationRate="fast"
                          onScroll={Animated.event(
                            [{ nativeEvent: { contentOffset: { x: scrollX } } }],
                            { useNativeDriver: true }
                          )}
                          snapToInterval={theme.sizes.FULL_SIZE}
                          keyExtractor={(item, index) => index.toString()}
                       
                        />

                        {
                          listHoliday.length > 3 ?
                        <View style={{flexDirection:"row"}}> 
                        <View style={{width:"50%"}}> 
                        <Button style={styles.buttonPrevious} onPress={() => scrollIndexHoliday > 2 ? scrollToIndexHoliday(scrollIndexHoliday-2):scrollToIndexHoliday(0)} status='primary'>
                        <MaterialIcons name="navigate-before" size={35} color="white" />
                       </Button>
                       </View>
                       <View style={{width:"50%"}}> 
                        <Button style={styles.buttonPost} onPress={() => listHoliday.length > scrollIndexHoliday + 2 ? scrollToIndexHoliday(scrollIndexHoliday+2) : scrollToIndexHoliday(listHoliday.length -1)} status='primary'>
                        <MaterialIcons name="navigate-next" size={35} color="white" />
                       </Button>
                       </View>
                       </View>:null}
                      </View>
                    </View>
                  ) : null}
  
                  <View
                    style={{
                      borderRadius: 30,
                      marginTop: 10,
                    }}
                  >
               
                  </View>
  
                  {listEvent.length == 0 ? (
                   null
                  ) : (
                    <LinearGradient
                      colors={theme.colors.linear20}
                      start={{ x: 0, y: 1 }}
                      end={{ x: 1, y: 0 }}
                      style={{
                        borderRadius: 30,
                        marginTop: "5%",
                        marginBottom: "45%",
                        backgroundColor: "white",
                      }}
                    >
                      <View>
                        <Text style={{
                          fontSize: 25,
      color: "#fff",
      fontWeight: "800",
      width: theme.sizes.ITEM_WIDTH * 0.8,
      textTransform: "uppercase",
      
      marginTop: theme.sizes.height * 0.04,
      marginBottom: theme.sizes.height * 0.04,
      marginLeft: theme.sizes.height * 0.04,
                        }}>{`Evénements`}</Text>
                      </View>
                
                      <View
                        style={{
                          marginTop: theme.sizes.height * 0.01,
                          marginBottom: theme.sizes.height * 0.04,
                          flex: 1,
                          
                        }}
                      >
                 
                        <FlatList
                        ref={flatListRefEvent}
                        viewabilityConfig={{ viewAreaCoveragePercentThreshold: 50 }}
                        getItemLayout={(data, index) => ({ length: 200, offset: 200 * index, index })}
                        initialScrollIndex={scrollIndexEvent}
                        contentContainerStyle={{ flexGrow: 1, paddingBottom: 5,width: 400}}
                          horizontal
                          showsHorizontalScrollIndicator={false}
                          data={listEvent}
                          renderItem={renderItemEvent}
                          keyExtractor={(item, index) => index.toString()}
                     
                        />
                      </View>
                      {
                        listEvent.length > 5 ?
                      <View style={{flexDirection:"row"}}> 
                      <View style={{width:"50%"}}> 
                      <Button style={styles.buttonPrevious} onPress={() => scrollIndexEvent > 2 ? scrollToIndexEvent(scrollIndexEvent-2):scrollToIndexEvent(0)} status='primary'>
                      <MaterialIcons name="navigate-before" size={35} color="white" />
                     </Button>
                     </View>
                     <View style={{width:"50%"}}> 
                      <Button style={styles.buttonPost} onPress={() => listEvent.length > scrollIndexEvent + 2 ? scrollToIndexEvent(scrollIndexEvent+2) : scrollToIndexEvent(listEvent.length -1)} status='primary'>
                      <MaterialIcons name="navigate-next" size={35} color="white" />
                     </Button>
                     </View>
                     </View>:null}
                    </LinearGradient>
                  )}
                </View>
              }
            />
            <Modal
              animationType="fade"
              transparent={true}
              visible={createStoryModal}
            >
              <View
                style={{
                  backgroundColor: "black",
  
                  height: "100%",
                }}
              >
             
             
                <ImageBackground
                  source={{ uri: fileName }}
                  style={styles.imageContent}
                  resizeMode="contain"
                  imageStyle={{ borderRadius: 15}}
                >
                
                  <View style={{ flex: 1 }}>
                  <View>
                      <TouchableOpacity
                        onPress={hideCreateStoryModal}
                        style={{
                          width: 452,
                          height: 35,
                          backgroundColor: "rgba(0,0,0,0.2)",
                          justifyContent: "center",
                          borderRadius: 10,
                          alignItems: "center",
                        }}
                      >
                        <Feather
                          name="x"
                          size={theme.sizesPhotos.font * 1.2}
                          color={"#e0e0e0"}
                        />
                      </TouchableOpacity>
  
                      <View
                        style={{
                          width: 150,
                          height: 40,
  
                    
                          
                        }}
                      >
                        {handleHome.updateManager == true ? (
                          <Button
                            onPress={publishStory}
                            loading={buttonLoadingStory}
                            style={{ backgroundColor: theme.colors.lightBlue }}
                            mode="contained"
                          >
                            <Text style={{ padding: "10%" }}>
                              Envoyer{" "}
                            </Text>
  
                            <Feather
                              name="chevrons-right"
                              size={theme.sizesPhotos.font * 1.2}
                              color={"#e0e0e0"}
                            />
                          </Button>
                        ) : null}
                      </View>
  
  
                  
                    </View>
                  </View>
  
                  <View
                    style={{ justifyContent: "center", marginVertical: "20%" }}
                  >
                   
                  </View>
                </ImageBackground>
                
              </View>
            </Modal>
          </View>
  
            
            
          </View>
        </SafeAreaView>
      );
    }
  
    const renderPost = (item) => {

     
      return (
        <TouchableOpacity activeOpacity={0.8}>
          <ImageBackground  resizeMode= 'contain'
          imageStyle={{ borderRadius: 15}}
            style={[
             
              stylesPictures.destination,
             
            ]}
            source={{ uri: item.preview }}
          >
            <View
              style={[stylesPictures.row, { justifyContent: "space-between" }]}
            >
              <View
                style={[
                  stylesPictures.column,
                  {
                    padding: "5%",
                  },
                ]}
              >
                <Text
                  style={{
                    color: theme.colors.white,
                    fontWeight: "bold",
                    margin: "5%",
                  }}
                >
                  {item.title}
                </Text>
                <View style={{ flexDirection: "row", margin: "5%" }}>
                  <View>
                    {item.location !== "" ? (
                      <Text style={{ color: theme.colors.white }}>
                        <Octicons
                          name="location"
                          size={theme.sizesPhotos.font * 0.8}
                          color={theme.colors.white}
                        />
                      </Text>
                    ) : null}
                  </View>
  
                  <View>
                    <Text
                      style={{
                        fontSize: 13,
                        marginLeft: 5,
                        marginTop: -3,
                        fontWeight: "700",
                        fontStyle: "italic",
                      }}
                    >
                      {" "}
                      {item.location}
                    </Text>
                  </View>
                </View>
              </View>
              {handleHome.updateManager == true ? (
                <TouchableOpacity
                  onPress={() => {
                    openDeleteCaptureModal(item._id, item.preview);
                  }}
                  style={[
                    stylesPictures.column,
                    {
                      flex: 0.5,
  
                      padding: "5%",
                      alignItems: "flex-end",
                    },
                  ]}
                  activeOpacity={0.8}
                >
                  {handleHome.updateManager == true &&
                  item.idCentre !== "advertising" ? (
                    <View
                      style={{
                        width: 35,
                        height: 30,
                        backgroundColor: "rgba(0,0,0,0.2)",
                        borderRadius: 10,
                        justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          alignSelf: "center",
                        }}
                      >
                        <Feather
                          name="trash"
                          size={theme.sizesPhotos.font * 1.2}
                          color={"#e0e0e0"}
                        />
                      </View>
                    </View>
                  ) : null}
                </TouchableOpacity>
              ) : null}
            </View>
          </ImageBackground>
        </TouchableOpacity>
      );
    };
    return jsx();
  };
  


  const styles = StyleSheet.create({
    itemContainer: {
      width: theme.sizes.ITEM_WIDTH * 0.3 ,
    height: theme.sizes.ITEM_WIDTH * 0.2,
      margin: theme.sizes.SPACING,
    },
   
    headerTitle: {
      fontSize: 25,
      color: "#fff",
      fontWeight: "800",
      width: theme.sizes.ITEM_WIDTH * 0.8,
      textTransform: "uppercase",
      position: "absolute",
      top: theme.sizes.SPACING + 90,
      left: theme.sizes.SPACING + 20,
    },
    titleHeader: {
      fontSize: 25,
      color: "black",
      fontWeight: "800",
      width: theme.sizes.ITEM_WIDTH * 0.8,
      textTransform: "uppercase",
      top: theme.sizes.SPACING + 90,
      marginLeft: 60,
    },
  
    titleHeaderWhite: {
      fontSize: 25,
      color: "white",
      fontWeight: "800",
     
      textTransform: "uppercase",
      
     alignSelf:"center"
    },
    titleSetting: {
      fontSize: 13,
      color: "white",
      fontWeight: "800",
     
     
    },
    pill: {
      paddingHorizontal: 10,
      paddingVertical: 12 / 1.5,
      borderRadius: 10,
      marginHorizontal: 5,
    },
    pillText: {
      fontWeight: "700",
      fontSize: 12,
    },
    pillDay: {
      paddingHorizontal: 10,
      paddingVertical: 12 / 1.5,
  
      marginLeft: 10,
    },
    pillDaySingle: {
      marginHorizontal: 7,
      marginVertical: 6,
    },
    pillTextDay: {
      fontWeight: "700",
      fontSize: 10,
    },
    modalStyle:{
      backgroundColor:"#fff",
      height:"50%",
      width:"85%",
      alignItems:"center",
      justifyContent: "center",
      marginLeft:20
    },
buttonPost:{
  
  width: 50,
    position: 'relative',
    alignSelf: 'flex-end',
    bottom: 250,
    right: 190,

},
buttonPrevious:{
  
  width: 50,
    position: 'relative',
    alignSelf: 'flex-start',
    bottom: 250,
    left: 90,

},

    styleModal:{
      backgroundColor:"#fff",
      height:"50%",
      width:"90%",
   
      borderRadius:70,
      
    },
    pillTextDaySingle: {
      fontWeight: "700",
      alignItems: "center",
      paddingBottom: 2,
    },
  
    date: {
      fontWeight: "400",
      fontSize: 18,
      marginVertical: 10,
      marginHorizontal: 12 * 1.3,
    },
  
    titleFlatlistActivity: {
      fontWeight: "800",
      fontSize: 22,
      marginTop: 25,
      marginHorizontal: 12 * 1.3,
    },
  
    card: {
      width:  theme.sizes.width > 800 ? 350  : theme.sizes.width * 0.64,
      height: theme.sizes.height > 1000 ? 320 :  theme.sizes.height * 0.36,
      margin: 12,
      backgroundColor: "#fcfcfc",
      borderRadius: 20,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 3,
      },
      shadowOpacity: 0.27,
      shadowRadius: 4.65,
      elevation: 6,
    },
    cardPermanence: {
      width: theme.sizes.width > 800 ? 350  : theme.sizes.width * 0.64,
      height: theme.sizes.height > 1000 ? 260:  theme.sizes.height * 0.29,
      margin: 20,
      backgroundColor: "#fcfcfc",
      borderRadius: 10,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.2,
      shadowRadius: 3.65,
      elevation: 3,
    },
  
    image: {
      width:theme.sizes.width > 800 ? 350  : theme.sizes.width * 0.64,
      height: 130,
      alignSelf: "center",
      resizeMode: "cover",
      borderRadius: 15,
      marginHorizontal: 0,
    },
    imagePermanence: {
      width: theme.sizes.width > 800 ? 350  : theme.sizes.width * 0.64,
      height: theme.sizes.height > 1000 ? 170 :  theme.sizes.height * 0.20,
      alignSelf: "center",
      resizeMode: "cover",
  
      marginHorizontal: 0,
    },
  
    imageStory: {
      width: theme.sizes.width > 800 ? 210  : theme.sizes.width * 0.28,
      height: theme.sizes.height > 1000 ? 230 :  theme.sizes.height * 0.18,
      alignSelf: "center",
      resizeMode: "contain",
      borderRadius: 20,
      marginHorizontal: 5,
    },
    imageContent: {
      width: "80%",
      height: "80%",
      marginTop:"5%",
      marginLeft:"2%",
      justifyContent:"center"
    },
  
    header: {
      paddingHorizontal: theme.sizes.base * 1,
      marginVertical: theme.sizes.base * 1,
    },
  
    titleDescription: {
      fontSize: 13,
      margin: 5,
      marginLeft: 0,
      fontWeight: "700",
    },
    titleLocation: {
      fontSize: 13,
      margin: 5,
      marginLeft: 0,
      fontWeight: "700",
      fontStyle: "italic",
    },
    roundedBottomSheet: {
      backgroundColor: "#00BCFF",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      paddingVertical: theme.sizes.width / 15.26,
      
      flexGrow: 1,
    },
  
    titleDate: {
      fontSize: 15,
      fontWeight: "700",
      margin: 7,
      marginLeft: 0,
      color: "#0f0c29",
      fontStyle: "italic",
    },
    RenderPole: {
      flexDirection: "row",
      width: width * 0.91,
    },
    infoPole: {
      width: width * 0.57,
    },
  
    cardPole: {
      alignItems: "flex-end",
    },
  
    title: {
      marginBottom: 10,
      fontSize: 14,
    },
  
    Subtitle: {
      marginBottom: 5,
      fontSize: 16,
      width: "100%",
      color: "#0f0c29",
    },
    SeeMore: {
      fontSize: 12,
      color: "#0f0c29",
      fontStyle: "italic",
    },
  
    BorderDouble: {
      borderColor: "#3d3a57",
      borderWidth: 1,
    },
  
    title: {
      fontSize: 12,
      padding: 4,
      color: "white",
      marginVertical: 15,
      backgroundColor: "#0f0c29",
      textAlign: "center",
  
      overflow: "hidden",
      borderWidth: 1,
    },
    metaInfo: {},
    contentFontManage: {
      fontSize: 20,
      color: "#0f0c29",
      fontWeight: "bold",
  
      textAlign: "center",
    },
    contentFont: {
      fontSize: 15,
      color: "#0f0c29",
      textAlign: "center",
      padding: "6%",
    },
  
    contentIconManage: {
      fontSize: 12,
  
      color: "white",
      backgroundColor: "#0f0c29",
      textAlign: "center",
      borderRadius: 20,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 10,
      paddingBottom: 10,
    },
 

    buttonContainerAddPole: {
      backgroundColor: theme.colors.lightBlue,
      padding: 10,
      borderRadius: 5,
      flexDirection: 'row',
      alignItems: 'center',
    },
    buttonContainerCancel: {
      backgroundColor: '#9e1208',
      padding: 10,
      borderRadius: 5,
      flexDirection: 'row',
      alignItems: 'center',
    },
    paypalButtonText: {
      color: 'white',
      fontWeight: 'bold',
    },
  
  
    containerPost: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    imagePost: {
      width: theme.sizes.width * 0.45,
      height: theme.sizes.height * 0.40,
      resizeMode: 'contain',
    },
  
  
  });
  
  const stylesPictures = StyleSheet.create({
    
    column: {
      flexDirection: "column",
    },
    row: {
      flexDirection: "row",
    },
  
    destination: {
      width: theme.sizes.width * 0.40,
      height: theme.sizes.height * 0.35,
    },
  
  
  
  });
  
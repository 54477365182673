
import { getData } from "../../storage/manageStorage";

    export const AddContentStory  = async  (formData) => {

      const token = await getData("authToken")
  
     return fetch( "https://mcsdatamanagesocial.osc-fr1.scalingo.io/centers/addStoryWeb", {
          method: "POST",
          
           body:formData    
           
      }).then((response) => response.json())
      .then((json) => {
  
       
      return json;
      }).catch((err) => {
          console.log("AddStory.js, AddStory Request Error: ", err.message);
          
      });
  
         
    
  }
import {

  Dimensions,

} from "react-native";

const { width, height } = Dimensions.get("window");

const CUSTOMFONT_REGULAR = 'ProductSans-Regular';
const CUSTOMFONT_BOLD = 'ProductSans-Bold';
const CUSTOMFONT_BLACK = 'ProductSans-Black';


const colors = {
  accent: "#F3534A",
  primary: "#0AC4BA",
  secondary: "#0a8396",
  tertiary: "#FFE358",
  black: "#323643",
  white: "#FFFFFF",
  gray: "#9DA3B4",
  gray2: "#C5CCD6",
  turquoise:"#0a8396",
  caption: '#BCCCD4',
  active: '#007BFA',
  purple:'#1f014a',
  blueCyan:'#11CDEF',
  DEFAULT: '#172B4D',
  PRIMARY: '#5E72E4',
  SECONDARY: '#F7FAFC',
  LABEL: '#FE2472',
  INFO: '#11CDEF',
  ERROR: '#F5365C',
  SUCCESS: '#2DCE89',
  WARNING: '#FB6340',
  MUTED: '#ADB5BD',
  INPUT: '#DCDCDC',
  INPUT_SUCCESS: '#7BDEB2',
  INPUT_ERROR: '#FCB3A4',
  ACTIVE: '#5E72E4', //same as primary
  BUTTON_COLOR: '#9C26B0', //wtf
  PLACEHOLDER: '#9FA5AA',
  SWITCH_ON: '#5E72E4',
  SWITCH_OFF: '#D4D9DD',
  GRADIENT_START: '#6B24AA',
  GRADIENT_END: '#AC2688',
  PRICE_COLOR: '#EAD5FB',
  BORDER_COLOR: '#E7E7E7',
  BLOCK: '#E7E7E7',
  ICON: '#172B4D',
  HEADER: '#525F7F',
  BORDER: '#CAD1D7',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  lightBlue:"#00BCFF",
  defaultApp:"#efefef",
  transparentWhite: 'rgba(255, 255, 255, 0.2)',
  transparentBlack: 'rgba(0, 0, 0, 0.4)',
  linear: ['#0f0c29', '#302b63'],
  close: ['#C5CCD6', '#C5CCD6'],
  linear2: ['#b92b27', '#1565C0'],
  linear3: ['#8E2DE2', '#4A00E0'],
  linear4: ['#009FFF', '#009FFF'],
  linear5: ['#74ebd5', '#ACB6E5'],
  linear7: ['#43C6AC', '#191654'],
  linear6: ['#03001e', '#7303c0',"#ec38bc","#fdeff9"],
  linear8: ['#43cea2', '#43cea2'],
  linear9: ['#16222A', '#3A6073'],
  linear10: ['#41295a', '#2F0743'],
  linear11: ['#00B4DB', '#0083B0'],
  linear12: ['#000046', '#1CB5E0'],
  linear13: ['#000000',"#090947",],
  linear15: ['#ADA996',"#F2F2F2"],
  linear14: ['#000428', '#004e92'],
  linear16: ['#c6b8d4', '#9570ba'],
  linear17: ['#70b3c4', '#0aa3c9'],
  linear18: ['#735c91', '#400191'],
  linear19: ['#91dfeb', '#96b0b5'],
  linear20: ['#0300C4', '#00FFEF'],
  linearDislabled: ['#f5f5f5', '#d6d2d2'],
  PURPLE:"#302b63",
};


 const widthBlock = width > 600 ? "20%" : "70%"
 const widthLogin = width > 600 ? "20%" : "90%"

  const s = width*0.68
const sizes = {
  // global sizes
  base:  width * 0.046,
  font: 14,
  radius: 6,
  padding: width * 0.07,
  widthBlock:widthBlock,
  widthLogin:widthLogin,
  s:width*0.68,
  ITEM_WIDTH: s,
  ITEM_HEIGHT: s*1.5,
  SPACING:12,
  RADIUS:18,
  SIZE:64,
  FULL_SIZE: s+12*2,
  ICON_SIZE:64 *0.6,
  h1: 26,
  h2: 20,
  h3: 18,
  title: 18,
  header: 16,
  body: 14,
  caption: 12,
  width:width,
  height:height,
  BLOCK_SHADOW_RADIUS: 2,
  base2: '8px',
  font2: '14px',
  radius2: '20px',
  padding2: '30px',
  small: '24px',
  big: '32px',

  // font sizes
  large: 40,
  small: 24,
  h1: 30,
  h2: 24,
  h3: 20,
  h4: 16,
  h5: 14,
  h6: 13,
  h7: 12,
  h8: 11,
  h9: 10,
  body1: 30,
  body2: 22,
  body3: 16,
  body4: 14,
  body5: 13,
  body6: 12,

};

const sizesPhotos = {
  base: 16,
  font: 14,
  padding: width * 0.01,
  margin: width * 0.01,
  title: 24,
  radius: 12,
};


const fonts = {
  h1: {
    fontSize: sizes.h1,

  },
  h2: {
    fontSize: sizes.h2
  },
  h3: {
    fontSize: sizes.h3
  },
  header: {
    fontSize: sizes.header
  },
  title: {
    fontSize: sizes.title
  },
  body: {
    fontSize: sizes.body
  },
  caption: {
    fontSize: sizes.caption
  }
};
 const FONTS = {
  large: { fontFamily: CUSTOMFONT_REGULAR, fontSize: sizes.large, lineHeight: '40px' },
  small: { fontFamily: CUSTOMFONT_REGULAR, fontSize: sizes.small, lineHeight: '22px' },
  h1: { fontFamily: CUSTOMFONT_BOLD, fontSize: sizes.h1, lineHeight: '36px' },
  h2: { fontFamily: CUSTOMFONT_BOLD, fontSize: sizes.h2, lineHeight: '30px' },
  h3: { fontFamily: CUSTOMFONT_BOLD, fontSize: sizes.h3, lineHeight: '22px' },
  h4: { fontFamily: CUSTOMFONT_BOLD, fontSize: sizes.h4, lineHeight: '22px' },
  h5: { fontFamily: CUSTOMFONT_BOLD, fontSize: sizes.h5, lineHeight: '22px' },
  h6: { fontFamily: CUSTOMFONT_BOLD, fontSize: sizes.h6, lineHeight: '22px' },
  h7: { fontFamily: CUSTOMFONT_BOLD, fontSize: sizes.h7, lineHeight: '22px' },
  h8: { fontFamily: CUSTOMFONT_BOLD, fontSize: sizes.h8, lineHeight: '22px' },
  h9: { fontFamily: CUSTOMFONT_BOLD, fontSize: sizes.h9, lineHeight: '22px' },
  body1: { fontFamily: CUSTOMFONT_REGULAR, fontSize: sizes.body1, lineHeight: '36px', },
  body2: { fontFamily: CUSTOMFONT_REGULAR, fontSize: sizes.body2, lineHeight: '30px', },
  body3: { fontFamily: CUSTOMFONT_REGULAR, fontSize: sizes.body3, lineHeight: '25px', },
  body4: { fontFamily: CUSTOMFONT_REGULAR, fontSize: sizes.body4, lineHeight: '22px', },
  body5: { fontFamily: CUSTOMFONT_REGULAR, fontSize: sizes.body5, lineHeight: '22px', },
  body6: { fontFamily: CUSTOMFONT_REGULAR, fontSize: sizes.body6, lineHeight: '22px', },
};



export { colors, sizes, fonts, sizesPhotos,FONTS};

export const resendMailConfirmAccount = (emailData) => {
 
   return fetch("https://mcsdatamanagesocial.osc-fr1.scalingo.io/users/resendValidate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
         body: JSON.stringify({
         emailData
        
         })
    }).then((response) => response.json())
    .then((json) => {
           
      return json;
    }).catch((err) => {
        console.log("resendMailConfirmAccount.js, email Request Error: ", err.message);

    });
  

}

import React, { useEffect, useState, useRef, useContext } from "react";
import moment from "moment";
import localization from "moment/locale/fr";
import { SOCKET } from "../../config/socket";

import {
  Text,
  View,
  StyleSheet,
  ImageBackground,
  Platform,
  Image,
  StatusBar,
  FlatList,
  Modal,
  Button,
  Share,
} from "react-native";

import { LinearGradient } from "expo-linear-gradient";


import { ScrollView } from "react-native-gesture-handler";
import { TouchableOpacity } from "react-native-gesture-handler";

import { Feather } from "@expo/vector-icons";
import { theme } from "../../constants";

import { getData } from "../../storage/manageStorage";
import { addUserParticipation } from "../../actions/AddParticipationFileAction";
import { loginActions } from "../../actions/authentification/LoginAction";
import { removeParticipation } from "../../actions/removeParticipation";
import { listParticipant } from "../../actions/FindUsersParticipate";
import { getCreatorData } from "../../actions/GetCreatorData";
import { ManageContext } from "../../provider/manageStateProvider";

import { Ionicons } from "@expo/vector-icons";



const HolidayActivityDetail = ({ navigation, route }) => {



const handleHolidayActivityDetail = useContext(ManageContext);

  const [password, setPassword] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [updateParticipation, setUpdateParticipation] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [expand, setSetExpand] = useState(0);
  const [manage, setManage] = useState(false);
  const [participationModal, setParticipationModal] = useState(false);
  

  const [idUser, setIdUser] = useState("");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [photoUser, setPhotoUser] = useState("");
  const [userParticipation, setUserParticipation] = useState([]);
  const [allParticipation, setAllParticipation] = useState([]);
  const [creatorDetail, setCreatorDetail] = useState([]);
  const [isBanned, setIsBanned] = useState();
  const [idStructure, setIdStructure] = useState("");
  const [visibleMaxUser,  setVisibleMaxUser] = useState(false);


  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  
  const [region, setRegion] = useState({
    "latitude": 48.863824,
    "latitudeDelta": 0.0022,
    "longitude": 2.4201162,
    "longitudeDelta": 0.0221,
  });

  moment().locale("fr", localization).format("LLL");

  const activity = route.params.item;


  const bottomSheetHandle = useRef(null);

  useEffect(() => {
    //Put your Data loading function here instead of my loadData()

    const unsubscribe = navigation.addListener("focus", async () => {



   
      await getData("isManager")
        .then((data) => data)
        .then((value) =>{

      
          value == "true" ? setManage(true) : setManage(false)  }
        );

      await getData("idUser")
        .then((data) => data)
        .then((value) =>{
      

          setIdUser(value)
        });

        await getData("idC")
        .then((data) => data)
        .then((value) => {
        

          setIdStructure(value)
        });

      await getData("firstname")
        .then((data) => data)
        .then((value) => {      
          
         
          setFirstname(value)
        });

      await getData("photoUser")
        .then((data) => data)
        .then((value) => 
      
      {
        setPhotoUser(value)});

      await getData("lastname")
        .then((data) => data)
        .then((value) => {
          setLastname(value)});

      return unsubscribe;
    });
  }, [navigation]);


 const idAllowParticipate = route.params.concept == "holiday" ? idStructure : route.params.item.idStructure =="" ?  route.params.item.idCentre :route.params.item.idStructure
  

  useEffect(() => {
    const fetchParticipate = async () => {


      if (idUser !== "") {
        const formData = {
          idUser: idUser,
          idConcept: route.params.item.id,
        };

        const participe = await listParticipant(formData);

        setUserParticipation(participe.isParticipating);

        if (participe.allParticipant[0] == undefined) {
          setAllParticipation([]);
        } else {
          setAllParticipation(participe.allParticipant);

          setIsBanned(participe.allParticipant[0].isBanned);
        }
      }

     
    };
    fetchParticipate();



  }, [idUser, userParticipation,updateParticipation]);


  useEffect(() => {
    const fetchCreator = async () => {

      const formData = {
        idUser: route.params.item.idUser,
        
      };


      const creator = await getCreatorData(formData);

     if(creator !== undefined){
    

        setCreatorDetail(creator.response);
   

     

     }

  
    };
    fetchCreator();

  }, []);

  const goToEditConcept = () => {
    
    navigation.navigate("EditConcept", {
      activity: route.params.item,
      concept: 'activityHoliday',
      idCentre: route.params.idCentre,
      idEvent: route.params.idEvent,
      subActivity: true,
      idPole: "01",
    });
  };



  const sendSmsActivity = () => {

 
 
    

    const timeActivity = route.params.item.timeActivity == undefined ? "" : route.params.item.timeActivity


    const shareOptions = {
      title: 'Mon Centre Social',
      message: `Bonjour, je trouve très intéréssant cette activité de ${route.params.item.title} et je souhaite t'en informer elle aura lieu au ${route.params.item.location} ${timeActivity}, pour plus d'information tu peux télécharger l'application Mon Centre Social : 
      
Play Store: 
https://play.google.com/store/apps/details?id=com.mcsexpo.MCS&gl=FR

Apple Store: 
https://apps.apple.com/fr/app/mon-centre-social/id1610144427?platform=iphone` , 
      subject: 'Participation'
    };
    
    Share.share(shareOptions)  
  

};

  
  const closeBottomSheet = () => {
    bottomSheetHandle.current.close();
    setSetExpand(0);
  };



  const hideMaxUserModal = () => {

    setVisibleMaxUser(false)

  };


 

  const showListParticipation =  () => {

    if((route.params.concept =="holiday") && (handleHolidayActivityDetail.updateManager !== false)){ 

      setParticipationModal(true);
    }
    else{ 
      
     if ((idStructure == route.params.item.idStructure ) && (handleHolidayActivityDetail.updateManager !== false))
    
  {

      setParticipationModal(true);
   }

  }
  
  }
    
  
  const hideListParticipation =  () => {

    setParticipationModal(false);
  };
  
  
  function jsx(){

  
let canHeEdit ="";



if ( (idStructure == route.params.item.idStructure ) && (handleHolidayActivityDetail.updateManager == true)){

   canHeEdit = true;
}else{
  canHeEdit = false;
}

if (route.params.concept == "activity" ) {

  canHeEdit = true;
}




     return ( 
    
      <View style={styles.container}>
      <StatusBar style="auto" />

      <ScrollView
        contentContainerStyle={{
          backgroundColor: "#FFF",
        }}
        style={{
          backgroundColor: "#FFF",
        }}
      >
        <ImageBackground
          resizeMode="cover"
          source={{ uri: activity.preview }}
          style={{
            width: "100%",

        
          }}
        >
          <View style={{ flex: 1 }}>
          <View style={{    flexDirection: "row",
          justifyContent: "space-between",
          marginTop:80,
          marginLeft: 30,
          margiRight: 30,
          zIndex: 1}}>
              <TouchableOpacity
                onPress={goBack}
                style={{
                  width: 56,
                  height: 40,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  justifyContent: "center",
                  borderRadius: 10,
                  alignItems: "center",
                }}
              >
              <Feather
              name="arrow-left"
              size={theme.sizesPhotos.font * 1.2}
              color={"#e0e0e0"}
            />
              </TouchableOpacity>

              <View
                style={{
                  width: (handleHolidayActivityDetail.updateManager == true) ? 90 : 50,
                  height: 40,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  justifyContent: "space-between",
                  borderRadius: 10,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
               
                <TouchableOpacity onPress={sendSmsActivity}>
                <Feather
                name="share-2"
                size={theme.sizesPhotos.font * 1.2}
                color={"#e0e0e0"}
                 style={{
                      marginLeft: 16,
                    }}
              />
                </TouchableOpacity>
            
                
                {(handleHolidayActivityDetail.updateManager == true)  ? (
                 
                  <TouchableOpacity onPress={goToEditConcept}>
                    <View
                      style={{
                        margin: 10,
                      }}
                    >
                      <Feather
                        name="edit-2"
                        size={theme.sizesPhotos.font * 1.2}
                        color={"#e0e0e0"}
                      />
                    </View>
                  </TouchableOpacity>
                ) : null}
              </View>
            </View>

            

            <View style={{flex: 1,
              justifyContent: 'flex-end',
              position: 'relative'}}>
              
            <LinearGradient
                colors={["transparent", "transparent"]}
                start={{ x: 0, y: 0 }}
                end={{ x: 0, y: 1 }}
                style={{
                  width: "100%",
                  height: 200,
                  justifyContent: "flex-end",
                }}
              >
              <View style={{ flexDirection: "row",
                justifyContent: "space-between",
                
                marginHorizontal: "20%"}}>


              <View>
               

                <Text
                  
                  style={{
                    color: "#0f0c29",
                    fontWeight: "400",

                    color: "white",
                  }}
                >
                  {route.params.item.title}
                </Text>
              </View>

              <View
                style={{
                  width: 90,
                  height: 50,
                  marginBottom: "10%",
                  marginTop: "10%",
                  borderRadius: 15,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: theme.colors.lightBlue,
                }}
              >
                <Text h8 style={{ letterSpacing: 1 }}>
                  {"Le "}
                  {moment(route.params.item.dateStart)
                    .format("DD MMM")
                    .toLocaleUpperCase()}
                </Text>
               
              </View>
            </View>
                </LinearGradient>

              <View
                style={{
                  flexDirection: "row",
                  borderTopRightRadius: 15,
                  borderTopLeftRadius: 15,
                  paddingTop: 15,
                  backgroundColor: "white",
                }}
              >
                <LinearGradient
                  colors={["white", "white"]}
                  start={{ x: 0, y: 1 }}
                  end={{ x: 1, y: 1 }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "19%" }}>
                      <TouchableOpacity
                      onPress={showListParticipation}
                        
                        activeOpacity={0.8}
                      >
                        <View
                          style={{
                            marginHorizontal: theme.sizes.base * 1,
                            marginBottom: theme.sizes.base * 0.4,
                          }}
                        >
                          <View>
                            <View style={styles.postAuthorWrapper}>
                              <View
                                colors={"#F8F8FF"}
                                style={{
                                  width: 45,
                                  height: 45,
                                  borderRadius: 30,
                                }}
                              >
                                <View style={styles.imageReferent}>
                                  <Image
                                    source={{
                                      uri: route.params.item.idUser == undefined ? route.params.item.avatarCreator : creatorDetail.photo,
                                    }}
                                    resizeMode="cover"
                                    style={styles.postProfileImgReferent}
                                  />
                                </View>
                              </View>
                              <Text style={styles.postUsername}></Text>
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        marginLeft: -theme.sizes.width * 0.17,
                        width: "50%",
                      }}
                    >
                      <TouchableOpacity
                      onPress={showListParticipation}
                        
                        activeOpacity={0.8}
                      >
                        <View
                          style={{
                            marginHorizontal: theme.sizes.base * 1,
                            marginBottom: theme.sizes.base * 0.4,
                          }}
                        >
                          <View>
                            <View style={styles.postAuthorWrapper}>
                              <View
                                colors={"#F8F8FF"}
                                style={{
                                  width: 45,
                                  height: 45,
                                  borderRadius: 30,
                                }}
                              >
                                <View style={styles.imageNb}>
                                  <Text
                                    h4
                                    style={{
                                      marginLeft: "40%",
                                      marginVertical: "25%",
                                    }}
                                  >
                                    {allParticipation.length}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View>

                   

                    <View style={{ width: "90%" }}>
                      {(route.params.item.activeChat == true ) && (handleHolidayActivityDetail.userStructure == handleHolidayActivityDetail.idStructure || handleHolidayActivityDetail.updateManager  == true) ? (
                        <TouchableOpacity onPress={chatHandler}>
                          <Image
                            style={{ width: 50, height: 50 }}
                            source={require("../../assets/images/chatButton.png")}
                          />
                        </TouchableOpacity>
                      ) : (
                        <LinearGradient
                          colors={theme.colors.linearDislabled}
                          start={{ x: 0, y: 1 }}
                          end={{ x: 1, y: 0 }}
                          style={{
                            width: 45,
                            height: 45,
                            borderRadius: 30,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Ionicons
                             
                              name="chatbubble-ellipses"
                              color={
                                route.params.item.activeChat == true
                                  ? "#fff"
                                  : "#c4c0c0"
                              }
                              size={16}
                            />
                          </View>
                        </LinearGradient>
                      )}
                    </View>
                  </View>
                </LinearGradient>
              </View>
            </View>
          </View>
        </ImageBackground>

        <Modal
        animationType="slide"
        transparent={true}
        visible={visibleMaxUser}
        
      >
        <View
          style={{
         
            
            backgroundColor: 'rgba(0,0,0,0.7)',
         
          
         
           
          }}
        >
          <View  style={{
         
            alignItems:"center",
            width: "90%",
            height: "50%",
            borderColor: "#ccc",
            borderWidth: 1,
            borderRadius: 20,
            borderStyle: "solid",
            backgroundColor: "white",
            opacity: 1,
        
            marginVertical: "50%",
            marginLeft: "5%",
            borderRadius: 4,
            justifyContent: "center",
          }}>
          

 
            <Text
              h4
              color="#232043"
              style={{ margin: theme.sizes.padding  }}
            >
            {"Désolé le nombre maximum de participants a été atteint."}
            </Text>

           

            <Button style={{backgroundColor:theme.colors.lightBlue}} onPress={hideMaxUserModal}  mode="contained">
            <Text
            style={{ padding: "5%" }}
            bold
            size={14}
          >
            {"J'ai compris"}
          </Text>
          </Button>
          </View>
        </View>
      </Modal>





        <View>


          <Text body3  style={{
            marginVertical: "2%",
            color: "#0f0c29",
            alignSelf: "center",
            fontSize:20
          }}>
            {activity.description}
          </Text>
         
    
          
          
          <Text body3 style={{ marginTop: "5%", color: "#0f0c29",fontWeight:"700" }}>
          {"Lieu de L'activité:"} {activity.location}
          </Text> 
          
          

            <Text body3 style={{ marginTop: "5%", color: "#0f0c29" }}>
            {activity.timeActivity}
          </Text>
          
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "85%" }}>
              <Text h4 style={{ marginTop: "10%", color: "#0f0c29" }}>
                {"Organisé par : "}
                {creatorDetail.firstnameCreator} {creatorDetail.lastnameCreator}
              </Text>
            </View>

            <View style={styles.postAuthorWrapper}>
              <View
                colors={"#2acaea"}
                style={{
                  alignContent: "flex-end",
                  marginTop: "30%",
                  width: 45,
                  height: 45,
                  borderRadius: 30,
                }}
              >
                <View style={styles.imageReferent}>
                  <Image
                    source={{ 
                      uri: route.params.item.idUser == undefined ? route.params.item.avatarCreator : creatorDetail.photo
                    }}
                    resizeMode="cover"
                    style={styles.postProfileImgReferent}
                  />
                </View>
              </View>
            </View>
          </View>
          <Modal animationType='slide' visible={participationModal}>
          <View style={styles.centeredView}>
           
             <View style={{flexDirection:"row",marginVertical:"7%",marginHorizontal:"3%",paddingTop:  (Platform.OS === 'ios') ? "8%" : 0}}>

             <View style={{width:"30%"}}>
               
             <Ionicons 
             onPress={hideListParticipation}
             style={{ marginLeft: 10 }}
             name="arrow-back-circle-sharp"
             color={theme.colors.lightBlue}
             size={30}
           />
          
           </View>
                       
             <View style ={{width:"70%"}}>
             
             <Text h3 color="black"> Liste des Participants</Text>
             </View>
             
             </View>
             <View
      style={{
        height: 1,
        width: '100%',
        backgroundColor: '#CED0CE',
        marginBottom:"5%"
        
      }}
    />
             
              <FlatList              
                data={allParticipation}
                renderItem={renderItemListParticipation}
                keyExtractor={(item, index) => index.toString()}
                
                
              />
            </View>
         
        </Modal>
        </View>

        <View>
        <View
          style={{
            height: 100,
            zIndex: -1,
            borderRadius: 20,

            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        ></View>
      </View>
      </ScrollView>

      <View style={{ 
        height: theme.sizes.height / 8 ,
        width: theme.sizes.width,
        borderRadius: 15,
        position: "absolute",
        bottom: 0,
        justifyContent: "center",
        backgroundColor: "#f5f5f5"}}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginHorizontal: 30,
          }}
        >
          <View>
            <Text
              body3
              style={{ color: "#0f0c29", opacity: 0.5, letterSpacing: 1 }}
            >
              Entrée
            </Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Text
                h3
                style={{
                  color: "#0f0c29",
                  fontSize: 20,
                }}
              >
                {activity.price !== undefined &&
                activity.price !== 0 &&
                activity.price !== null
                  ? activity.price + "€  "
                  : "Gratuite"}
              </Text>
              <Text
                h4
                style={{
                  color: "#0f0c29",
                  fontSize: 11,
                }}
              >
                {activity.priceTag  !== undefined &&
                  activity.priceTag  !== 0 &&
                  activity.priceTag  !== null &&
                  activity.price  !== 0 
                    ? "/ " + activity.priceTag 
                    : ""}
              </Text>
            </View>
          </View>
          <TouchableOpacity onPress={userParticipationHandler}>
            <View
              style={{
                width: 150,
                height: 53,
                borderRadius: 15,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: handleHolidayActivityDetail.userStructure == idAllowParticipate ? "#146cd9" : "#ced5e0",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >

                
                <View>
                  <Text>Participer </Text>
                </View>
                {
                  //Check if message failed
                  userParticipation == false ? (
                    <View>
                    <View>
                      <Image
                        style={{ margin: 11 }}
                        source={require("../../assets/icons/buy_ticket_icon.png")}
                        size={20}
                      ></Image>
                    </View>
                  </View>
                  ) : (
                    <View>
                      <View>
                        <Ionicons
                          style={{ marginLeft: 10 }}
                          name="checkmark-outline"
                          color={"#fff"}
                          size={20}
                        />
                      </View>
                    </View>
                  )
                }
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>

  
    </View>)
   


    
  };




  const chatHandler = () => {
    if (idUser == null || idUser == "undefined" || idUser == "") {
      if (expand == 0) {
        bottomSheetHandle.current.expand();
        setSetExpand(1);
      } else {
        bottomSheetHandle.current.close();
        setSetExpand(0);
      }
    } else {
      if (route.params.item.activeChat == true) {
        navigation.navigate("Chat", {
          firstname: firstname,
          lastname: lastname,
          idUser: idUser,
          photoUser: photoUser,
          concept: route.params.item.id,
          title: route.params.item.title,
          isManager:handleHolidayActivityDetail.updateManager
        });

        SOCKET.connect();
        
        SOCKET.emit("joinGroup", {
          firstname: firstname,
          room: route.params.item.title,
        });
      }
    }
  };

  const loginHandler = () => {
    if (password.length < 6 || email.length < 4) {
      if (email.length < 4) {
        setMessage("Vérifiez vos informations et réessayez");
      }

      if (password.length < 6) {
        setMessage("Vérifiez vos informations et réessayez");
      }
    } else {
      setButtonLoading(true);

    
      const fetchResponse = async () => {


        await getData("idC")
        .then((data) => data)
        .then(async(idStructure)  => {

        const loginData = {
          password: password,
          email: email,
          idStructure : idStructure
        }


        const loginResponse = await loginActions(loginData);



        if (loginResponse.message == "login success") {
         


          closeBottomSheet();

          //const isManager = await doesHeManage(route.params.idCentre);

          const avatar =
            loginResponse.photo == undefined || loginResponse.photo == null
              ? handleHolidayActivityDetail.setUpdateAvatar(
                "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/profileDefault.png"
                )
              : handleHolidayActivityDetail.setUpdateAvatar(avatar);

          loginResponse.photo !== "" || loginResponse.photo !== undefined
            ? handleHolidayActivityDetail.setUpdateAvatar(loginResponse.photo)
            : handleHolidayActivityDetail.setUpdateAvatar(
              "https://mcsdatamanagesocial.osc-fr1.scalingo.io/uploadsImageActivity/profileDefault.png"
              );
          loginResponse.firstname !== null
            ? handleHolidayActivityDetail.setFirstname(loginResponse.firstname)
            : handleHolidayActivityDetail.setFirstname("");
          loginResponse.lastname !== null
            ? handleHolidayActivityDetail.setLastname(loginResponse.lastname)
            : handleHolidayActivityDetail.setLastname("");

      

            const isManager = loginResponse.isManager
            const isHeManager = loginResponse.isHeManager
            const isHeSuperAdmin = loginResponse.isHeSuperAdmin
  
  
            isManager == true
              ? handleHolidayActivityDetail.setUpdateManager(true)
              : handleHolidayActivityDetail.setUpdateManager(false);
  
              isHeManager == true
              ? handleHolidayActivityDetail.setUpdateIsHeManager(true)
              : handleHolidayActivityDetail.setUpdateIsHeManager(false);
  
              isHeSuperAdmin == true
              ? handleHolidayActivityDetail.setUpdateIsHeSuperAdmin(true)
              : handleHolidayActivityDetail.setUpdateIsHeSuperAdmin(false);

              handleHolidayActivityDetail.setIdUser(loginResponse.idUser)
              handleHolidayActivityDetail.setUserStructure(loginResponse.userStructure)
        handleHolidayActivityDetail.setUserProfil(! handleHolidayActivityDetail.userProfil)      
        
          setManage(isManager);
          setIdUser(loginResponse.idUser);
          setLastname(loginResponse.lastname);
          setFirstname(loginResponse.firstname);
          setPhotoUser(avatar);

          setButtonLoading(false);
        }

        if (
          loginResponse.message == "Mot de passe incorrect" ||
          loginResponse.message == "Email Inconnu"
        ) {
          setMessage("Email ou Mot de passe non reconnu");

          setButtonLoading(false);
        }
     
     
     
     
      })
      
    
    
    };

      fetchResponse();
    }
  };

  const userParticipationHandler = () => {
   

    if (idUser == null || idUser == "undefined" || idUser == "") {
      if (expand == 0) {
        bottomSheetHandle.current.expand(0);
        setSetExpand(1);
      } else {
        bottomSheetHandle.current.close();
        setSetExpand(0);
      }
    } else {

    if(handleHolidayActivityDetail.userStructure == idAllowParticipate){
      
      if (userParticipation == false) {

     
         const maxUser =
          route.params.item.maxUser == undefined
            ? 0
            : route.params.item.maxUser; const price =
          route.params.item.price == undefined
            ? 0
            : route.params.item.price;

if(allParticipation.length !== 0 && allParticipation.length >= maxUser){
setVisibleMaxUser(true)


}
  else{

    
    
          const formData = {
            
          idStructure:route.params.item.idStructure,
          firstname: firstname,
          lastname: lastname,
          idUser: idUser,
          photoUser: photoUser,
          idConcept: route.params.item.id,
          preview: route.params.item.preview,
          title: route.params.item.title,
          date: route.params.item.date,
          description: route.params.item.description,
          location: route.params.item.location,
          activeChat: route.params.item.activeChat,
          idCreator:route.params.item.idUser,   
          price: price,
          maxUser: maxUser,
          allData:route.params.item,
          concept:"holidayActivity",
        
         
        };

        addUserParticipation(formData);

        setUserParticipation(true);

         handleHolidayActivityDetail.updatePole == "false"
          ? handleHolidayActivityDetail.setUpdatePole("true")
          : handleHolidayActivityDetail.setUpdatePole("false");

}
       
      }

      if (isBanned !== true && userParticipation == true) {
        
        const formData = {
          idUser: idUser,
          idConcept: route.params.item.id,
        };

        removeParticipation(formData);

        setUserParticipation(false);


         handleHolidayActivityDetail.updatePole == "false"
          ? handleHolidayActivityDetail.setUpdatePole("true")
          : handleHolidayActivityDetail.setUpdatePole("false");
      }
    } 
    }
  };


  const deleteParticipationHandler = (item) => {
   
        const formData = {
          idUser:item.idUser,
          idConcept: route.params.item.id,
        };

        removeParticipation(formData);

     
        setUpdateParticipation(!updateParticipation)

        
         handleHolidayActivityDetail.updatePole == "false"
          ? handleHolidayActivityDetail.setUpdatePole("true")
          : handleHolidayActivityDetail.setUpdatePole("false");
      
    
  };


  const renderItemListParticipation = ({ item }) => {
 
    return (
      <View>
      <View
        style={{
          flexDirection: 'row',
          padding: 16,
          alignItems: 'center',
          justifyContent:"space-between"
        }}>
        

        
                <Image
                  source={{ uri: item.photo }}
                  resizeMode="cover"
                  style={styles.postListProfileImg}
                />
               
        <Text
        h5
          style={{
            color: '#000'
          }}>{`${item.firstname} ${item.lastname}`}</Text>
      
     
      <Button onPress={()=>{deleteParticipationHandler(item)}} loading={loading} style={{marginHorizontal:"10%",width : "20%"}}>retirer</Button>
      </View>
      
        

      <View
      style={{
        height: 1,
        width: '86%',
        backgroundColor: '#CED0CE',
        marginLeft: '5%'
      }}
    />
    </View>

    
    );
  };

  const goBack = () => {
    
    StatusBar.setHidden(false);

    Platform.OS == "android" ?  StatusBar.setBackgroundColor("#FF573300") : null
  ;

    navigation.goBack();
  };




  return (
   jsx()
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  centeredView:{
    justifyContent:"center",
    flex:1

  },
  socialConnect: {
    backgroundColor: theme.colors.WHITE,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#8898AA",
    padding: 50,
  },
  socialTextButtons: {
    color: theme.colors.DEFAULT,
    fontWeight: "800",
    fontSize: 14,
  },
  postContainer: {
    backgroundColor: "transparent",
    opacity: 0.7,
    width: 75,
    marginVertical: 20,
    borderRadius: 18,
  },
  postTopWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  postAuthorWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },

  imageReferent: {
    borderColor: "#409ddb",
    borderWidth: 2,

    borderRadius: 30,
    width: 50.5,
    height: 50.5,
  },

  imageNb: {
    borderRadius: 30,
    width: 50.5,
    height: 50.5,
    backgroundColor: theme.colors.lightBlue,
    opacity: 0.8,
    position: "absolute",
    left: -17,
    zIndex: 100,
  },

  postProfileImgReferent: {
    width: 40.5,
    height: 40.5,
    borderRadius: 30,
    margin: 3,
  },

  image: {
    borderColor: "#8741bb",
    borderWidth: 2,
    borderRadius: 30,
    width: 61.5,
    height: 61.5,
  },

  postProfileImg: {
    width: 55.5,
    height: 55.5,
    borderRadius: 30,
    margin: 3,
  },


  postListProfileImg: {
    width: 55.5,
    height: 55.5,
    borderRadius: 30,
    margin: 15,
  },
  postUsername: {
    fontWeight: "600",
  },
  postMoreIcon: {
    transform: [{ rotate: "90deg" }],
    marginRight: 8,
  },

  postBottomWrapper: {
    flexDirection: "row",
    alignSelf: "stretch",
    justifyContent: "space-between",
    padding: 0,
  },
  postBottomLeftWrapper: {
    flexDirection: "row",
  },
  postDetailContainers: {
    marginRight: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  postDetailIcon: {
    marginRight: 0,
  },
  postDetailNumbers: {
    fontSize: 12,
  },
});

export default HolidayActivityDetail;
